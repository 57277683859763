import { ChartRevenueItem, WaterfallData } from '../shared/Interface/generic.model';

export function formatWaterfallChart(list: ChartRevenueItem[]): WaterfallData[] {
    if (list && list.length) {
        let previousValue = 0;
        return list.map(i => {
            const roundValue = Math.round(i.value);
            const obj = { x: i.label, y: roundValue, color: "#7851A9" };
            if (obj.x == "Gross Revenue") {
              previousValue = roundValue;
            } else {
                if (obj.x == "Net Profit") {
                    // console.log(previousValue, i.value);
                    if (previousValue > roundValue) obj.y = roundValue;
                    else {
                        obj.y = -roundValue;
                    }
                } else {
                    obj.y = -roundValue;
                    previousValue += (obj.y);
                    obj.color = "#78B751";
                }
            }
            return obj;
        })
    } else {
        return []
    }
}