import * as i0 from '@angular/core';
import { Directive, ContentChildren, Component, ChangeDetectionStrategy, ContentChild, NgModule } from '@angular/core';
import { ComplexBase, setValue, ArrayBase, ComponentBase, Template, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { __decorate } from 'tslib';
import { AIAssistView } from '@syncfusion/ej2-interactive-chat';
const _c0 = ["footerTemplate"];
const _c1 = ["promptItemTemplate"];
const _c2 = ["responseItemTemplate"];
const _c3 = ["promptSuggestionItemTemplate"];
const _c4 = ["bannerTemplate"];
const _c5 = ["ejs-aiassistview", ""];
const _c6 = ["*"];
export * from '@syncfusion/ej2-interactive-chat';
import { CommonModule } from '@angular/common';
let input = ['iconCss', 'name', 'type', 'viewTemplate'];
let outputs$1 = [];
/**
 * Represents the Essential JS 2 Angular AIAssistView Component.
 * ```html
 * <ejs-aiassistview>
 *   <e-views>
 *     <e-view>
 *      </e-view>
 *    </e-views>
 * </ejs-aiassistview>
 * ```
 */
class ViewDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$1);
    this.directivePropList = input;
  }
}
ViewDirective.ɵfac = function ViewDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ViewDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
ViewDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ViewDirective,
  selectors: [["e-view"]],
  inputs: {
    iconCss: "iconCss",
    name: "name",
    type: "type",
    viewTemplate: "viewTemplate"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ViewDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-aiassistview>e-views>e-view',
      inputs: input,
      outputs: outputs$1,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * View Array Directive
 * @private
 */
class ViewsDirective extends ArrayBase {
  constructor() {
    super('views');
  }
}
ViewsDirective.ɵfac = function ViewsDirective_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ViewsDirective)();
};
ViewsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: ViewsDirective,
  selectors: [["e-views"]],
  contentQueries: function ViewsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, ViewDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ViewsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-aiassistview>e-views',
      queries: {
        children: new ContentChildren(ViewDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs = ['activeView', 'bannerTemplate', 'cssClass', 'enablePersistence', 'enableRtl', 'footerTemplate', 'height', 'locale', 'prompt', 'promptIconCss', 'promptItemTemplate', 'promptPlaceholder', 'promptSuggestionItemTemplate', 'promptSuggestions', 'promptSuggestionsHeader', 'promptToolbarSettings', 'prompts', 'responseIconCss', 'responseItemTemplate', 'responseToolbarSettings', 'showClearButton', 'showHeader', 'toolbarSettings', 'views', 'width'];
const outputs = ['created', 'promptChanged', 'promptRequest', 'promptChange'];
const twoWays = ['prompt'];
/**
 * Represents the Essential JS 2 Angular AIAssistView Component.
 * ```html
 * <ejs-aiassistview></ejs-aiassistview>
 * ```
 */
let AIAssistViewComponent = class AIAssistViewComponent extends AIAssistView {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['views'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childViews;
    this.containerContext.ngAfterContentChecked(this);
  }
};
AIAssistViewComponent.ɵfac = function AIAssistViewComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AIAssistViewComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
AIAssistViewComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: AIAssistViewComponent,
  selectors: [["", "ejs-aiassistview", ""]],
  contentQueries: function AIAssistViewComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, _c1, 5);
      i0.ɵɵcontentQuery(dirIndex, _c2, 5);
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, ViewsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.footerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.promptItemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.responseItemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.promptSuggestionItemTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.bannerTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childViews = _t.first);
    }
  },
  inputs: {
    activeView: "activeView",
    bannerTemplate: "bannerTemplate",
    cssClass: "cssClass",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    footerTemplate: "footerTemplate",
    height: "height",
    locale: "locale",
    prompt: "prompt",
    promptIconCss: "promptIconCss",
    promptItemTemplate: "promptItemTemplate",
    promptPlaceholder: "promptPlaceholder",
    promptSuggestionItemTemplate: "promptSuggestionItemTemplate",
    promptSuggestions: "promptSuggestions",
    promptSuggestionsHeader: "promptSuggestionsHeader",
    promptToolbarSettings: "promptToolbarSettings",
    prompts: "prompts",
    responseIconCss: "responseIconCss",
    responseItemTemplate: "responseItemTemplate",
    responseToolbarSettings: "responseToolbarSettings",
    showClearButton: "showClearButton",
    showHeader: "showHeader",
    toolbarSettings: "toolbarSettings",
    views: "views",
    width: "width"
  },
  outputs: {
    created: "created",
    promptChanged: "promptChanged",
    promptRequest: "promptRequest",
    promptChange: "promptChange"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c5,
  ngContentSelectors: _c6,
  decls: 1,
  vars: 0,
  template: function AIAssistViewComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], AIAssistViewComponent.prototype, "footerTemplate", void 0);
__decorate([Template()], AIAssistViewComponent.prototype, "promptItemTemplate", void 0);
__decorate([Template()], AIAssistViewComponent.prototype, "responseItemTemplate", void 0);
__decorate([Template()], AIAssistViewComponent.prototype, "promptSuggestionItemTemplate", void 0);
__decorate([Template()], AIAssistViewComponent.prototype, "bannerTemplate", void 0);
AIAssistViewComponent = __decorate([ComponentMixins([ComponentBase])], AIAssistViewComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AIAssistViewComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-aiassistview]',
      inputs: inputs,
      outputs: outputs,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childViews: new ContentChild(ViewsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, {
    footerTemplate: [{
      type: ContentChild,
      args: ['footerTemplate']
    }],
    promptItemTemplate: [{
      type: ContentChild,
      args: ['promptItemTemplate']
    }],
    responseItemTemplate: [{
      type: ContentChild,
      args: ['responseItemTemplate']
    }],
    promptSuggestionItemTemplate: [{
      type: ContentChild,
      args: ['promptSuggestionItemTemplate']
    }],
    bannerTemplate: [{
      type: ContentChild,
      args: ['bannerTemplate']
    }]
  });
})();

/**
 * NgModule definition for the AIAssistView component.
 */
class AIAssistViewModule {}
AIAssistViewModule.ɵfac = function AIAssistViewModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AIAssistViewModule)();
};
AIAssistViewModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AIAssistViewModule
});
AIAssistViewModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AIAssistViewModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [AIAssistViewComponent, ViewDirective, ViewsDirective],
      exports: [AIAssistViewComponent, ViewDirective, ViewsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the AIAssistView component with providers.
 */
class AIAssistViewAllModule {}
AIAssistViewAllModule.ɵfac = function AIAssistViewAllModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || AIAssistViewAllModule)();
};
AIAssistViewAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AIAssistViewAllModule
});
AIAssistViewAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, AIAssistViewModule], AIAssistViewModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AIAssistViewAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, AIAssistViewModule],
      exports: [AIAssistViewModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { AIAssistViewAllModule, AIAssistViewComponent, AIAssistViewModule, ViewDirective, ViewsDirective };
