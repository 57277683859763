import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ButtonModule,
  RadioButtonModule,
} from '@syncfusion/ej2-angular-buttons';
import { ToastComponent, ToastUtility } from '@syncfusion/ej2-angular-notifications';
import { AuthGraphqlService } from 'app/graphql/auth-graphql.service';
import { ResetPasswordWithToken } from 'app/shared/Interface/generic.model';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reset-password',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RadioButtonModule, ButtonModule],
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss',
})
export class ResetPasswordComponent implements OnInit {
  token: string = '';
  resetPasswordForm: FormGroup;
  showResetPassword: boolean = false;
  showConfirmPassword: boolean = false;
  subscribe?: Subscription;

  constructor(private fb: FormBuilder, 
    private authGraphqlService: AuthGraphqlService, 
    private loader: NgxUiLoaderService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.resetPasswordForm = this.fb.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            this.passwordStrengthValidator,
          ],
        ],
        confirmPassword: ['', [Validators.required]],
      },
      { validators: this.passwordsMatchValidator }
    );
  }

  passwordStrengthValidator(control: any) {
    const password = control.value;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /[0-9]/.test(password);
    const hasSpecialChars = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const validLength = password && password.length >= 8;

    if (
      !validLength ||
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumbers ||
      !hasSpecialChars
    ) {
      return { weakPassword: true };
    }
    return null;
  }

  passwordsMatchValidator(group: FormGroup) {
    const newPassword = group.get('newPassword')?.value;
    const confirmPassword = group.get('confirmPassword')?.value;
    if (newPassword !== confirmPassword) {
      return { passwordMismatch: true };
    }
    return null;
  }

  onSaveresetPasswordForm() {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    let payload: ResetPasswordWithToken = {
      confirmPassword: this.resetPasswordForm.controls['confirmPassword'].value,
      newPassword: this.resetPasswordForm.controls['newPassword'].value,
      token: this.token,
    };
    this.loader.start();
    this.authGraphqlService.resetPasswordWithToken(payload).subscribe(
      (response: any) => {
        console.log('response', response);
        if (response?.data?.resetPasswordWithToken?.success) {
          ToastUtility.show(
            {
              content: response?.data?.resetPasswordWithToken?.success,
              timeOut: 10000,
              cssClass: 'e-toast-success',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Information'
          ) as ToastComponent;
          
        setTimeout(()=>{
          this.router.navigate([`/login`]);
        }, 1000);
        } else {
          ToastUtility.show(
            {
              content: response?.errors[0]?.message,
              timeOut: 10000,
              cssClass: 'e-toast-danger',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Error'
          ) as ToastComponent;
        }
        this.loader.stop();
      },
      () => {
        this.loader.stop();
      }
    );
  }

  toggleResetPassword() {
    this.showResetPassword = !this.showResetPassword;
  }

  toggleConfirmPassword() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  ngOnInit(): void {
    this.subscribe = this.route.params.subscribe((params) => {
      if (params['id']) {
        this.token = params['id'];
      }
    });
  }

  ngOnDestory(): void {
    this.subscribe?.unsubscribe();
  }
}
