<div class="login_section">
    <div class="login-box mx-auto my-5">
      <form [formGroup]="loginForm" class="py-7 px-14">
        <div class="flex justify-center">
          <img src="assets/images/logo.png" class="w-auto" alt="images" />
        </div>
        <h2 class="flex justify-center my-5">Login</h2>
        <div class="login_textbox">
          <input class="e-input input-emp-id e-outline" placeholder="User Name *" floatLabelType="Auto" formControlName="username" type="text">
        </div>
        @if(loginForm.get('username')?.invalid && loginForm.get('username')?.touched){
        <div class="error-message">
          Username is required
        </div>
        }
        <div class="relative password-container mt-4">
          <input class="e-input input-emp-id e-outline" formControlName="password" cssClass="password-textbox" floatLabelType="Auto" [type]="showPassword ? 'text' : 'password'" placeholder="Password *" type="text"> 
          <div
            class="icon">
            @if(!showPassword){
              <i class="fa-regular fa-eye" (click)="togglePassword()"></i>
            }
            @if(showPassword){
              <i class="fa-solid fa-eye-slash" (click)="togglePassword()"></i>
            }
          </div>
        </div>
        @if(loginForm.get('password')?.invalid && loginForm.get('password')?.touched){
          <div class="error-message">
            Password is required
          </div>
        }
        <div class="sm:flex space-y-2 sm:space-y-0 justify-between mt-5">
          <div class="remember_section">
            <ejs-checkbox class="rounded-full" label="Remember me" formControlName="rememberMe"></ejs-checkbox>
          </div>
          <span class="text-xs text-blue-600 cursor-pointer" [routerLink]="['/forgot-password']">Forgot password ?</span>
        </div>
        <div class="text-center space-y-2 mt-5">
          <div class="flex justify-center">
            <button class="w-full" ejs-button type="submit" [disabled]="loginForm.invalid"(click)="onClickLogin()">Login</button>
          </div>
          <div>
            or
          </div>
          <div class="flex justify-center">
            @if (isOktaAuthenticated | async) {
              @if (!isOktaProcessing) {
              <span class="hidden">{{verifyOkta()}}</span>
              }
              <button class="w-full" ejs-button>Loading with <span>Okta</span></button>
            } @else {
              <button class="w-full" ejs-button type="submit" (click)="signInOkta()">Login with <span>Okta</span></button>
            }
          </div>
          <button class="w-full" ejs-button type="submit" (click)="verifyAD()">Login with <span>Microsoft</span></button>
        </div>
      </form>
    </div>
  </div>