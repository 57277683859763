import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { filter, Subscription } from 'rxjs';
import { OKTA_AUTH } from '@okta/okta-angular';
import {
  ToastComponent,
  ToastUtility,
} from '@syncfusion/ej2-angular-notifications';
import { GenericService } from '../../../graphql/generic.service';
import { CommonService } from '../../services/common.service';
@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  dialogInstance: any;
  isSimulationListScreen: boolean = false;
  isSimulationFormModify: boolean = false;
  isScenarioFormModify: boolean = false;
  subscribe?: Subscription;
  isUserLogin: boolean = false;
  private oktaAuth = inject(OKTA_AUTH);

  constructor(
    private router: Router, 
    private genericService: GenericService,
    private commonService: CommonService
  ) {
    this.subscribe = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.isSimulationListScreen =
          event.url === '/simulation-list' ? true : false;
      });
    this.isUserLogin = this.commonService.accessToken ? true : false;
  }

  identifySaveRemainingChanges() {
    this.genericService.formDirtyFlagIndetify(true);
    setTimeout(() => {
      if (this.isSimulationFormModify || this.isScenarioFormModify) {
        this.onOpenDialog();
      } else {
        this.router.navigate([`/simulation-list`]);
      }
    }, 500);
  }

  onOpenDialog = () => {
    this.dialogInstance = DialogUtility.confirm({
      title: 'Alert',
      content: `Please Save Your Data Before Navigate to Home Page. Once You Clicked Ok Button You Should Redirect to Home Page!`,
      okButton: { text: 'Ok', click: this.redirectHomeClick.bind(this) },
      cancelButton: { text: 'Cancel', click: this.cancelClick.bind(this) },
      showCloseIcon: true,
      width: '30%',
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    });
  };

  redirectHomeClick(): void {
    this.router.navigate([`/simulation-list`]);
    this.dialogInstance?.close();
  }

  cancelClick(): void {
    this.dialogInstance?.close();
  }

  logout(){
    ToastUtility.show(
      {
        content: 'Logout Successfully!',
        timeOut: 2000,
        cssClass: 'e-toast-success',
        position: { X: 'Right', Y: 'Top' },
        showCloseButton: true,
      },
      'Information'
    ) as ToastComponent;
    this.oktaAuth.tokenManager.clear();
    localStorage.removeItem('predealtoken');
    this.router.navigateByUrl('/login');
  }

  ngOnInit(): void {
    this.subscribe = this.genericService.senarioFormDirtyFlag$.subscribe(
      (senarioFormDirtyFlag) => {
        this.isScenarioFormModify = senarioFormDirtyFlag;
      }
    );
    this.subscribe = this.genericService.simulationFormDirtyFlag$.subscribe(
      (simulationFormDirtyFlag) => {
        this.isSimulationFormModify = simulationFormDirtyFlag;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscribe?.unsubscribe();
  }
}
