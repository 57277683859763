<div class="login_section">
    <div class="login-box mx-auto my-5">
        @if (showSecurityQeustion) {
            <form [formGroup]="resetPasswordQuestionForm" class="py-7 px-7">
                <div class="flex justify-center">
                    <img src="assets/images/logo.png" class="w-auto" alt="images" />
                </div>
                <h2 class="flex justify-center my-5">Reset Password</h2>
                <div class="input-container mb-3">
                    <div class="flex items-center gap-3">
                        <i class="fa-solid fa-email"></i>
                        <p class="font-medium text-[#696969] text-[12px]">
                            Email: {{forgotPasswordForm.controls['emailAddress'].value}}
                        </p>
                    </div>
                </div>
                @if (securityQuestion) {
                    <div class="text-xs px-2">{{ securityQuestion["question"] }}</div>
                    <div class="login_textbox">
                        <input class="e-input input-emp-id e-outline" placeholder="Answer" floatLabelType="Auto" formControlName="answer" type="text"/>
                    </div>
                }
                <div class="text-xs px-2 mt-2">New Password</div>
                <div class="login_textbox">
                    <input class="e-input input-emp-id e-outline" placeholder="New Password" floatLabelType="Auto" formControlName="newPassword" type="password"/>
                </div>
                <div class="text-xs px-2 mt-2">Confirm Password</div>
                <div class="login_textbox">
                    <input class="e-input input-emp-id e-outline" placeholder="Confirm Password" floatLabelType="Auto" formControlName="confirmPassword" type="password"/>
                </div>
                <button class="w-full mt-5" (click)="submitForQuestion()" ejs-button type="submit" [disabled]="resetPasswordQuestionForm.invalid">
                    Reset Password
                </button>
            </form>
        } @else {
        <form [formGroup]="forgotPasswordForm" class="py-7 px-14">
            <div class="flex justify-center">
                <img src="assets/images/logo.png" class="w-auto" alt="images" />
            </div>
            <h2 class="flex justify-center my-5">Forgot Password</h2>
            <div class="login_textbox">
                <input class="e-input input-emp-id e-outline" placeholder="Email Address *" floatLabelType="Auto" formControlName="emailAddress" type="email"/>
            </div>
            @if(forgotPasswordForm.get('emailAddress')?.hasError('required') && forgotPasswordForm.get('emailAddress')?.touched){
                <div class="error-message">
                    Email Address is required
                </div>
            }
            @if(forgotPasswordForm.get('emailAddress')?.hasError('email') && forgotPasswordForm.get('emailAddress')?.touched){
                <div class="error-message">
                    Email must be a valid email address
                </div>
            }
            <h6 class="my-3 text-sm font-semibold">
                Reset your password using:
            </h6>
            <div class="sm:flex space-y-2 sm:space-y-0 justify-between">
                <div class="remember_section">
                    <ejs-radiobutton label="Send link to email address" formControlName="resetMethod" value="LinkToEmail"></ejs-radiobutton>
                    <ejs-radiobutton label="Using 2FA Authentication" formControlName="resetMethod" value="2FAAuthentication"></ejs-radiobutton>
                    <ejs-radiobutton label="Using your security questions" formControlName="resetMethod" value="SecurityQuestion"></ejs-radiobutton>
                </div>                 
            </div>
            <button class="w-full mt-5" (click)="submit()" ejs-button type="submit" [disabled]="forgotPasswordForm.invalid">
                Process
            </button>
        </form>
        }
    </div>
</div>