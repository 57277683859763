<!--Create Scenario Actual Code Start-->
@if (createScenarioFlag) {
<form [formGroup]="scenariosForm">
  <ng-container formArrayName="scenarios">
    @for(scenarioFormArray of scenariosArray.controls; track scenarioFormArray;
    let i = $index) {
    <div [formGroupName]="i" [ngClass]="{ 'mt-3': i }">
      <div class="card">
        <div class="flex justify-between items-center mb-4">
          <div
            class="cursor-pointer"
            [ngClass]="
              scenarioFormArray.get('scenarioType').value === scenarioType[0].id
                ? 'scenario-actual-label'
                : 'scenario-forecast-label'
            "
          >
            Create Scenario - {{ scenarioFormArray.get("scenarioType").value }}
          </div>
          <div class="btn-section flex gap-4 items-center">
            @if(scenariosForm.get('scenarios')?.length < 2){
            <i
              class="fa-solid fa-copy cursor-pointer"
              (click)="addScenario()"
            ></i>
            }
            @if(i){
            <i
              class="fa-regular fa-trash-can text-red-600 cursor-pointer"
              (click)="
                onOpenDialog(i, scenarioFormArray?.get('scenarioId')?.value)
              "
            ></i>
            }
          </div>
        </div>
        <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div class="card space-y-2">
            <div>
              <label>Scenario Type <span class="text-red-600">*</span></label>
              <div class="radio_block">
                @for (scenario of scenarioType; track scenario.name; let st =
                $index) {
                <label
                  class="radio-btn"
                  for="{{ scenario.name }}_{{ st }}_scenario_type"
                >
                  <input
                    type="radio"
                    id="{{ scenario.name }}_{{ st }}_scenario_type"
                    formControlName="scenarioType"
                    [value]="scenario.id"
                    (change)="
                      scenarioTypeChangeEvent(
                        scenarioFormArray.get('scenarioType')?.value,
                        i
                      )
                    "
                  />
                  {{ scenario.name }}
                </label>
                <!-- <ejs-radiobutton
                  name="scenarioType{{i}}"
                  [label]="scenario.name"
                  [value]="scenario.id"
                  formControlName="scenarioType"
                  (change)="scenarioTypeChangeEvent($event, i)"
                ></ejs-radiobutton> -->
                }
              </div>
            </div>
            <div>
              <label>Scenario ID</label>
              <ejs-textbox
                formControlName="scenarioId"
                class="m-0"
                [placeholder]="'&lt;System Generated ID&gt;'"
                [cssClass]="'e-outline'"
                [floatLabelType]="'Never'"
              ></ejs-textbox>
            </div>
            <div>
              <label>Scenario Name <span class="text-red-600">*</span></label>
              <ejs-textbox
                formControlName="scenarioName"
                class="m-0"
                [placeholder]="'Enter Scenario Name'"
                [cssClass]="'e-outline'"
                [floatLabelType]="'Never'"
              ></ejs-textbox>
            </div>
            <div>
              <label>Scenario Description</label>
              <ejs-textbox
                formControlName="scenarioDescription"
                class="m-0"
                [placeholder]="'Enter Scenario Description'"
                [cssClass]="'e-outline'"
                [floatLabelType]="'Never'"
              ></ejs-textbox>
            </div>
            <div>
              <label
                >Number Of Comparison <span class="text-red-600">*</span></label
              >
              <div class="radio_block">
                @for (comparison of comparisonNumbers; track comparison) {
                <label
                  class="radio-btn"
                  for="{{ comparison.name }}_{{ i }}_number_of_comparison"
                >
                  <input
                    type="radio"
                    id="{{ comparison.name }}_{{ i }}_number_of_comparison"
                    formControlName="numberOfRevenueForecasts"
                    [value]="comparison.id"
                    (change)="
                      comparisonNumberChange(
                        scenarioFormArray.get('numberOfRevenueForecasts')
                          ?.value,
                        i
                      )
                    "
                  />
                  {{ comparison.name }}
                </label>
                <!-- <ejs-radiobutton
                  [label]="comparison.name"
                  id="{{comparison.name}}_{{i}}_number_of_comparison"
                  name="numberOfComparison"
                  formControlName="numberOfRevenueForecasts"
                  [value]="comparison.id"
                  (change)="comparisonNumberChange($event, i)"
                ></ejs-radiobutton> -->
                }
              </div>
            </div>
            <div>
              <label>Base Date Range <span class="text-red-600">*</span></label>
              @if(scenarioFormArray.get('scenarioType')?.value ===
              scenarioType[0].id){
              <ejs-daterangepicker
                [max]="minDate"
                placeholder="Date Range Picker"
                formControlName="baseDateRange"
              ></ejs-daterangepicker>
              } @if(scenarioFormArray.get('scenarioType')?.value ===
              scenarioType[1].id){
              <ejs-daterangepicker
                [min]="minDate"
                placeholder="Date Range Picker"
                formControlName="baseDateRange"
              ></ejs-daterangepicker>
              }
            </div>
          </div>
          <div class="card space-y-2">
            @if(scenarioFormArray.get('scenarioType')?.value ===
            scenarioType[0].id){
            <div>
              <label>Quantity</label>
              <ejs-dropdownlist
                [cssClass]="'e-outline'"
                [allowFiltering]="true"
                [placeholder]="'Select Quantity'"
                id="entity"
                [fields]="{ text: 'measure', value: 'value' }"
                [dataSource]="actualScenarioDropdownValue"
                formControlName="quantity"
                (filtering)="onFiltering($event)"
              ></ejs-dropdownlist>
            </div>
            <div>
              <label>Gross Revenue</label>
              <ejs-dropdownlist
                [cssClass]="'e-outline'"
                [allowFiltering]="true"
                [placeholder]="'Select Gross Revenue'"
                id="product"
                [dataSource]="actualScenarioDropdownValue"
                [fields]="{ text: 'measure', value: 'value' }"
                formControlName="grossRevenue"
                (filtering)="onFiltering($event)"
              ></ejs-dropdownlist>
            </div>
            } @if(scenarioFormArray.get('scenarioType')?.value ===
            scenarioType[1].id){
            <div>
              <label>Forecast Name</label>
              <ejs-dropdownlist
                [cssClass]="'e-outline'"
                [allowFiltering]="true"
                [placeholder]="'Select Forecast Name'"
                id="forecastName"
                [dataSource]="forecastNames"
                formControlName="forecastName"
                (filtering)="onForecastNameFiltering($event)"
              ></ejs-dropdownlist>
            </div>
            <div>
              <label>Forecast Demand Name</label>
              <ejs-dropdownlist
                [cssClass]="'e-outline'"
                [allowFiltering]="true"
                [placeholder]="'Select Forecast Demand Name'"
                id="forecastDemandName"
                [dataSource]="forecastDemandNames"
                formControlName="forecastDemandName"
              ></ejs-dropdownlist>
            </div>
            <div>
              <label>Forecast Measure</label>
              <ejs-dropdownlist
                [cssClass]="'e-outline'"
                [allowFiltering]="false"
                [placeholder]="'Forecast Measure'"
                id="forecastMeasure"
                [dataSource]="forecastMeasures"
                formControlName="forecastMeasure"
              ></ejs-dropdownlist>
            </div>
            @if(scenarioFormArray.get('forecastMeasure')?.value === 'Units') {
            <div>
              <label>Forecast Price Types</label>
              <ejs-dropdownlist
                [cssClass]="'e-outline'"
                [allowFiltering]="true"
                [placeholder]="'Forecast Price Types'"
                id="forecastPriceType"
                [dataSource]="forecastPriceTypes"
                formControlName="forecastPriceType"
              ></ejs-dropdownlist>
            </div>
            } @if(scenarioFormArray.get('forecastMeasure')?.value === 'Dollars')
            {
            <div>
              <label>Forecast Revenue Metrics</label>
              <ejs-dropdownlist
                [cssClass]="'e-outline'"
                [allowFiltering]="false"
                [placeholder]="'Forecast Price Types'"
                id="forecastRevenueMetric"
                [dataSource]="forecastRevenueMetrics"
                formControlName="forecastRevenueMetric"
              ></ejs-dropdownlist>
            </div>
            } }
            <div>
              <label>Simulation Growth Values</label>
              <div class="radio_block radio_transparent justify-between my-3">
                @for (growthValues of growthValueOptions; track
                growthValues.name) {
                <label
                  class="radio-btn"
                  for="{{ growthValues.name }}_{{ i }}_growth_values"
                >
                  <input
                    type="radio"
                    id="{{ growthValues.name }}_{{ i }}_growth_values"
                    formControlName="simulationGrowthType"
                    [value]="growthValues.id"
                    (change)="
                      growthValueSliderCreate(
                        scenarioFormArray.get('numberOfRevenueForecasts')
                          ?.value,
                        i
                      )
                    "
                  />
                  {{ growthValues.name }}
                </label>
                <!-- <ejs-radiobutton
                  [label]="growthValues.name"
                  name="simulationGrowthValues"
                  formControlName="simulationGrowthType"
                  [value]="growthValues.id"
                  (change)="
                    growthValueSliderCreate(
                      scenarioFormArray.get('numberOfRevenueForecasts')?.value,
                      i
                    )
                  "
                ></ejs-radiobutton> -->
                }
              </div>
            </div>
            <div class="grid sm:grid-cols-2">
              <div class="col-span-2">
                @if(scenarioFormArray.get('simulationGrowthType')?.value ===
                growthValueOptions[0].id){
                <label>Growth (%)</label>
                <ng-container formArrayName="growthUserProvided">
                  @for(growthUserProvided of growthUserProvidedArr(i).controls;
                  track growthUserProvided; let g = $index) {
                  <ng-container [formGroupName]="g">
                    <div class="flex gap-2 items-center mb-2">
                      <ejs-slider
                        formControlName="growthUserProvidedSlider"
                        [value]="
                          growthUserProvided.get('growthUserProvidedSlider')
                            .value
                        "
                        id="slider{{ g }}"
                        [type]="minRange"
                        min='0'
                        max='300'
                        [limits]="userProvidedLimits"
                        [tooltip]="sliderTooltip"
                        [ticks]="userProvidedTicksData"
                      ></ejs-slider>
                      <div class="flex">
                        <input
                          type="number"
                          min="0"
                          max="300"
                          class="custom-input small-text-box"
                          [value]="
                            growthUserProvided.get('growthUserProvidedSlider')
                              .value || 0
                          "
                          formControlName="growthUserProvidedSlider"
                        />(%)
                      </div>
                    </div>
                  </ng-container>
                  }
                </ng-container>
                } @if(scenarioFormArray.get('simulationGrowthType').value ===
                growthValueOptions[1].id){
                <label>Growth (%)</label>
                <div class="flex gap-2 items-center justify-between">
                  <div>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      class="custom-input small-text-box"
                      formControlName="growthMonteCarloRangeStart"
                      (ngModelChange)="growthMonteCarloRangeInputBoxChange(i)"
                    />(%)
                  </div>
                  <div>
                    <input
                      type="number"
                      min="0"
                      max="100"
                      class="custom-input small-text-box"
                      formControlName="growthMonteCarloRangeEnd"
                      (ngModelChange)="growthMonteCarloRangeInputBoxChange(i)"
                    />(%)
                  </div>
                </div>
                <div class="wrap">
                  <ejs-slider
                    id="slider"
                    [type]="range"
                    [tooltip]="sliderTooltip"
                    [limits]="rangeLimits"
                    (changed)="growthMonteCarloRangeChange(i)"
                    formControlName="growthMonteCarloRange"
                    [ticks]="rangeTicksData"
                  ></ejs-slider>
                </div>
                }
              </div>
            </div>
          </div>
          <div class="card space-y-2">
            <div class="card fix-height overflow-auto">
              <div class="grid gap-2">
                <ng-container formArrayName="discounts">
                  @if (!scenarioFormArray?.get('discounts').value?.length) {
                  <div class="border-b">
                    <div class="flex justify-between items-center">
                      <div class="mb-1">
                        <label
                          >Discounts <span class="text-red-600">*</span></label
                        >
                      </div>
                      <div class="flex justify-items-center gap-2 items-center">
                        <div>
                          <div class="custom-select">
                            @if(scenarioFormArray.get('scenarioType')?.value ===
                            scenarioType[0].id){
                            <ejs-menu
                              #menu
                              [items]="actualMenuItems"
                              showItemOnClick="true"
                              (select)="
                                itemSelect($event, i);
                                discountGrowthValueSliderCreate(
                                  $event,
                                  scenarioFormArray.get(
                                    'numberOfRevenueForecasts'
                                  )?.value,
                                  i,
                                  0
                                )
                              "
                            ></ejs-menu>
                            } @if(scenarioFormArray.get('scenarioType')?.value
                            === scenarioType[1].id){
                            <ejs-menu
                              #menu
                              [items]="forecastMenuItems"
                              showItemOnClick="true"
                              (select)="
                                itemSelect($event, i);
                                discountGrowthValueSliderCreate(
                                  $event,
                                  scenarioFormArray.get(
                                    'numberOfRevenueForecasts'
                                  )?.value,
                                  i,
                                  0
                                )
                              "
                            ></ejs-menu>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <b>Note:</b> For Save/Update Scenario atleast one discount
                    is required.
                  </div>
                  } @for(discountArray of scenariosDiscount(i).controls; track
                  discountArray; let j = $index) {
                  <ng-container [formGroupName]="j">
                    <div [ngClass]="{ 'border-b': j === 0 }">
                      <div class="flex justify-between items-center">
                        <div>
                          @if(j === 0){
                          <label
                            >Discounts
                            <span class="text-red-600">*</span></label
                          >
                          }
                        </div>
                        <div
                          class="flex justify-items-center gap-2 items-center"
                        >
                          <div>
                            @if(j === 0){
                            <div class="custom-select">
                              @if(scenarioFormArray.get('scenarioType')?.value ===
                              scenarioType[0].id){
                              <ejs-menu
                                #menu
                                showItemOnClick="true"
                                [items]="actualMenuItems"
                                (select)="
                                  itemSelect($event, i);
                                  discountGrowthValueSliderCreate(
                                    $event,
                                    scenarioFormArray.get(
                                      'numberOfRevenueForecasts'
                                    )?.value,
                                    i,
                                    j + 1,
                                    true
                                  )
                                "
                              ></ejs-menu>
                              }
                              @if(scenarioFormArray.get('scenarioType')?.value ===
                              scenarioType[1].id){
                              <ejs-menu
                                #menu
                                showItemOnClick="true"
                                [items]="forecastMenuItems"
                                (select)="
                                  itemSelect($event, i);
                                  discountGrowthValueSliderCreate(
                                    $event,
                                    scenarioFormArray.get(
                                      'numberOfRevenueForecasts'
                                    )?.value,
                                    i,
                                    j + 1,
                                    true
                                  )
                                "
                              ></ejs-menu>
                              }
                            </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--Historical Form Preview Start-->
                    @if(discountArray.get('discountType').value ===
                    discountType[0].id) {
                    <div class="flex items-center justify-between">
                      <label>Discount</label>
                      <div class="flex gap-2 items-center">
                        @if(discountArray.get('discountType').value){
                        <div
                          class="e-badge"
                          [ngClass]="
                            discountArray.get('discountType').value ===
                            'Historical'
                              ? 'e-badge-primary'
                              : 'e-badge-secondary'
                          "
                        >
                          {{ discountArray.get("discountType").value }}
                        </div>
                        }
                        <i
                          class="fa-solid fa-trash-can text-red-600 cursor-pointer"
                          (click)="removeDiscount(i, j)"
                        ></i>
                      </div>
                    </div>
                    <ejs-dropdownlist
                      [cssClass]="'e-outline'"
                      [allowFiltering]="true"
                      [dataSource]="actualScenarioDropdownValue"
                      [placeholder]="'Select Discount'"
                      [fields]="{ text: 'measure', value: 'value' }"
                      formControlName="historicalDiscount"
                      (filtering)="onFiltering($event)"
                    ></ejs-dropdownlist>
                    }
                    <!--Historical Form Preview End-->
                    <!--New Form Preview Start-->
                    @if(discountArray.get('discountType').value ===
                    discountType[1].id) {
                    <div class="grid grid-cols-4 gap-3">
                      <div class="col-span-2">
                        <label class="mb-1">Name</label>
                        <ejs-textbox
                          formControlName="discountName"
                          class="m-0"
                          [placeholder]="'Enter Name'"
                          [cssClass]="'e-outline'"
                          [floatLabelType]="'Never'"
                        ></ejs-textbox>
                      </div>
                      <div class="col-span-2">
                        <div class="flex justify-between mb-1">
                          <label>Value</label>
                          <div class="flex gap-2 items-center">
                            @if(discountArray.get('discountType').value){
                            <div
                              class="e-badge"
                              [ngClass]="
                                discountArray.get('discountType').value ===
                                'Historical'
                                  ? 'e-badge-primary'
                                  : 'e-badge-secondary'
                              "
                            >
                              {{ discountArray.get("discountType").value }}
                            </div>
                            }
                            <i
                              class="fa-solid fa-trash-can text-red-600 cursor-pointer"
                              (click)="removeDiscount(i, j)"
                            ></i>
                          </div>
                        </div>
                        <ejs-textbox
                          type="number"
                          formControlName="discountValue"
                          class="m-0"
                          [placeholder]="'Enter Value'"
                          [cssClass]="'e-outline'"
                          [floatLabelType]="'Never'"
                        ></ejs-textbox>
                      </div>
                    </div>
                    }
                    <!--New Form Preview End-->
                    @if(discountArray.get('discountType').value) {
                    @if(scenarioFormArray.get('simulationGrowthType').value ===
                    growthValueOptions[0].id) {
                    <label>Growth (%)</label>
                    <div class="grid sm:grid-cols-2">
                      <!--need to check-->
                      <ng-container formArrayName="discountGrowthUserProvided">
                        @for(discountGrowthUser of
                        discountGrowthUserProvidedArr(i,j)?.controls; track
                        discountGrowthUser; let dg = $index) {
                        <ng-container [formGroupName]="dg">
                          <div class="col-span-2">
                            <div class="flex gap-2 items-center mb-2">
                              <ejs-slider
                                formControlName="growthUserProvidedSlider"
                                [value]="
                                  discountGrowthUser.get(
                                    'growthUserProvidedSlider'
                                  ).value
                                "
                                id="sliderItem{{ j }}_{{ dg }}"
                                [type]="minRange"
                                min='0'
                                max='300'
                                [limits]="userProvidedLimits"
                                [tooltip]="sliderTooltip"
                                [ticks]="userProvidedTicksData"
                              ></ejs-slider>
                              <div class="flex">
                                <input
                                  type="number"
                                  min="0"
                                  max="300"
                                  class="custom-input small-text-box"
                                  [value]="
                                    discountGrowthUser.get(
                                      'growthUserProvidedSlider'
                                    ).value || 0
                                  "
                                  formControlName="growthUserProvidedSlider"
                                />(%)
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        }
                      </ng-container>
                    </div>
                    } @if(scenarioFormArray.get('simulationGrowthType').value
                    === growthValueOptions[1].id) {
                    <label>Growth(%)</label>
                    <div class="grid sm:grid-cols-2">
                      <div class="col-span-2">
                        <div class="flex gap-2 items-center justify-between">
                          <div>
                            <input
                              type="number"
                              min="0"
                              max="100"
                              class="custom-input small-text-box"
                              formControlName="discountGrowthMonteCarloRangeStart"
                              (ngModelChange)="
                                discountGrowthMonteCarloRangeInputBoxChange(
                                  i,
                                  j
                                )
                              "
                            />(%)
                          </div>
                          <div>
                            <input
                              type="number"
                              min="0"
                              max="100"
                              class="custom-input small-text-box"
                              formControlName="discountGrowthMonteCarloRangeEnd"
                              (ngModelChange)="
                                discountGrowthMonteCarloRangeInputBoxChange(
                                  i,
                                  j
                                )
                              "
                            />(%)
                          </div>
                        </div>
                        <ejs-slider
                          id="slider{{ i }}_{{ j }}"
                          [type]="range"
                          (changed)="discountGrowthMonteCarloRangeChange(i, j)"
                          [limits]="rangeLimits"
                          [tooltip]="sliderTooltip"
                          [ticks]="rangeTicksData"
                          formControlName="discountGrowthMonteCarloRange"
                        ></ejs-slider>
                      </div>
                    </div>
                    } }
                  </ng-container>
                  }
                </ng-container>
              </div>
            </div>
            <div
              class="flex mt-4 gap-4 items-center justify-end"
            >
              <button
                class="btn-primary sm:w-auto w-full"
                [disabled]="
                  !scenarioFormArray?.get('discounts')?.value?.length ||
                  scenarioFormArray?.invalid
                "
                (click)="
                  onSavescenariosForm(
                    i,
                    scenarioFormArray.get('scenarioType').value
                  )
                "
              >
                {{
                  scenarioFormArray?.get("scenarioId")?.value?.length
                    ? "Update"
                    : "Save"
                }}
              </button>
              <button class="btn-secondary sm:w-auto w-full">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Show Comparison  -->
    <div class="mt-3">
      <app-scenario-comparison
        [scenarioComparisonFlag]="
          scenarioFormArray.get('showScenarioComparisonFlag').value
        "
        [simulationId]="simulationId"
        [scenarioId]="scenarioFormArray.get('scenarioId').value"
      ></app-scenario-comparison>
    </div>
    }
  </ng-container>
</form>
}
<!--Create Scenario Actual Code End-->
