<div class="ai-assistview">
  <div class="left-content">
    <div class="header">Search History</div>
    <div class="content">
      <div class="note-title">
        <div class="heading">Planning and Requirements</div>
        <div class="sub-heading">Outline the process of gathering input from stakeholders</div>
      </div>
      <div class="note-title">
        <div class="heading">Meeting with Stakeholders</div>
        <div class="sub-heading">Discuss strategies for conducting productive meetings with stakeholders</div>
      </div>
      <div class="note-title">
        <div class="heading">Risk Management and Problem-Solving</div>
        <div class="sub-heading">Offer tips on how to proactively manage challenges, including regular</div>
      </div>
    </div>
  </div>
  <div class="right-content">
    <div class="default-aiassistview">
      <div id="aiAssistView" ejs-aiassistview #defaultAIAssistView [promptSuggestions]="suggestions"
        [toolbarSettings]="assistViewToolbarSettings" (promptRequest)="promptRequest($event)"
        cssClass="custom-aiassistview">
        <!-- <ng-template #bannerTemplate>
          <div class="banner-content">
            <div class="e-icons e-assistview-icon"></div>
            <h3>AI Assistance</h3>
            <i>To get started, provide input or choose a suggestion.</i>
          </div>
        </ng-template> -->
      </div>

    </div>
  </div>
</div>