import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { HttpBackend, provideHttpClient } from '@angular/common/http';
import { graphqlProvider } from './graphql/graphql.provider';
import { OktaAuthConfigService, OktaAuthModule } from '@okta/okta-angular';
import { configOktaInitializer } from './okta/okta.config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }), 
    provideRouter(routes), 
    provideClientHydration(), 
    provideHttpClient(),
    graphqlProvider,
    importProvidersFrom(
      OktaAuthModule
    ),
    { provide: APP_INITIALIZER, useFactory: configOktaInitializer, deps: [HttpBackend, OktaAuthConfigService], multi: true },
  ]
};
