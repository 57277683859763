import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  RadioButtonModule,
  ButtonModule,
  CheckBoxModule,
  ChipListModule,
  SwitchModule,
  ClickEventArgs,
} from '@syncfusion/ej2-angular-buttons';
import {
  CheckBoxSelectionService,
  DropDownListModule,
  MultiSelectModule,
} from '@syncfusion/ej2-angular-dropdowns';
import {
  ExcelExportService,
  ExportType,
  FilterService,
  GridComponent,
  GridModule,
  ResizeService,
  ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import {
  ContractsReferenceData,
  DropdownType,
  FormularyReferenceData,
  GetSimulationDetails,
  MarketshareReferenceData,
  ReferenceDataParameters,
  SaveSimulation,
  SimulationOptionsChannels,
  SimulationOptionsEntities,
  SimulationOptionsProducts,
} from '../../shared/Interface/generic.model';
import { ReferenceData } from '../../shared/enum/generic.enum';
import {
  CategoryService,
  LineSeriesService,
  StepLineSeriesService,
  SplineSeriesService,
  StackingLineSeriesService,
  DateTimeService,
  SplineAreaSeriesService,
  MultiColoredLineSeriesService,
  ParetoSeriesService,
  ColumnSeriesService,
  BarSeriesService,
  ChartModule,
  DataLabelService,
  WaterfallSeriesService,
  ChartComponent,
  ExportService,
  TooltipService,
} from '@syncfusion/ej2-angular-charts';
import {
  DropDownButtonModule,
} from '@syncfusion/ej2-angular-splitbuttons';
import { SimulationGraphqlService } from '../../graphql/simulation-graphql.service';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastUtility } from '@syncfusion/ej2-notifications';
import {
  ToastComponent,
  ToastModule,
} from '@syncfusion/ej2-angular-notifications';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from '../../shared/services/common.service';
import { GenericService } from '../../graphql/generic.service';

@Component({
  selector: 'app-simulation',
  providers: [
    CategoryService,
    CheckBoxSelectionService,
    ResizeService,
    DataLabelService,
    LineSeriesService,
    StepLineSeriesService,
    SplineSeriesService,
    StackingLineSeriesService,
    DateTimeService,
    SplineAreaSeriesService,
    MultiColoredLineSeriesService,
    WaterfallSeriesService,
    BarSeriesService,
    ParetoSeriesService,
    ColumnSeriesService,
    ExcelExportService,
    ToolbarService,
    FilterService,
    ExportService,
    TooltipService,
  ],
  standalone: true,
  imports: [
    TextBoxModule,
    ToastModule,
    MultiSelectModule,
    CommonModule,
    ReactiveFormsModule,
    RadioButtonModule,
    ButtonModule,
    CheckBoxModule,
    DropDownListModule,
    DropDownButtonModule,
    ChipListModule,
    SwitchModule,
    GridModule,
    ChartModule,
    NgxUiLoaderModule,
  ],
  templateUrl: './simulation.component.html',
  styleUrl: './simulation.component.scss',
})
export class SimulationComponent implements OnInit {
  @Input() scenarioLength: number = 0;
  @Output() createScenarioFlag: EventEmitter<boolean> = new EventEmitter(false);
  @Output() simulationIdEmitter: EventEmitter<string> = new EventEmitter();
  @Output() entityTypeEmitter: EventEmitter<string> = new EventEmitter();
  @ViewChild('contractGrid') public contractGrid?: GridComponent;
  @ViewChild('chart') public chart?: ChartComponent;
  @ViewChild('formularyGrid') public formularyGrid?: GridComponent;
  @ViewChild('marketShareGrid') public marketShareGrid?: GridComponent;
  simulationForm!: FormGroup;
  entityListData: SimulationOptionsEntities[] = [];
  productListData: SimulationOptionsProducts[] = [];
  channelListData: SimulationOptionsChannels[] = [];
  entityType: string[] = [];
  referenceData: DropdownType[] = ReferenceData;
  gpPricesData: DropdownType[] = [];
  formularyTableData: FormularyReferenceData[] = [];
  contractTableData: ContractsReferenceData[] = [];
  tableReferenceDatePreview = [false, false, false];
  marketShareChartData: MarketshareReferenceData[] = [];
  title?: string;
  marker?: Object;
  primaryXAxis?: Object;
  primaryYAxis?: Object;
  tooltip?: Object;
  connector?: Object;
  referenceDataShipSelected = [];
  selectedGpPrices: string[] = [];
  getSimulationDetails: GetSimulationDetails;
  subscribe?: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private simulationGraphqlService: SimulationGraphqlService,
    private loader: NgxUiLoaderService,
    private genericService: GenericService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService
  ) {}

  toggleSections() {
    this.createScenarioFlag.emit(true);
    this.commonService.createScenarioState(true);
  }

  exportContractsTable(exportType: string): void {
    if (!this.contractTableData.length) {
      return;
    }
    if (exportType === 'csv') {
      (this.contractGrid as GridComponent).csvExport();
    } else {
      (this.contractGrid as GridComponent).excelExport();
    }
  }

  exportFormularyTable(exportType: string): void {
    if (!this.formularyTableData.length) {
      return;
    }
    if (exportType === 'csv') {
      (this.formularyGrid as GridComponent).csvExport();
    } else {
      (this.formularyGrid as GridComponent).excelExport();
    }
  }

  referenceDataChipClicked(e: ClickEventArgs) {
    if (
      e.selected &&
      e.index === 0 &&
      this.simulationForm.controls['entityType'].value !== 'PBM'
    ) {
      e.selected = false;
      return;
    }
    if (e.text) {
      const currIndex = this.referenceDataShipSelected.indexOf(e.text);
      if (e.index === 0) {
        if (e.selected) {
          this.referenceDataShipSelected.push(e.text);
        } else {
          if (currIndex > -1) {
            this.referenceDataShipSelected.splice(currIndex, 1);
          }
        }
      }
      if (e.index === 1) {
        if (e.selected) {
          this.referenceDataShipSelected.push(e.text);
        } else {
          if (currIndex > -1) {
            this.referenceDataShipSelected.splice(currIndex, 1);
          }
        }
      }
      if (e.index === 2) {
        if (e.selected) {
          this.referenceDataShipSelected.push(e.text);
        } else {
          if (currIndex > -1) {
            this.referenceDataShipSelected.splice(currIndex, 1);
          }
        }
      }
    }
  }

  getMarketShareGraphData() {
    const payload: ReferenceDataParameters = {
      entityId: this.simulationForm.controls['entityId'].value || '',
      brandName: this.simulationForm.controls['brandName'].value || '',
      channelGroup: this.simulationForm.controls['channelGroup'].value || '',
      entityType: this.simulationForm.controls['entityType'].value || '',
    };
    this.loader.start();
    this.simulationGraphqlService
      .getMarketshareReference(payload)
      .subscribe((graphData) => {
        const prepareGraphArr = [];
        graphData?.forEach((graphElement, index) => {
          prepareGraphArr.push({
            x:
              graphElement.quarter +
              ' (' +
              graphElement.totalApprovedClaims +
              ')',
            y: parseFloat(graphElement.marketShare.toFixed(2)),
            color: index % 2 === 0 ? '#7851A9' : '#78B751',
          });
        });
        this.marketShareChartData = [...prepareGraphArr];
        this.loader.stop();
      }),
      () => {
        this.loader.stop();
      };
  }

  simulationFormInitialize() {
    this.simulationForm = this.formBuilder.group({
      simulationId: [{ value: '', disabled: true }],
      simulationName: ['', Validators.required],
      simulationDescription: [''],
      entityType: ['', Validators.required],
      entityId: [{ value: '', disabled: true }, Validators.required],
      brandName: [{ value: '', disabled: true }],
      channelId: [{ value: '', disabled: true }],
      channelGroup: [{ value: '', disabled: true }],
      gpImpact: [false],
      gpPrices: [''],
    });
  }

  toggleGpPrice(gpPrice: string, checked: boolean) {
    if (checked) {
      const selectionArray = [...this.selectedGpPrices, gpPrice];
      this.selectedGpPrices = [...selectionArray];
    } else {
      const filterArray = this.selectedGpPrices.filter(
        (obj) => obj !== gpPrice
      );
      this.selectedGpPrices = [...filterArray];
    }
  }

  exportChart(exportType: ExportType | string): void {
    if (
      (exportType === 'JPEG' || exportType === 'PNG') &&
      this.chart?.exportModule
    ) {
      this.chart.exportModule.export(exportType, 'MarketShareChart');
    }
  }

  onSaveSimulation() {
    if (this.simulationForm.invalid) {
      return;
    }
    let payload: SaveSimulation = {
      simulationId: this.simulationForm.controls['simulationId'].value,
      simulationName: this.simulationForm.controls['simulationName'].value,
      simulationDescription:
        this.simulationForm.controls['simulationDescription'].value,
      parameters: {
        entityType: this.simulationForm.controls['entityType'].value,
        entityId: this.simulationForm.controls['entityId'].value,
        brandName: this.simulationForm.controls['brandName'].value,
        channelId: this.simulationForm.controls['channelId'].value,
        referenceData: this.referenceDataShipSelected?.length
          ? this.referenceDataShipSelected
          : null,
        gpImpact: this.simulationForm.controls['gpImpact'].value,
        gpPrices: this.selectedGpPrices,
      },
    };
    this.loader.start();
    this.simulationGraphqlService.saveSimulation(payload).subscribe(
      (response: any) => {
        if (response?.data?.saveSimulation) {
          ToastUtility.show(
            {
              content: 'Simulation Data Successfully Saved!',
              timeOut: 10000,
              cssClass: 'e-toast-success',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Information'
          ) as ToastComponent;
          this.simulationForm.controls['simulationId'].setValue(
            response?.data?.saveSimulation?.simulationId
          );
          this.simulationIdEmitter.emit(
            response?.data?.saveSimulation?.simulationId
          );
          this.entityTypeEmitter.emit(
            this.simulationForm.controls['entityType'].value
          );
          this.simulationForm.markAsPristine();
          this.getAPICalledManage();
        } else {
          ToastUtility.show(
            {
              content: 'Simulation Data Not Saved! Please Try Again Later',
              timeOut: 10000,
              cssClass: 'e-toast-danger',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Error'
          ) as ToastComponent;
        }
        this.loader.stop();
      },
      () => {
        this.loader.stop();
      }
    );
  }

  entityTypeSelectionChange(entityType: string) {
    if (entityType) {
      this.simulationForm.get('entityId')?.enable();
      this.simulationForm.get('brandName')?.enable();
      this.simulationForm.get('channelId')?.enable();
    } else {
      this.simulationForm.get('entityId')?.disable();
      this.simulationForm.get('brandName')?.disable();
      this.simulationForm.get('channelId')?.disable();
    }
    this.referenceDataShipSelected = [];
    this.tableReferenceDatePreview = [false, false, false];
    this.getEntityLists(entityType);
    this.getProductTypeLists(entityType);
    this.getChannelsTypeLists(entityType);
    this.referenceDataManage();
    this.entityTypeEmitter.emit(entityType);
  }

  getFormularyTableData() {
    const payload: ReferenceDataParameters = {
      entityId: this.simulationForm.controls['entityId'].value || '',
      brandName: this.simulationForm.controls['brandName'].value || '',
      channelGroup: this.simulationForm.controls['channelGroup'].value || '',
    };
    this.loader.start();
    this.simulationGraphqlService.getFormularyList(payload).subscribe(
      (formularyReference: FormularyReferenceData[]) => {
        this.formularyTableData = formularyReference?.length
          ? formularyReference
          : [];
        this.loader.stop();
      },
      () => {
        this.loader.stop();
      }
    );
  }

  getContractsTableData() {
    this.loader.start();
    const payload: ReferenceDataParameters = {
      entityId: this.simulationForm.controls['entityId'].value || '',
      brandName: this.simulationForm.controls['brandName'].value || '',
      channelGroup: this.simulationForm.controls['channelGroup'].value || '',
    };
    this.simulationGraphqlService.getContractsList(payload).subscribe(
      (contractsReference: ContractsReferenceData[]) => {
        this.contractTableData = contractsReference?.length
          ? contractsReference
          : [];
        this.loader.stop();
      },
      () => {
        this.loader.stop();
      }
    );
  }

  getEntityLists(entityType: string) {
    this.loader.start();
    this.simulationGraphqlService
      .getEntityTypeSelectionChange(entityType)
      .subscribe(
        (entityList: SimulationOptionsEntities[]) => {
          this.entityListData = entityList?.length ? entityList : [];
          this.simulationForm.controls['entityId'].setValue(
            this.getSimulationDetails?.parameters?.entityId || ''
          );
          this.loader.stop();
        },
        () => {
          this.loader.stop();
        }
      );
  }

  referenceDataManage() {
    this.referenceData = [];
    setTimeout(() => {
      this.referenceData = ReferenceData;
    });
  }

  getProductTypeLists(entityType: string) {
    this.loader.start();
    this.simulationGraphqlService
      .getProductTypeSelectionChange(entityType)
      .subscribe(
        (productList: SimulationOptionsProducts[]) => {
          this.productListData = productList?.length ? productList : [];
          this.simulationForm.controls['brandName'].setValue(
            this.getSimulationDetails?.parameters?.brandName || ''
          );
          this.loader.stop();
        },
        () => {
          this.loader.stop();
        }
      );
  }

  getChannelsTypeLists(entityType: string) {
    this.loader.start();
    this.simulationGraphqlService
      .getChannelTypeSelectionChange(entityType)
      .subscribe(
        (channelList: SimulationOptionsChannels[]) => {
          this.channelListData = channelList?.length ? channelList : [];
          this.simulationForm.controls['channelId'].setValue(
            this.getSimulationDetails?.parameters?.channelId || ''
          );
          this.loader.stop();
        },
        () => {
          this.loader.stop();
        }
      );
  }

  getSimulationEntityType() {
    this.loader.start();
    this.simulationGraphqlService.getSimulationEntityType().subscribe(
      (entityType) => {
        this.entityType = entityType;
        this.loader.stop();
      },
      () => {
        this.loader.stop();
      }
    );
  }

  getGPPrices() {
    this.loader.start();
    this.simulationGraphqlService.getGPPrices().subscribe(
      (gpPricesData) => {
        this.gpPricesData = [];
        gpPricesData?.forEach((gpPricesData) => {
          this.gpPricesData.push({
            id: gpPricesData,
            name: gpPricesData,
            checked: false,
          });
        });
        this.loader.stop();
      },
      () => {
        this.loader.stop();
      }
    );
  }

  onProductFiltering(event: any) {
    const query = event.text.toLowerCase();
    const productListData = this.productListData.filter((item) =>
      item.name.toLowerCase().includes(query)
    );
    event.updateData(productListData);
  }

  onEntityFiltering(event: any) {
    const query = event.text.toLowerCase();
    const entityListData = this.entityListData.filter((item) =>
      item.name.toLowerCase().includes(query)
    );
    event.updateData(entityListData);
  }

  onChanelFiltering(event: any) {
    const query = event.text.toLowerCase();
    const channelListData = this.channelListData.filter((item) =>
      item.name.toLowerCase().includes(query)
    );
    event.updateData(channelListData);
  }

  getAPICalledManage() {
    this.referenceData = [...ReferenceData];
    if (this.referenceDataShipSelected?.length) {
      if (this.referenceDataShipSelected.includes(this.referenceData[0].id)) {
        this.tableReferenceDatePreview[0] = true;
        this.getFormularyTableData();
      }
      if (this.referenceDataShipSelected.includes(this.referenceData[1].id)) {
        this.tableReferenceDatePreview[1] = true;
        this.getContractsTableData();
      }
      if (this.referenceDataShipSelected.includes(this.referenceData[2].id)) {
        this.tableReferenceDatePreview[2] = true;
        this.getMarketShareGraphData();
      }
    }
  }

  setChannelGroupName(channelId: string) {
    const findChannelObj = this.channelListData.find(
      (obj) => obj.channelId === channelId
    );
    this.simulationForm.controls['channelGroup'].setValue(
      findChannelObj?.channelGroup || ''
    );
    this.getAPICalledManage();
  }

  getSimulationDetailsById(simulationId: string) {
    this.simulationGraphqlService
      .getSimulationDetailsById(simulationId)
      .subscribe((simulationDetails: GetSimulationDetails) => {
        if (simulationDetails) {
          this.getSimulationDetails = {
            ...simulationDetails,
            parameters: simulationDetails.parameters,
          };
          if (
            this.getSimulationDetails?.simulationId &&
            this.getSimulationDetails?.parameters?.entityType
          ) {
            this.entityTypeSelectionChange(
              this.getSimulationDetails?.parameters?.entityType
            );
            setTimeout(() => {
              this.setSimulationFormValue();
            }, 1000);
          }
        } else {
          ToastUtility.show(
            {
              content: 'Invalid simulation, redirecting to home page',
              timeOut: 2000,
              cssClass: 'e-toast-info',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Information'
          ) as ToastComponent;
          this.router.navigate([`/simulation-list`]);
        }
      });
  }

  setSimulationFormValue() {
    this.simulationForm.controls['simulationId'].setValue(
      this.getSimulationDetails?.simulationId || ''
    );
    this.simulationIdEmitter.emit(
      this.getSimulationDetails?.simulationId || ''
    );
    this.simulationForm.controls['simulationName'].setValue(
      this.getSimulationDetails?.simulationName || ''
    );
    this.simulationForm.controls['simulationDescription'].setValue(
      this.getSimulationDetails?.simulationDescription || ''
    );
    this.simulationForm.controls['entityType'].setValue(
      this.getSimulationDetails?.parameters?.entityType || ''
    );
    this.simulationForm.controls['gpImpact'].setValue(
      this.getSimulationDetails?.parameters?.gpImpact || false
    );
    this.simulationForm.controls['gpPrices'].setValue(
      this.getSimulationDetails?.parameters?.gpPrices || []
    );
    this.selectedGpPrices =
      this.getSimulationDetails?.parameters?.gpPrices || [];
    this.gpPricesData?.forEach((gpPricesData) => {
      gpPricesData.checked = this.selectedGpPrices?.includes(gpPricesData.name);
    });
    this.referenceDataShipSelected =
      this.getSimulationDetails?.parameters?.referenceData || [];
    this.referenceDataGetLogicManage();
  }

  referenceDataGetLogicManage() {
    this.referenceData = [...ReferenceData];
    if (this.getSimulationDetails?.parameters?.referenceData?.length) {
      if (
        this.getSimulationDetails?.parameters?.referenceData.includes(
          this.referenceData[0].id
        )
      ) {
        this.tableReferenceDatePreview[0] = true;
      }
      if (
        this.getSimulationDetails?.parameters?.referenceData.includes(
          this.referenceData[1].id
        )
      ) {
        this.tableReferenceDatePreview[1] = true;
      }
      if (
        this.getSimulationDetails?.parameters?.referenceData.includes(
          this.referenceData[2].id
        )
      ) {
        this.tableReferenceDatePreview[2] = true;
      }
      this.getAPICalledManage();
      this.referenceDataManage();
    }
  }

  ngOnInit(): void {
    this.simulationFormInitialize();
    this.getSimulationEntityType();
    this.getGPPrices();
    this.marker = {
      dataLabel: { visible: true, position: 'Outer' },
    };
    this.primaryXAxis = {
      valueType: 'Category',
      title: 'Quarter (Total Approved Claims)',
      majorGridLines: { width: 0 },
      minorGridLines: { width: 0 },
    };
    this.primaryYAxis = {
      title: 'Market Share (%)',
      labelFormat: '{value}%',
    };
    this.tooltip = {
      enable: true,
      format: 'Quarter (Total Approved Claims) : ${point.x}',
    };
    this.title = 'Market Share Trend';
    this.connector = { color: '#0070C0', width: 1 };
    this.subscribe = this.route.params.subscribe((params) => {
      if (params['id']) {
        this.getSimulationDetailsById(params['id']);
      }
    });
    this.subscribe = this.genericService.formDirtyFlag$.subscribe(
      (alertPopup) => {
        if (alertPopup) {
          this.genericService.simulationFormDirtyIdentify(this.simulationForm.dirty);
        }
      }
    );
  }

  // onTooltipRender(args: any) {
  //   if (args.data) {
  //     const pointData = this.marketShareChartData.find((item:any) => item.x === args.data.pointX);
  //     if (pointData) {
  //       args.point.text = pointData.totalApprovedClaims;
  //     }
  //   }
  // }

  ngOnDestory(): void {
    this.subscribe?.unsubscribe();
  }
}
