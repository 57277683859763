import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '../services/common.service';

@Injectable({
  providedIn: 'root',
})
export class noAuthGuard {
  constructor(
    private router: Router,
    private commonService: CommonService
  ) {}

  public canMatch(): boolean {
    const token = this.commonService.accessToken;
    if (!token) {
      return true;
    } else {
      this.router.navigateByUrl('/');
      return false;
    }
  }
}
