import { NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { FooterComponent } from './shared/layout/footer/footer.component';
import { HeaderComponent } from './shared/layout/header/header.component';
import { UserSidebarComponent } from './shared/sidebar/sidebar.component';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, FooterComponent, HeaderComponent, NgxUiLoaderModule, UserSidebarComponent, NgClass],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {

  private router = inject(Router);
  private route = inject(ActivatedRoute);

  public showHeaderFooter: boolean = true;
  public isSideBarOpen: boolean = false;

  constructor() {
    const queryParams = new URLSearchParams(window.location.search);
    const newToken = queryParams.get('token')
    const existingToken = localStorage.getItem('predealtoken');
    if (newToken !== existingToken && newToken) {
      localStorage.clear();
    }
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.isSideBarOpen = this.route.firstChild.snapshot.data['isSideBarOpen'];
        this.showHeaderFooter = this.router.url.includes('login') ? false : true;
      }
    });
  }
}
