@if (scenarioComparisonFlag) {
<div class="space-y-4">
    <!--Scenario Comparison Code Start-->
    <div class="card space-y-4" [formGroup]="actualScenarioComparisonForm" >
        <div class="scenario-actual-label inline-block">
            Scenario Comparison
        </div>
        <div class="card space-y-3 overflow-auto">
            @if (showPlaceholder) {
            <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
                <div>
                    <label>Scenario Id</label>
                    <ejs-textbox class="m-0" [placeholder]="'&lt;System Generated ID&gt;'" [cssClass]="'e-outline'"
                        [floatLabelType]="'Never'" formControlName="scenarioID"></ejs-textbox>
                </div>
                <div>
                    <label>Scenario Name</label>
                    <ejs-textbox class="m-0" [placeholder]="'Enter Scenario Name'" [cssClass]="'e-outline'"
                        [floatLabelType]="'Never'" formControlName="scenarioName"></ejs-textbox>
                </div>
                <div>
                    <label>Scenario Description</label>
                    <ejs-textbox class="m-0" [placeholder]="'Enter Scenario Description'" [cssClass]="'e-outline'"
                        [floatLabelType]="'Never'" formControlName="scenarioDecription"></ejs-textbox>
                </div>
                <div>
                    <label>Run Date</label>
                    <ejs-textbox class="m-0" [placeholder]="'&lt;Date Scenario was Last Updated&gt;'"
                        [cssClass]="'e-outline'" [floatLabelType]="'Never'" formControlName="runDate"></ejs-textbox>
                </div>
            </div>
            }
            @if (showPlaceholder) {
            <div class="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <div class="green-box border-[3px] border-dashed p-5 lg:p-20">
                    This is the Scenario Comparison Table, that will be defined in Excel and Provided Separately.
                </div>
                <div class="green-box border-[3px] border-dashed p-5 lg:p-20">
                    This is the waterfall chart for the base scenario, sample will be provided separately
                </div>
                <div class="blue-box md:col-span-2 border-[3px] border-dashed p-5 lg:p-20">
                    This is the waterfall chart for the base scenario, sample will be provided separately, system will
                    display a minimum of 1 and a maximum of 3 scenarios based on what the user has requested.
                </div>
            </div>
            }
            @if (isProcessing) {
            <div class="flex justify-center">
                <div role="status">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                    </svg>
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            }
            @else {
            <div class="lg:flex space-y-2 lg:space-y-0 gap-4">
                <!-- Step 1: Table -->
                <div class="green-box w-full border-[3px] border-dashed">
                    @if (comparisonTableData?.length) {
                        <div class="flex justify-end mt-2 me-2">
                            <div class="forecast-btn-section flex gap-2 sm:gap-4 items-center">
                            <i
                                class="fa-solid fa-file-excel cursor-pointer"
                                title="Export as Excel"
                                (click)="exportComparisonTableData('excel')"
                            ></i>
                            <i
                                class="fa-solid fa-file-csv cursor-pointer"
                                title="Export as CSV"
                                (click)="exportComparisonTableData('csv')"
                            ></i>
                            </div>
                        </div>
                    }
                    <!--display table in UI-->
                    <ejs-grid class="m-2 md:m-3" #comparisonGrid id="comparisonGrid"
                    [allowExcelExport]="true" [dataSource]='comparisonTableData'>
                        <e-columns>
                            <e-column field='Metrics' headerText='Metrics' width=150></e-column>
                            <e-column field='Base' headerText='Base' width=200>
                                <ng-template #template let-comparisonTableData>
                                    <span>
                                        {{ comparisonTableData['Base'] }}
                                        @if(comparisonTableData['Base Percent']) {
                                            ({{comparisonTableData['Base Percent']}}%)
                                        }
                                    </span>
                                </ng-template>
                            </e-column>
                            <e-column field='Rev Forecast 1' headerText='Revenue Forecast 1' type="number" width=200>
                                <ng-template #template let-comparisonTableData>
                                    <span>
                                        {{ comparisonTableData['Rev Forecast 1'] }}
                                        @if(comparisonTableData['Rev Forecast 1 Percent']) {
                                            ({{comparisonTableData['Rev Forecast 1 Percent']}}%)
                                        }
                                    </span>
                                </ng-template>
                            </e-column>
                            @if (tableColList.includes('Rev Forecast 2')) {
                            <e-column field='Rev Forecast 2' headerText='Revenue Forecast 2' type="number" width=200>
                                <ng-template #template let-comparisonTableData>
                                    <span>
                                        {{ comparisonTableData['Rev Forecast 2'] }}
                                        @if(comparisonTableData['Rev Forecast 2 Percent']) {
                                            ({{comparisonTableData['Rev Forecast 2 Percent']}}%)
                                        }
                                    </span>
                                </ng-template>
                            </e-column>
                            }
                            @if (tableColList.includes('Rev Forecast 3')) {
                            <e-column field='Rev Forecast 3' headerText='Revenue Forecast 3' type="number" width=200>
                                <ng-template #template let-comparisonTableData>
                                    <span>
                                        {{ comparisonTableData['Rev Forecast 3'] }}
                                        @if(comparisonTableData['Rev Forecast 3 Percent']) {
                                            ({{comparisonTableData['Rev Forecast 3 Percent']}}%)
                                        }
                                    </span>
                                </ng-template>
                            </e-column>
                            }
                        </e-columns>
                    </ejs-grid>
                    <!--display table in end-->
                    <!--download for table data start-->
                    <div class="hidden">
                        <ejs-grid class="m-2 md:m-3" #comparisonGridDownload id="comparisonGridDownload"
                            [allowExcelExport]="true" [dataSource]='comparisonTableDownloadData'>
                            <e-columns>
                                <e-column field='Metrics' headerText='Metrics' width=150></e-column>
                                <e-column field='Base' headerText='Base' width=200></e-column>
                                <e-column field='Base Percent' headerText='Base Percent' width=200></e-column>
                                <e-column field='Rev Forecast 1' headerText='Revenue Forecast 1' type="number" width=200></e-column>
                                <e-column field='Rev Forecast 1 Percent' headerText='Rev Forecast 1 Percent' type="number" width=200> </e-column>
                                @if (tableColList.includes('Rev Forecast 2')) {
                                <e-column field='Rev Forecast 2' headerText='Revenue Forecast 2' type="number" width=200></e-column>
                                <e-column field='Rev Forecast 2 Percent' headerText='Rev Forecast 2 Percent' type="number" width=200> </e-column>
                                }
                                @if (tableColList.includes('Rev Forecast 3')) {
                                <e-column field='Rev Forecast 3' headerText='Revenue Forecast 3' type="number" width=200></e-column>
                                <e-column field='Rev Forecast 3 Percent' headerText='Rev Forecast 3 Percent' type="number" width=200> </e-column>
                                }
                            </e-columns>
                        </ejs-grid>
                    </div>
                    <!--download for table data end-->
                </div>
                <!-- Step 2: Base Chart -->
                <div class="green-box border-[3px] border-dashed">
                    @if (baseChartData?.title) {
                    <div class="forecast-btn-section flex justify-end mt-2 me-2 gap-4 items-center">
                        <i class="fa-solid fa-file-image cursor-pointer" title="Export as JPG" (click)="exportChart('JPEG', scenarioId)"></i>
                        <i class="fa-regular fa-file-image cursor-pointer" title="Export as PNG" (click)="exportChart('PNG', scenarioId)"></i>
                    </div>
                    <div class="data-chart-table-section" id="baseChart{{scenarioId}}">
                        <!-- <div class="grid col-span-5"> -->
                            <div class="refrence-title-bar p-3 space-y-2 md:space-y-0 m-2 md:m-3 md:flex items-center justify-between">
                                <div>
                                    <h2 class="text-sm md:text-md">
                                        {{baseChartData?.title}}
                                    </h2>
                                    <div class="sm:flex space-y-2 sm:space-y-0 items-center gap-2 mt-1">
                                        <span><i class="fa-sharp fa-solid fa-signal-bars-good"></i>
                                        @if (baseChartData?.revenueData?.length) {
                                            $ {{baseChartData?.revenueData[0]?.value | number:'1.2-2'}}
                                        }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        <!-- </div> -->
                        <div class="lg:flex space-y-2 lg:space-y-0">
                            <div class="sm:py-3">
                                <div class="revenue-list p-2.5">
                                    @for (item of baseChartData.revenueData; track $index) {
                                    <div class="revenue-box">
                                        <h5 class="text-gray-500 tracking-wider" title="{{item.label}}">
                                            {{item.label}}
                                        </h5>
                                        <div class="flex items-center justify-between">
                                            <h6 class="font-semibold text-black truncate" title="${{item.value | number:'1.2-2'}}">
                                                ${{item.value | number:'1.2-2'}}
                                            </h6>
                                            @if (item.percent) {
                                            <span>{{item.percent | number:'1.2-2' }}%</span>
                                            }
                                        </div>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div class="overflow-x-auto pb-3">
                                <ejs-chart #baseChart class="chart-box" [attr.id]="'barchart-container-' + randomId" [primaryXAxis]='primaryXAxis'
                                    [primaryYAxis]='primaryYAxis' [title]='title'>
                                    <e-series-collection>
                                        <e-series [dataSource]='baseChartData.formatChartData' type='Waterfall' xName='x' yName='y'
                                            name='USA' [columnWidth]='columnWidth' [connector]='connector'
                                            [intermediateSumIndexes]='intermediate' [sumIndexes]='sum' [marker]='marker'
                                            pointColorMapping='color'>
                                           </e-series>
                                    </e-series-collection>
                                </ejs-chart>
                            </div>
                        </div>
                    </div>
                    }
                    @else {
                        <div class="p-5">
                            No records available to show the waterfall chart for the base scenario
                        </div>
                    }
                </div>
                <!-- Step 3:  Forecast Chart -->
                <div class="blue-box w-auto border-[3px] border-dashed p-2">
                    @if (forecastChartData?.length) {
                    <div class="lg:flex gap-2 space-y-2 lg:space-y-0">
                        @for (forecast of forecastChartData; let key = $index; track forecast.title) {
                        <div class="data-chart-table-section w-full">
                            <div class="forecast-btn-section flex justify-end gap-4 items-center mb-2">
                                <i
                                  class="fa-solid fa-file-image cursor-pointer"
                                  title="Export as JPG"
                                  (click)="exportForecastChart('JPEG','forecastchart' +scenarioId+ (key + 1), forecast.title)"
                                ></i>
                                <i
                                  class="fa-regular fa-file-image cursor-pointer"
                                  title="Export as PNG"
                                  (click)="exportForecastChart('PNG', 'forecastchart' +scenarioId+ (key + 1), forecast.title)"
                                ></i>
                              </div>
                            <div class="chart-background" id="forecastchart{{scenarioId}}{{key+1}}">
                                <!-- <div class="grid col-span-6"> -->
                                    <div
                                        class="refrence-title-bar p-3 space-y-2 md:space-y-0 md:flex items-center justify-between">
                                        <div>
                                            <h2 class="text-sm md:text-md">
                                                {{forecast.title}}
                                            </h2>
                                            <div class="sm:flex space-y-2 sm:space-y-0 items-center gap-2 mt-1">
                                                <span>
                                                    <i class="fa-sharp fa-solid fa-signal-bars-good"></i> $ {{forecast?.chartData[0]?.value | number:'1.2-2'}}
                                                </span>
                                                <div class="revenue-section">
                                                    <h6>
                                                        Revenue
                                                        <span>
                                                            {{forecast?.chartData[0]?.percent | number:'1.2-2'}} %
                                                        </span>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                <!-- </div> -->
                                <div class="lg:flex">
                                    <div class="sm:py-3">
                                        <div class="revenue-list custom-revenue p-2.5">
                                            @for (item of forecast.revenueData; track $index) {
                                            <div class="revenue-box">
                                                <h5 class="text-gray-500 tracking-wider"  title="{{item.label}}">
                                                    {{item.label}}
                                                </h5>
                                                <div class="flex items-center justify-between" title="${{item.value | number:'1.2-2'}}">
                                                    <h6 class="font-semibold text-black truncate">
                                                        ${{item.value | number:'1.2-2'}}
                                                    </h6>
                                                    @if (item.percent) {
                                                    <span>{{item.percent | number:'1.2-2'}}%</span>
                                                    }
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div class="overflow-x-auto sm:py-3">
                                        <ejs-chart #foreCastChart class="chart-box" [attr.id]="'barchart-container-' + randomId + '-' + (key + 1)" [primaryXAxis]='primaryXAxis'
                                            [primaryYAxis]='primaryYAxis' [title]='title'>
                                            <e-series-collection>
                                                <e-series [dataSource]='forecast.formatChartData' type='Waterfall' xName='x' yName='y'
                                                    name='USA' [columnWidth]='columnWidth' [connector]='connector'
                                                    [intermediateSumIndexes]='intermediate' [sumIndexes]='sum' [marker]='marker'
                                                    pointColorMapping='color'> </e-series>
                                            </e-series-collection>
                                        </ejs-chart>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                    }
                    @else {
                        <div class="p-3">
                            No records available to show the waterfall charts for each forecasts.
                        </div>
                    }
                </div>
            </div>
            }
        </div>
        @if (showPlaceholder) {
        <div class="card">
            <!-- Blue Box -->
        </div>
        }

        <!-- GP Price -->
        @if (!isProcessing) {
            <div class="mt-4">
                <div class="scenario-forecast-label inline-block">
                    Government Pricing Price Impact Analysis
                </div>
                <!-- gpPriceComparison -->
                @if (comparisonGPPrices?.length) {
                    @for (item of comparisonGPPrices; let i = $index; track item.name) {
                        <!--Icon-->
                        <div class="forecast-btn-section flex justify-end gap-4 items-center mt-2 mb-2">
                            <i
                              class="fa-solid fa-file-image cursor-pointer"
                              title="Export as JPG"
                              (click)="exportAsImage('JPEG', 'governmentPricingCard' + scenarioId + (i + 1), item.name)"
                            ></i>
                            <i
                              class="fa-regular fa-file-image cursor-pointer"
                              title="Export as PNG"
                              (click)="exportAsImage('PNG', 'governmentPricingCard' + scenarioId + (i + 1), item.name)"
                            ></i>
                        </div>
                        <div class="py-2" id="governmentPricingCard{{scenarioId}}{{i + 1}}">
                            <div class="ml-2">{{item.name}}</div>
                            @if (item.gpImpact) {
                                <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
                                    @for (i of item.gpImpact; let j = $index; track $index) {
                                    <div class="card">
                                        <div class="uppercase font-semibold flex gap-2 items-center">
                                            <p class="text-gray-700 ">
                                                {{i.type}}
                                            </p>
                                            <p class="text-green-700 ">
                                                (${{i.netPrice | number:'1.2-2'}})
                                            </p>
                                        </div>
                                        <h3 class="gp-price-value font-bold truncate"
                                            [ngClass]="{'no-impact': (i.impact == 'No Imapct'), 'gp-impact': (i.impact == 'GP Impacted')}">
                                            ${{i.brandPrice | number:'1.2-2'}}
                                        </h3>
                                        <div class="mt-1">
                                            <span class="text-sm text-gray-500">{{i.impact}}</span>
                                        </div>
                                    </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                } @else {
                    <div class="px-2 text-sm text-gray-500">No data avaialble for GP Price Comparison</div>
                }
            </div>
        }
    </div>
    <!--Scenario Comparison Code End-->

    @if (showPlaceholder) {
    <!--Last Section Code Start-->
    <div class="card custom-card">
        This Section will display the AI Interpretation of the Scenario and will include the reference data is user has
        selected it.
    </div>
    <div class="card">
        <div class="scenario-forecast-label inline-block">
            Scenario Comparison
        </div>
        <div class="card mt-4 space-y-3" [formGroup]="forecastScenarioComparisonForm">
            <div class="grid md:grid-cols-2 lg:grid-cols-4 gap-4 lg:gap-8">
                <div>
                    <label>Scenario ID</label>
                    <ejs-textbox class="m-0" [placeholder]="'&lt;System Generated ID&gt;'" [cssClass]="'e-outline'"
                        [floatLabelType]="'Never'" formControlName="scenarioID"></ejs-textbox>
                </div>
                <div>
                    <label>Scenario Name</label>
                    <ejs-textbox class="m-0" [placeholder]="'Enter Scenario Name'" [cssClass]="'e-outline'"
                        [floatLabelType]="'Never'" formControlName="scenarioName"></ejs-textbox>
                </div>
                <div>
                    <label>Scenario Description</label>
                    <ejs-textbox class="m-0" [placeholder]="'Enter Scenario Description'" [cssClass]="'e-outline'"
                        [floatLabelType]="'Never'" formControlName="scenarioDecription"></ejs-textbox>
                </div>
                <div>
                    <label>Run Date</label>
                    <ejs-textbox class="m-0" [placeholder]="'&lt;Date Scenario was Last Updated&gt;'"
                        [cssClass]="'e-outline'" [floatLabelType]="'Never'" formControlName="runDate"></ejs-textbox>
                </div>
            </div>
            <div class="border p-3 border-gray-300 rounded-md">
                This is the Scenario - GP Comparison Table, that will be defined in Excel and Provided Separately.
            </div>
            <div class="border border-gray-300 rounded-md">
                <ejs-grid class="m-2 md:m-3" [dataSource]='scenarioComparisonTableData'>
                    <e-columns>
                        <e-column field='PBMName' headerText='PBM Name' width=180></e-column>
                        <e-column field='FSS' headerText='FSS' width=150></e-column>
                        <e-column field='340B' headerText='340B' width=120></e-column>
                        <e-column field='Medicaid' headerText='Medicaid' width=250></e-column>
                        <e-column field='OGAPricing' headerText='OGA Pricing' width=250></e-column>
                        <e-column field='Tricare' headerText='Tricare' width=250></e-column>
                    </e-columns>
                </ejs-grid>
            </div>
        </div>
    </div>
    <!--Last Section Code End-->
    }
</div>
}
