<header class="px-4 sm:px-6 py-4 sticky top-0 z-20">
    <div class="grid grid-cols-4 sm:grid-cols-3 items-center">
        <img src="assets/images/logo.png" class="w-auto cursor-pointer" alt="images" (click)="redirectHomeClick()"/>
        <h1 class="text-sm md:text-xl lg:text-2xl font-bold text-center col-span-2 sm:col-auto">
            Deal Analytics
        </h1>
        @if (isUserLogin) {
        <div class="user-setting flex justify-end">
            <div class="md:flex items-center justify-end hidden gap-4 setting-icon">
                <div class="user flex items-center gap-2">
                    <div class="user-icon">
                        <img src="assets/images/avtar.webp" alt="Profile">
                    </div>
                    <div class="user-name">
                        <p>
                            Welcome
                        </p>
                        <h6>{{ userDetail().firstName }} {{ userDetail().lastName }}</h6>
                    </div>
                </div>
                <!-- <i class="fa-solid fa-arrow-left cursor-pointer hidden md:block text-3xl" (click)="onClickBack()" title="Back"></i>
                @if(!isSimulationListScreen) {
                    <i class="fa-solid fa-home cursor-pointer" title="Home" (click)="identifySaveRemainingChanges()"></i>
                } -->
                @if (isVisibleHomeIcon) {
                    <i class="fa-solid fa-home cursor-pointer hidden md:block text-3xl" title="Home" (click)="identifySaveRemainingChanges()"></i>
                } @else {
                    <i class="fa-solid fa-arrow-left cursor-pointer hidden md:block text-3xl" (click)="onClickBack()" title="Back"></i>
                }
                <i class="fa-solid cursor-pointer hidden md:block text-3xl" (click)="toggleTheme()" title="Theme" [ngClass]="isDarkTheme ? 'fa-sun' : 'fa-moon'"></i>
                <i class="fa-solid fa-right-from-bracket cursor-pointer hidden md:block text-3xl" title="Logout" (click)="logout()"></i>
                <div class="w-1/4 hidden md:block text-3xl">
                    <ejs-dropdownlist
                    [cssClass]="'e-outline'"
                    [placeholder]="'Select Theme'"
                    [(value)]="themeValue"
                    [dataSource]="themeList"
                    [fields]="{ text: 'name', value: 'value' }"
                    (change)="toggleTheme1(themeValue)">
                    <ng-template #itemTemplate let-data>
                        <div style="display: flex; align-items: center;">
                        <span [style.background-color]="data.color"
                        class="h-4 w-4 rounded-full inline-block mx-2">
                        </span>
                        {{ data.name }}
                        </div>
                    </ng-template>
                    </ejs-dropdownlist>
                </div>
            </div>
            <div class="relative inline-block md:hidden">
                <input type="checkbox" id="dropdown-toggle" class="hidden" />
                <label for="dropdown-toggle" class="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50 cursor-pointer" aria-haspopup="true" aria-expanded="true">
                    <i class="fa-solid fa-user"></i>
                </label>
                <div class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 hidden group-focus-within:block dropdown-content" role="menu" aria-orientation="vertical" tabindex="-1">
                    <div class="p-3 space-y-4" role="none">
                        <div class="user flex items-center gap-2">
                            <div class="user-icon">
                                <img src="assets/images/avtar.webp" alt="Profile">
                            </div>
                            <div class="user-name">
                                <p>Welcome</p>
                                @if (userDetail().firstName ||  userDetail().lastName) {
                                    <h6>{{ userDetail().firstName }} {{ userDetail().lastName }}</h6>
                                } @else {
                                 <h6>User</h6>
                                }
                            </div>
                        </div>
                        <div class="setting-icon gap-4 space-y-3 text-3xl items-center">
                            <a class="block text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-0">
                                <i class="fa-solid fa-gear mr-2"></i> Account settings
                            </a>
                            <a class="block text-sm text-gray-700" role="menuitem" tabindex="-1" id="menu-item-1">
                                <i class="fa-solid fa-right-from-bracket mr-2" title="Logout" (click)="logout()"></i> Logout
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
</header>
