import { Component, ViewChild } from '@angular/core';
import {
  ColumnChooserService,
  GridComponent,
  GridModule,
  PageService,
  PageSettingsModel,
  SortService,
  ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import { CommonModule } from '@angular/common';
import { ChipListModule } from '@syncfusion/ej2-angular-buttons';
import { SimulationListGrid } from '../../shared/Interface/generic.model';
import { SimulationListGraphqlService } from '../../graphql/simulation-list-graphql.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Router } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import {
  ToastComponent,
  ToastUtility,
} from '@syncfusion/ej2-angular-notifications';

@Component({
  selector: 'app-simulation-list',
  standalone: true,
  imports: [GridModule, CommonModule, ChipListModule],
  providers: [SortService, PageService, ToolbarService, ColumnChooserService],
  templateUrl: './simulation-list.component.html',
  styleUrl: './simulation-list.component.scss',
})
export class SimulationListComponent {
  @ViewChild('simulationListGrid') grid!: GridComponent;
  simulationListData: SimulationListGrid[] = [];
  pageSettings?: PageSettingsModel;
  sortSettings?: any;
  toolbar!: string[];
  totalRecords = 0;
  screenMode: string = '';
  dialogInstance: any;
  simulationId: string = '';

  constructor(
    private simulationListGraphqlService: SimulationListGraphqlService,
    private loader: NgxUiLoaderService,
    private router: Router
  ) {}

  created(): void {
    (
      document.getElementById(
        (this.grid as GridComponent).element.id + '_searchbar'
      ) as Element
    ).addEventListener('keyup', () => {
      (this.grid as GridComponent).search(
        ((event as MouseEvent).target as HTMLInputElement).value
      );
    });
  }

  onRowDoubleClick(args: any): void {
    this.manageSimulation(args.rowData.simulationId);
  }

  getSimulationEntityType() {
    this.loader.start();
    this.simulationListGraphqlService.getSimulationGridListsData().subscribe(
      (data: any) => {
        this.simulationListData = data;
        this.totalRecords = this.simulationListData?.length;
        this.loader.stop();
      },
      () => {
        this.loader.stop();
      }
    );
  }

  manageSimulation(simulationId?: string) {
    if (simulationId) {
      this.router.navigate([`/simulation-scenario-manage/${simulationId}`]);
    } else {
      this.router.navigate([`/simulation-scenario-manage`]);
    }
  }

  onOpenDialog = (simulationId: string) => {
    this.simulationId = simulationId;
    this.dialogInstance = DialogUtility.confirm({
      title: 'Delete Simulation',
      content: 'Are you sure you want to delete this simulation and related scenarios?',
      okButton: { text: 'OK', click: this.okClick.bind(this) },
      cancelButton: { text: 'Cancel', click: this.cancelClick.bind(this) },
      showCloseIcon: true,
      width: '30%',
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    });
  };

  okClick(): void {
    this.simulationListGraphqlService
      .deleteSimulation(this.simulationId)
      .subscribe((response: any) => {
        if (response?.data?.deleteSimulation) {
          ToastUtility.show(
            {
              content: ' Simulation Deleted Successfully!',
              timeOut: 10000,
              cssClass: 'e-toast-success',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Information'
          ) as ToastComponent;
          this.getSimulationEntityType();
        }
      });
    this.dialogInstance.close();
  }

  cancelClick(): void {
    this.dialogInstance.close();
  }

  ngOnInit(): void {
    this.pageSettings = { pageSize: 12 };
    this.sortSettings = {
      columns: [{ field: 'updatedAt', direction: 'Descending' }],
    };
    this.toolbar = ['Search'];
    this.getSimulationEntityType();
  }
}
