import {
  Component,
  ViewChild
} from '@angular/core';
import { FabComponent, FabModule } from '@syncfusion/ej2-angular-buttons';
import {
  AIAssistViewComponent,
  AIAssistViewModule,
  PromptRequestEventArgs,
  ResponseToolbarSettingsModel,
  ToolbarSettingsModel
} from '@syncfusion/ej2-angular-interactive-chat';
import {
  defaultPromptResponseData,
  defaultSuggestions,
} from './promptResponseData';

@Component({
  selector: 'app-open-ai-assistance',
  standalone: true,
  imports: [FabModule, AIAssistViewModule],
  templateUrl: './open-ai-assistance.component.html',
  styleUrl: './open-ai-assistance.component.scss',
})
export class OpenAiAssistanceComponent {

  @ViewChild('Fab')
  public Fab: FabComponent;

  @ViewChild('defaultAIAssistView')
  public defaultAIAssistView: AIAssistViewComponent;

  public prompts: { [key: string]: string | string[] }[] = defaultPromptResponseData;

  public suggestions: string[] = defaultSuggestions;

  public toolbarItemClicked = (args) => {
    if (args.item.iconCss === 'e-icons e-assist-copy') {
      var targetElem = document.querySelector('.right-content .content');
      var response = this.defaultAIAssistView.prompts[args.dataIndex].response;
      if (targetElem) {
        targetElem.innerHTML += response + '<br />';
      }
    }
  };

  public assistViewToolbarSettings: ToolbarSettingsModel = {
    items: [{ iconCss: 'e-icons e-close', align: 'Right' }],
    itemClicked: this.toolbarItemClicked
  };

  public promptRequest = (args: PromptRequestEventArgs) => {
    setTimeout(() => {
      var foundPrompt = this.prompts.find((promptObj) => promptObj['prompt'] === args.prompt);
      var defaultResponse = 'For real-time prompt processing, connect the AI AssistView control to your preferred AI service, such as OpenAI or Azure Cognitive Services. Ensure you obtain the necessary API credentials to authenticate and enable seamless integration.';

      this.defaultAIAssistView.addPromptResponse(foundPrompt ? foundPrompt['response'] : defaultResponse);
      this.defaultAIAssistView.promptSuggestions = foundPrompt['suggestions'] as string[] || this.suggestions;
    }, 2000);
  };
}