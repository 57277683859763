import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  ForecastScenarioOptions,
  MeasureOption,
  SaveScenario,
} from '../shared/Interface/generic.model';

@Injectable({
  providedIn: 'root',
})
export class ScenarioGraphqlService {
  constructor(private apollo: Apollo) {}

  getActualScenarioOptions(entityType: string): Observable<MeasureOption[]> {
    const GET_ENTITY_TYPE = gql`
      query actualScenarioOptions($entityType: String!) {
        actualScenarioOptions(entityType: $entityType) {
          measure
          value
        }
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_ENTITY_TYPE,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { entityType: entityType },
      })
      .valueChanges.pipe(
        map((result: any) => result?.data?.actualScenarioOptions)
      );
  }

  getForeCastScenarioOptions(
    entityType: string
  ): Observable<ForecastScenarioOptions> {
    const GET_ENTITY_TYPE = gql`
      query forecastScenarioOptions($entityType: String!) {
        forecastScenarioOptions(entityType: $entityType) {
          forecastNames
          forecastDemandNames
          forecastMeasures
          forecastPriceTypes
          forecastRevenueMetrics
        }
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_ENTITY_TYPE,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { entityType: entityType },
      })
      .valueChanges.pipe(
        map((result: any) => result?.data?.forecastScenarioOptions)
      );
  }

  saveScenarios(payload: SaveScenario) {
    const SAVE_SCENARIO_MUTATION = gql`
      mutation saveScenario($data: ScenarioInput!) {
        saveScenario(data: $data) {
          scenario {
            scenarioId
            scenarioType
            scenarioName
          }
        }
      }
    `;
    return this.apollo.mutate({
      mutation: SAVE_SCENARIO_MUTATION,
      variables: { data: payload },
    });
  }

  getScenariosDetailsBySimulationId(
    simulationId: string
  ): Observable<SaveScenario[]> {
    const GET_SCENARIOS_Data = gql`
      query scenarios($simulationId: String!) {
        scenarios(simulationId: $simulationId) {
          scenarioId
          scenarioType
          scenarioName
          scenarioDescription
          numberOfRevenueForecasts
          baseDateRangeStart
          baseDateRangeEnd
          simulationGrowthType
          parameters
        }
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_SCENARIOS_Data,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { simulationId: simulationId },
      })
      .valueChanges.pipe(map((result: any) => result?.data?.scenarios));
  }

  processComparisonData(payload: Object) {
    const PROCESS_COMPARISON_MUTATION = gql`
      mutation processComparisonData(
        $scenarioId: Int!
        $simulationId: String!
      ) {
        processComparisonData(
          scenarioId: $scenarioId
          simulationId: $simulationId
        )
      }
    `;
    return this.apollo.mutate({
      mutation: PROCESS_COMPARISON_MUTATION,
      variables: payload,
      errorPolicy: 'all',
    });
  }

  deleteScenario(scenarioId: number): Observable<any> {
    const DELETE_SCENARIO = gql`
      mutation deleteScenario($scenarioId: Int!) {
        deleteScenario(scenarioId: $scenarioId)
      }
    `;
    return this.apollo.mutate({
      mutation: DELETE_SCENARIO,
      variables: { scenarioId: scenarioId },
    });
  }
}
