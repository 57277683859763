import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  createScenarioFlag = new BehaviorSubject(false);

  constructor() { }

  /**
   * function is used get access token
   */
  get accessToken(): string {
    return localStorage.getItem('predealtoken');
  }

  /*
 * function is used for create scenario flag manage
 */
  createScenarioState(state: boolean) {
    this.createScenarioFlag.next(state);
  }
}
