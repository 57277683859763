import { Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { authGuard } from './shared/guard/auth.guard';
import { noAuthGuard } from './shared/guard/no-auth.guard';
import { userAuthGuard } from './shared/guard/user-auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'simulation-list', pathMatch: 'full' },
  {
    path: 'simulation-list',
    canMatch: [authGuard],
    loadComponent: () =>
      import('./deal-analytics/simulation-list/simulation-list.component').then(
        (c) => c.SimulationListComponent
      ),
  },
  {
    path: 'simulation-scenario-manage',
    canMatch: [authGuard],
    loadComponent: () =>
      import('./deal-analytics/deal-analytics.component').then(
        (c) => c.DealAnalyticsComponent
      ),
  },
  {
    path: 'simulation-scenario-manage/:id',
    canMatch: [authGuard],
    loadComponent: () =>
      import('./deal-analytics/deal-analytics.component').then(
        (c) => c.DealAnalyticsComponent
      ),
  },
  {
    path: 'user-management',
    canMatch: [authGuard, userAuthGuard],
    loadComponent: () =>
      import(
        './user-access-management/user-management/user-management.component'
      ).then((c) => c.UserManagementComponent),
    data: {
      isSideBarOpen: true,
    },
  },
  {
    path: 'user-management/new',
    canMatch: [authGuard],
    loadComponent: () =>
      import(
        './user-access-management/user-management/add-edit-user/add-edit-user.component'
      ).then((c) => c.AddEditUserComponent),
    data: {
      isSideBarOpen: true,
    },
  },
  {
    path: 'user-management/edit/:id',
    canMatch: [authGuard],
    loadComponent: () =>
      import(
        './user-access-management/user-management/add-edit-user/add-edit-user.component'
      ).then((c) => c.AddEditUserComponent),
    data: {
      isSideBarOpen: true,
    },
  },
  {
    path: 'role-management',
    canMatch: [authGuard, userAuthGuard],
    loadComponent: () =>
      import(
        './user-access-management/role-management/role-management.component'
      ).then((c) => c.RoleManagementComponent),
    data: {
      isSideBarOpen: true,
    },
  },
  {
    path: 'role-management/new',
    canMatch: [authGuard],
    loadComponent: () =>
      import(
        './user-access-management/role-management/add-edit-role-details/add-edit-role-details.component'
      ).then((c) => c.AddEditRoleComponent),
    data: {
      isSideBarOpen: true,
    },
  },
  {
    path: 'role-management/edit/:id',
    canMatch: [authGuard],
    loadComponent: () =>
      import(
        './user-access-management/role-management/add-edit-role-details/add-edit-role-details.component'
      ).then((c) => c.AddEditRoleComponent),
    data: {
      isSideBarOpen: true,
    },
  },
  {
    path: 'two-factor-authentication',
    canMatch: [noAuthGuard],
    loadComponent: () =>
      import(
        './user-access-management/authentication/two-fa/two-fa.component'
      ).then((c) => c.TwoFaComponent)
  },
  {
    path: 'backup-verification-code',
    canMatch: [authGuard],
    loadComponent: () =>
      import(
        './user-access-management/authentication/backup-verification-code/backup-verification-code.component'
      ).then((c) => c.BackupVerificationCodeComponent)
  },
  {
    path: 'login',
    canMatch: [noAuthGuard],
    loadComponent: () =>
      import('./user-access-management/authentication/sign-in/sign-in.component').then(
        (c) => c.SignInComponent
      ),
  },
  {
    path: 'forgot-password',
    canMatch: [noAuthGuard],
    loadComponent: () =>
      import('./user-access-management/authentication/forgot-password/forgot-password.component').then(
        (c) => c.ForgotPasswordComponent
      ),
  },
  {
    path: 'reset-password/:id',
    canMatch: [noAuthGuard],
    loadComponent: () =>
      import('./user-access-management/authentication/reset-password/reset-password.component').then(
        (c) => c.ResetPasswordComponent
      ),
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent,
  },
];
