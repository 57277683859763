import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApplicationConfig, inject } from '@angular/core';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { environment } from '../../environments/environment';

export function apolloOptionsFactory(): ApolloClientOptions<any> {
  const httpLink = inject(HttpLink);
  const auth = setContext(() => {
    const token = localStorage.getItem('predealtoken');
    const preDealToken = token?.replace(/^b\'/gm, "").replace(/\'/, "")
    if (!preDealToken) {
      return {
        headers: {
          'X-App': 'pre-deal',
        },
      };
    } else {
      return {
        headers: {
          Authorization: `Bearer ${preDealToken}`,
          'X-App': 'pre-deal',
        },
      };
    }
  });

  const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) {
      if (graphQLErrors?.length && graphQLErrors[0]?.message?.toLowerCase() == 'Signature has expired'.toLowerCase()) {
        localStorage.clear();
        location.reload();
      }
    }
  });

  return {
    link: ApolloLink.from([
      auth,
      errorLink,
      httpLink.create({ uri: environment.graphqlUrl }),
    ]),
    cache: new InMemoryCache(),
  };
}

export const graphqlProvider: ApplicationConfig['providers'] = [
  Apollo,
  {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
  },
];
