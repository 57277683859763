<div class="table-section px-5 py-4 relative">
  <div class="absolute right-5 top-[35px] z-10">
    <button (click)="manageSimulation()"
      class="btn-primary justify-content-end sm:w-auto w-full"
    >
      Create New Simulation
    </button>
  </div>
    <ejs-grid
      #simulationListGrid
      id="simulationListGrid"
      [dataSource]="simulationListData"
      rowHeight="35"
      [allowSelection]="true"
      [allowSorting]="true"
      [sortSettings]="sortSettings"
      [allowPaging]="true"
      [pageSettings]="pageSettings"
      [toolbar]="toolbar"
      showColumnChooser="true"
      (created)="created()"
      (recordDoubleClick)="onRowDoubleClick($event)"
    >
      <e-columns>
        <e-column headerText="Actions" width="80">
          <ng-template #template let-simulationListData>
            <div class="flex gap-4 items-center justify-center action-btn">
              <button
                ejs-button
                class="edit-btn"
                title="Edit"
                (click)="
                manageSimulation(simulationListData.simulationId);
                "
              >
                <i class="fa fa-edit"></i>
              </button>
              <button
                ejs-button
                class="text-red-600"
                title="Delete"
                (click)="$event.stopPropagation(); this.onOpenDialog(simulationListData.simulationId)"
                
              >
                <i class="fa-regular fa-trash-can"></i>
              </button>
            </div>
          </ng-template>
        </e-column>
        <e-column
          field="simulationId"
          headerText="Id"
          width="100"
        ></e-column>
        <e-column
          field="simulationName"
          headerText="Simulation Name"
          width="250"
        ></e-column>
        <e-column
          field="simulationDescription"
          headerText="Description"
          width="250"
        ></e-column>
        <e-column field="createdBy" headerText="Created By" width="170">
          <ng-template #template let-simulationListData>
            <div>
              Admin
            </div>
          </ng-template>
        </e-column>
        <e-column
          field="updatedAt"
          headerText="Created/Last Modified"
          width="200"
        >
          <ng-template #template let-simulationListData>
            <div>
              <span id="Emptext">{{ simulationListData.updatedAt | date: 'yyyy-MM-dd h:mm a' }}</span>
            </div>
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>