<div class="login_section">
  <div class="login-box mx-auto my-5">
    <form [formGroup]="resetPasswordForm" class="py-7 px-14">
      <div class="flex justify-center">
        <img src="assets/images/logo.png" class="w-auto" alt="images" />
      </div>
      <h2 class="flex justify-center my-5">Reset Your Password</h2>
      <div class="login_textbox">
        <div class="relative password-container mt-4">
          <input
            class="e-input input-emp-id e-outline"
            formControlName="newPassword"
            cssClass="password-textbox"
            floatLabelType="Auto"
            [type]="showResetPassword ? 'text' : 'password'"
            placeholder="New Password *"
            type="text"
          />
          <div class="icon">
            @if(!showResetPassword){
            <i class="fa-regular fa-eye" (click)="toggleResetPassword()"></i>
            } @if(showResetPassword){
            <i class="fa-solid fa-eye-slash" (click)="toggleResetPassword()"></i>
            }
          </div>
        </div>
        @if(resetPasswordForm.get('newPassword')?.invalid &&
        resetPasswordForm.get('newPassword')?.touched &&
        !resetPasswordForm.get('newPassword')?.hasError('minlength')){
        <div class="error-message">Password is required</div>
        } @if(resetPasswordForm.get('newPassword')?.hasError('minlength') &&
        resetPasswordForm.get('newPassword')?.touched){
        <div class="error-message">Password minimum 8 characters long.</div>
        }
        <div class="relative password-container mt-4">
          <input
            class="e-input input-emp-id e-outline"
            formControlName="confirmPassword"
            cssClass="password-textbox"
            floatLabelType="Auto"
            [type]="showConfirmPassword ? 'text' : 'password'"
            placeholder="Confirm Password *"
            type="text"
          />
          <div class="icon">
            @if(!showConfirmPassword){
            <i class="fa-regular fa-eye" (click)="toggleConfirmPassword()"></i>
            } @if(showConfirmPassword){
            <i class="fa-solid fa-eye-slash" (click)="toggleConfirmPassword()"></i>
            }
          </div>
        </div>
        @if(resetPasswordForm.get('confirmPassword')?.invalid &&
        resetPasswordForm.get('confirmPassword')?.touched && 
        !resetPasswordForm.hasError('passwordMismatch')){
        <div class="error-message">Confirm Password is required</div>
        } @if(resetPasswordForm.get('confirmPassword')?.touched &&
        resetPasswordForm.hasError('passwordMismatch')){
        <div class="error-message">Passwords must match.</div>
        }
      </div>
      <button
        class="w-full mt-5"
        ejs-button
        type="submit"
        (click)="onSaveresetPasswordForm()"
        [disabled]="resetPasswordForm.invalid"
      >
        Reset Password
      </button>
    </form>
  </div>
</div>
