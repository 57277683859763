import { Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { DealAnalyticsComponent } from './deal-analytics/deal-analytics.component';
import { SimulationListComponent } from './deal-analytics/simulation-list/simulation-list.component';
import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { authGuard } from './shared/guard/auth.guard';
import { LayoutComponent } from './shared/layout/layout.component';
import { noAuthGuard } from './shared/guard/no-auth.guard';
import { ResetPasswordComponent } from './authentication/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './authentication/forgot-password/forgot-password.component';
import { OpenAiAssistanceComponent } from './deal-analytics/open-ai-assistance/open-ai-assistance.component';

export const routes: Routes = [
    { path: '', redirectTo: 'simulation-list', pathMatch: 'full' },
    {
        path: 'simulation-list',
        canMatch: [authGuard],
        component: LayoutComponent,
        children: [
            { path: '' , component: SimulationListComponent }
        ]
    },
    {
        path: 'open-ai-assistance',
        canMatch: [authGuard],
        component: LayoutComponent,
        children: [
            { path: '' , component: OpenAiAssistanceComponent }
        ]
    },
    {
        path: 'simulation-scenario-manage',
        canMatch: [authGuard],
        component: LayoutComponent,
        children: [
            { path: '' , component: DealAnalyticsComponent }
        ]
    },
    { 
        path: 'simulation-scenario-manage/:id',
        canMatch: [authGuard],
        component: LayoutComponent,
        children: [
            { path: '' , component: DealAnalyticsComponent }
        ]
    },
    { 
        path: "login",
        canMatch: [noAuthGuard],
        component: LayoutComponent,
        children: [
            { path: '' , component: SignInComponent }
        ] 
    },
    { 
        path: "forgot-password",
        canMatch: [noAuthGuard],
        component: LayoutComponent,
        children: [
            { path: '' , component: ForgotPasswordComponent }
        ] 
    },
    { 
        path: "reset-password/:id",
        canMatch: [noAuthGuard],
        component: LayoutComponent,
        children: [
            { path: '' , component: ResetPasswordComponent }
        ] 
    },
    { 
        path: 'login/callback', 
        component: LayoutComponent,
        children: [
            { path: '' , component: OktaCallbackComponent }
        ] 
    }
];
