import { OktaAuthConfigService } from '@okta/okta-angular';
import { HttpBackend } from '@angular/common/http';
import { OktaAuth } from '@okta/okta-auth-js';

export function configOktaInitializer(httpBackend: HttpBackend, configService: OktaAuthConfigService): () => void {
  return () =>
    configService.setConfig({
        oktaAuth: new OktaAuth({
          "issuer": "https://dev-29987006.okta.com/oauth2/default",
          "clientId": "0oajurmaq0fPup5nn5d7",
          redirectUri: `http://localhost:4200/login/callback`,
          scopes: ['openid', 'offline_access', 'profile']
        })
      })
  }