import { CommonModule } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { SidebarModule } from '@syncfusion/ej2-angular-navigations'
import { NavigationEnd, Router } from '@angular/router';
import { SideBarObject } from '../enum/generic.enum';
import { SidebarItem } from '../Interface/generic.model';


@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [SidebarModule, CommonModule, ButtonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class UserSidebarComponent implements OnInit {
  @Input() isSideBarOpen!: boolean;
  public innerWidth: any;
  @ViewChild('sidebar') sidebar?: SidebarComponent;
  @Output() sidebarToggle = new EventEmitter<void>(); 
  sideBarData: SidebarItem[] = SideBarObject;
  pathUrl:string = '/user-management';
  isMobileView: boolean = false;
  public showBackdrop: boolean = true;
  public type: string = 'Push';
  public width: string ='280px';
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.isMobileView = window.innerWidth <= 768;       
  }
  constructor(private router:Router){ }

  setActiveButton(router: string): void {
    this.pathUrl = router;
    this.router.navigateByUrl(this.pathUrl);
  }

  public onCreated(args: any) {
    (this.sidebar as SidebarComponent).element.style.visibility = '';
  }

  closeSideBar(): void {
    this.sidebar?.hide();
  };

  openSideBar():void{
    this.sidebar?.show();
    this.sidebarToggle.emit();
  }
  
  ngOnInit(): void {
    this.pathUrl = this.router.url;
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.pathUrl = this.router.url;
      }
    });
  }
}

