import { CommonModule } from '@angular/common';
import { Component, inject, OnInit, WritableSignal } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { DialogUtility } from '@syncfusion/ej2-angular-popups';
import { filter, Subscription } from 'rxjs';
import {
  ToastComponent,
  ToastUtility,
} from '@syncfusion/ej2-angular-notifications';
import { GenericService } from '../../../graphql/generic.service';
import { CommonService } from '../../services/common.service';
import { GetUserDetails } from 'app/shared/Interface/generic.model';
import { environment } from '../../../../environments/environment';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';

const LANDING_URL = environment && environment.landingUrl ? environment.landingUrl : 'https://ri.myfundae.com';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, RouterModule, DropDownListModule],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  genericService = inject(GenericService);
  dialogInstance: any;
  isVisibleHomeIcon: boolean = false;
  isSimulationListScreen: boolean = false;
  isSimulationFormModify: boolean = false;
  isScenarioFormModify: boolean = false;
  subscribe?: Subscription;
  isUserLogin: boolean = false;
  userDetail: WritableSignal<GetUserDetails> =
    this.genericService.userDetail;
  themeValue: string = 'Defualt';
  themeList = [
    { name: 'Default', value: 'Defualt', color: '' },
    { name: 'Theme 1', value: 'theme1', color: '#88BD2F' },
    { name: 'Theme 2', value: 'theme2', color: '#BEE3A4' },
    { name: 'Theme 3', value: 'theme3', color: '#FFFACD' },
    { name: 'Theme 4', value: 'theme4', color: '#30D5C8' },
    { name: 'Theme 5', value: 'theme5', color: '#C2B280' },
  ];


  constructor(
    private router: Router,
    private commonService: CommonService
  ) {
    this.subscribe = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        this.isSimulationListScreen =
          event.url === '/simulation-list' ? true : false;
        this.isUserLogin = this.commonService.accessToken ? true : false;
        this.isVisibleHomeIcon = !['/simulation-list', '/'].includes(event.url);
      });
  }

  get isDarkTheme(): boolean {
    return this.genericService.theme === 'dark';
  }

  set isDarkTheme(enabled: boolean) {
    this.genericService.theme = enabled ? 'dark' : 'light';
    document.body.className = enabled ? 'dark-theme' : 'light-theme';
  }
  
  get isTheme1(): boolean {
    return this.genericService.theme === 'theme1';
  }

  set isTheme1(enabled: boolean) {
    this.genericService.theme = enabled ? 'theme1' : 'light';
    document.body.className = enabled ? 'theme1' : 'light-theme';
  }

  get isTheme2(): boolean {
    return this.genericService.theme === 'theme2';
  }

  set isTheme2(enabled: boolean) {
    this.genericService.theme = enabled ? 'theme2' : 'light';
    document.body.className = enabled ? 'theme2' : 'light-theme';
  }

  get isTheme3(): boolean {
    return this.genericService.theme === 'theme3';
  }

  set isTheme3(enabled: boolean) {
    this.genericService.theme = enabled ? 'theme3' : 'light';
    document.body.className = enabled ? 'theme3' : 'light-theme';
  }

  get isTheme4(): boolean {
    return this.genericService.theme === 'theme4';
  }

  set isTheme4(enabled: boolean) {
    this.genericService.theme = enabled ? 'theme4' : 'light';
    document.body.className = enabled ? 'theme4' : 'light-theme';
  }

  get isTheme5(): boolean {
    return this.genericService.theme === 'theme5';
  }

  set isTheme5(enabled: boolean) {
    this.genericService.theme = enabled ? 'theme5' : 'light';
    document.body.className = enabled ? 'theme5' : 'light-theme';
  }

  toggleTheme1(value): void {
    value === 'theme1'
      ? (this.isTheme1 = true)
      : value === 'theme2'
      ? (this.isTheme2 = true)
      : value === 'theme3'
      ? (this.isTheme3 = true)
      : value === 'theme4'
      ? (this.isTheme4 = true)
      : value === 'theme5'
      ? (this.isTheme5 = true)
      : (this.isDarkTheme = false);
  }


  toggleTheme(): void {
    this.isDarkTheme = !this.isDarkTheme;
  }

  identifySaveRemainingChanges() {
    this.genericService.formDirtyFlagIndetify(true);
    setTimeout(() => {
      if (this.isSimulationFormModify || this.isScenarioFormModify) {
        this.onOpenDialog();
      } else {
        this.router.navigate([`/simulation-list`]);
      }
    }, 500);
  }

  onClickBack() {
    window.location.href = LANDING_URL;
  }

  onOpenDialog = () => {
    this.dialogInstance = DialogUtility.confirm({
      title: 'Alert',
      content: `Please Save Your Data Before Navigate to Home Page. Once You Clicked Ok Button You Should Redirect to Home Page!`,
      okButton: { text: 'Ok', click: this.redirectHomeClick.bind(this) },
      cancelButton: { text: 'Cancel', click: this.cancelClick.bind(this) },
      showCloseIcon: true,
      width: '30%',
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    });
  };

  redirectHomeClick(): void {
    this.router.navigate([`/simulation-list`]);
    this.dialogInstance?.close();
  }

  cancelClick(): void {
    this.dialogInstance?.close();
  }

  logout() {
    ToastUtility.show(
      {
        content: 'Logout Successfully!',
        timeOut: 2000,
        cssClass: 'e-toast-success',
        position: { X: 'Right', Y: 'Top' },
        showCloseButton: true,
      },
      'Information'
    ) as ToastComponent;
    localStorage.removeItem('predealtoken');
    this.router.navigateByUrl('/login');
  }

  ngOnInit(): void {
    this.subscribe = this.genericService.senarioFormDirtyFlag$.subscribe(
      (senarioFormDirtyFlag) => {
        this.isScenarioFormModify = senarioFormDirtyFlag;
      }
    );
    this.subscribe = this.genericService.simulationFormDirtyFlag$.subscribe(
      (simulationFormDirtyFlag) => {
        this.isSimulationFormModify = simulationFormDirtyFlag;
      }
    );
  }

  ngOnDestroy(): void {
    this.subscribe?.unsubscribe();
  }
}
