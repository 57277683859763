<div class="space-y-4">
  <!--Section 1 Code Start-->
  <div class="card">
    <div
      class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4"
      [formGroup]="simulationForm"
    >
      <div class="card space-y-2">
        <div class="search_input">
          <ejs-textbox
            class="m-0"
            [placeholder]="'Salesforce Opportunity ID...'"
            [cssClass]="'e-outline'"
            [floatLabelType]="'Never'"
          ></ejs-textbox>
          <span class="search_input-icon">
            <i class="fa-solid fa-magnifying-glass"></i>
          </span>
        </div>
        <div>
          <label>Simulation ID</label>
          <ejs-textbox
            class="m-0"
            [placeholder]="'&lt;System Generated ID&gt;'"
            [cssClass]="'e-outline'"
            [floatLabelType]="'Never'"
            formControlName="simulationId"
          ></ejs-textbox>
        </div>
        <div>
          <label>Simulation Name <span class="text-red-600">*</span></label>
          <ejs-textbox
            class="m-0"
            [placeholder]="'Enter Simulation Name'"
            [cssClass]="'e-outline'"
            [floatLabelType]="'Never'"
            formControlName="simulationName"
          ></ejs-textbox>
        </div>
        <div>
          <label>Simulation Description</label>
          <ejs-textbox
            class="m-0"
            [placeholder]="'Enter Simulation Description'"
            [cssClass]="'e-outline'"
            [floatLabelType]="'Never'"
            formControlName="simulationDescription"
          ></ejs-textbox>
        </div>
      </div>
      <div class="card space-y-2">
        <div>
          <label>Select Entity Type <span class="text-red-600">*</span></label>
          <div class="radio_block">
            @for (entity of entityType; track entity) {
            <label class="radio-btn" for="{{ entity }}">
              <input
                type="radio"
                id="{{ entity }}"
                formControlName="entityType"
                [value]="entity"
                (ngModelChange)="entityTypeSelectionChange($event)"
              />
              {{ entity }}
            </label>
            <!-- <ejs-radiobutton
              [label]="entity"
              formControlName="entityType"
              [value]="entity"
              (ngModelChange)="entityTypeSelectionChange($event)"
            >
            </ejs-radiobutton> -->
            }
          </div>
        </div>
        <div>
          <label>Entity <span class="text-red-600">*</span></label>
          <ejs-dropdownlist
            [cssClass]="'e-outline'"
            [allowFiltering]="true"
            [placeholder]="'Select Entity'"
            id="entity"
            [dataSource]="entityListData"
            formControlName="entityId"
            [fields]="{ text: 'name', value: 'entityId' }"
            (ngModelChange)="getAPICalledManage()"
            (filtering)="onEntityFiltering($event)"
          ></ejs-dropdownlist>
        </div>
        <div>
          <label>Product (NDC11)</label>
          <ejs-multiselect
            [cssClass]="'e-outline'"
            [allowFiltering]="true"
            [placeholder]="'Select Product'"
            id="product"
            mode="CheckBox"
            [dataSource]="productListData"
            formControlName="brandName"
            [fields]="{ text: 'name', value: 'name' }"
            (ngModelChange)="getAPICalledManage()"
            (filtering)="onProductFiltering($event)"
          ></ejs-multiselect>
        </div>
        <div>
          <label>Channel</label>
          <ejs-dropdownlist
            [cssClass]="'e-outline'"
            [allowFiltering]="true"
            [placeholder]="'Select Channel'"
            id="channel"
            [dataSource]="channelListData"
            formControlName="channelId"
            [fields]="{ text: 'name', value: 'channelId' }"
            (ngModelChange)="setChannelGroupName($event)"
            (filtering)="onChanelFiltering($event)"
          ></ejs-dropdownlist>
        </div>
      </div>
      <div>
        <div class="card">
          <div>
            <label>Reference Data</label>
            <div class="chips_block">
              <ejs-chiplist
                id="chip-reference-data"
                [selectedChips]="referenceDataShipSelected"
                selection="Multiple"
                (click)="referenceDataChipClicked($event)"
              >
                <e-chips>
                  @for (refData of referenceData; let i = $index; track
                  refData.name) {
                  <e-chip
                    [cssClass]="
                    simulationForm.controls['entityType'].value !==
                        'PBM' && !i
                        ? 'chip-disabled'
                        : referenceDataShipSelected?.includes(refData?.name)
                        ? 'e-active'
                        : ''
                    "
                    [text]="refData.name"
                  ></e-chip>
                  }
                </e-chips>
              </ejs-chiplist>
            </div>
          </div>
          <div class="switch_btn my-5">
            GP Impact
            <ejs-switch
              onLabel="ON"
              offLabel="OFF"
              formControlName="gpImpact"
            ></ejs-switch>
          </div>
          @if(simulationForm.controls['gpImpact'].value){
          <div>
            <label class="pb-3">GP Prices</label>
            <div class="checkbox_block">
              @for (gpPrice of gpPricesData; track gpPrice) {
              <ejs-checkbox
                [label]="gpPrice.id"
                [id]="gpPrice.name"
                [checked]="gpPrice.checked"
                (change)="toggleGpPrice(gpPrice.name, $event.checked)"
              ></ejs-checkbox>
              }
            </div>
          </div>
          }
        </div>
        <div
          class="sm:flex space-y-2 sm:space-y-0 gap-4 items-center justify-end pt-5 sm:pt-4"
        >
          <button
            class="btn-primary text-center sm:w-auto w-full"
            (click)="onSaveSimulation()"
            [disabled]="simulationForm.invalid"
          >
            {{
              simulationForm.get("simulationId").value ? "Update" : "Save"
            }}
          </button>
          <button class="btn-secondary text-center sm:w-auto w-full">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
  <!--Section 1 Code end-->

  <!-- Refrence Data Section Table Code Start -->
  @if(tableReferenceDatePreview[0]){
  <div class="card">
    <div class="refrence--data-section">
      <div class="flex justify-between">
        <div class="refrence-label">Formulary - Status & Lives</div>
        <div class="forecast-btn-section flex gap-2 sm:gap-4 items-center">
          <i
            class="fa-solid fa-file-excel cursor-pointer"
            title="Export as Excel"
            (click)="exportFormularyTable('excel')"
          ></i>
          <i
            class="fa-solid fa-file-csv cursor-pointer"
            title="Export as CSV"
            (click)="exportFormularyTable('csv')"
          ></i>
        </div>
      </div>
      <div class="refrence-data">
        <div class="data-chart-table-section">
          <!-- Table Data -->
          <ejs-grid
            class="m-2 md:m-3"
            #formularyGrid
            id="FormularyGrid"
            [dataSource]="formularyTableData"
            [allowResizing]="true"
            [allowExcelExport]="true"
            [height]="formularyTableData.length > 4 ? '315px' : 'auto'"
          >
            <e-columns>
              <e-column
                field="pbmNm"
                headerText="PBM Name"
                width="180"
              ></e-column>
              <e-column
                field="channel"
                headerText="Channel"
                width="150"
              ></e-column>
              <e-column
                field="brandId"
                headerText="Brand Id"
                width="120"
              ></e-column>
              <e-column
                field="formularyPeriodStartDate"
                headerText="Formulary Start Date"
                width="250"
              ></e-column>
              <e-column
                field="formularyPeriodEndDate"
                headerText="Formulary End Date"
                width="250"
              ></e-column>
              <e-column
                field="pbmFormularyStatus"
                headerText="PBM Formulary Status"
                width="250"
              ></e-column>
              <e-column
                field="pbmLives"
                headerText="PBM Lives"
                width="140"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
  }
  <!-- Refrence Data Section Table Code End -->

  <!-- Refrence Data Section Table Code Start -->
  @if(tableReferenceDatePreview[1]){
  <div class="card">
    <div class="refrence--data-section">
      <div class="flex justify-between">
        <div class="refrence-label">Existing Contracts</div>
        <div class="forecast-btn-section flex gap-2 sm:gap-4 items-center">
          <i
            class="fa-solid fa-file-excel cursor-pointer"
            title="Export as Excel"
            (click)="exportContractsTable('excel')"
          ></i>
          <i
            class="fa-solid fa-file-csv cursor-pointer"
            title="Export as CSV"
            (click)="exportContractsTable('csv')"
          ></i>
        </div>
      </div>
      <div class="refrence-data">
        <div class="data-chart-table-section">
          <!-- Table Data -->
          <ejs-grid
            class="m-2 md:m-3"
            #contractGrid
            id="ContractGrid"
            [allowExcelExport]="true"
            [dataSource]="contractTableData"
            [allowResizing]="true"
            [height]="contractTableData.length > 4 ? '315px' : 'auto'"
          >
            <e-columns>
              <e-column
                field="contractName"
                headerText="Contract Name"
                width="160"
              >
                <ng-template #template let-data>
                  <span>{{ data.contractName }}</span>
                </ng-template>
              </e-column>
              <e-column
                field="contractType"
                headerText="Contract Type"
                width="160"
              ></e-column>
              <e-column
                field="contractId"
                headerText="Contract Id"
                width="140"
              ></e-column>
              <e-column
                field="contractStartDate"
                headerText="Contract Start Date"
                width="200"
              ></e-column>
              <e-column
                field="contractExpiryDate"
                headerText="Contract Expiry Date"
                width="200"
              ></e-column>
              <e-column
                field="entityName"
                headerText="Entity Name"
                width="150"
              ></e-column>
              <e-column
                field="channelGroup"
                headerText="Channel Group"
                width="160"
              ></e-column>
              <e-column
                field="formularyPosition"
                headerText="Formulary Position"
                width="200"
              ></e-column>
              <e-column
                field="brandName"
                headerText="Brand Name"
                width="150"
              ></e-column>
              <e-column
                field="baseRebates"
                headerText="Base Rebate"
                width="140"
              ></e-column>
              <e-column
                field="fixedPriceRebate"
                headerText="Fixed Pricce Rebate"
                width="200"
              ></e-column>
              <e-column
                field="adminFeeRebate"
                headerText="Admin Fee Rebate"
                width="180"
              ></e-column>
              <e-column
                field="priceProtectionRebate"
                headerText="Price Protection Rebate"
                width="220"
              ></e-column>
              <e-column
                field="totalRebateRate"
                headerText="Totle Rebate Rate"
                width="140"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
  }

  <!-- Refrence Data Section Chart Code Start -->
  @if(tableReferenceDatePreview[2]) { @if(marketShareChartData?.length){
  <div class="card">
    <div class="refrence--data-section">
      <div class="flex justify-between">
        <div class="refrence-label">Market Share</div>
        <div class="forecast-btn-section flex gap-2 sm:gap-4 items-center">
          <i
            class="fa-solid fa-file-image cursor-pointer"
            title="Export as JPG"
            (click)="exportChart('JPEG')"
          ></i>
          <i
            class="fa-regular fa-file-image cursor-pointer"
            title="Export as PNG"
            (click)="exportChart('PNG')"
          ></i>
        </div>
      </div>
      <div class="refrence-data">
        <div class="data-chart-table-section">
          <ejs-chart
            id="linechart-container"
            #chart
            height="60%"
            width="100%"
            [primaryXAxis]="primaryXAxis"
            [primaryYAxis]="primaryYAxis"
            [title]="title"
            [tooltip]="tooltip"
          >
          <!-- (tooltipRender)="onTooltipRender($event)" -->
            <e-series-collection>
              <e-series
                [dataSource]="marketShareChartData"
                type="Line"
                fill="#7851A9"
                width="2"
                xName="x"
                yName="y"
                [marker]="marker"
              ></e-series>
            </e-series-collection>
          </ejs-chart>
        </div>
      </div>
    </div>
  </div>
  } @if(!marketShareChartData?.length) {
  <div class="card">
    <div class="refrence--data-section">
      <div class="flex justify-between">
        <div class="refrence-label">Market Share</div>
      </div>
      <div class="refrence-data">
        <div class="data-chart-table-section">
          <ejs-grid
            class="m-2 md:m-3"
            #marketShareGrid
            id="marketShareGrid"
            [dataSource]="marketShareChartData"
            [allowResizing]="true"
            [height]="'auto'"
          >
            <e-columns>
              <e-column field="quarter" headerText="Quarter" width="160">
              </e-column>
              <e-column
                field="marketShare"
                headerText="Market Share"
                width="160"
              ></e-column>
              <e-column
                field="totalApprovedClaims"
                headerText="Total Approved Claims"
                width="200"
              ></e-column>
              <e-column
                field="approvedClaimsByBrand"
                headerText="Approved Claims By Brand"
                width="200"
              ></e-column>
            </e-columns>
          </ejs-grid>
        </div>
      </div>
    </div>
  </div>
  } }
  <!-- Refrence Data Section Chart Code End -->

  <!--Reference Data Section Textarea Code Start-->
  <div class="card">
    <div class="refrence--data-section">
      @if (!tableReferenceDatePreview.includes(true)) {
      <div class="flex justify-between">
        <div class="refrence-label">Reference Data</div>
        <!-- <div class="forecast-btn-section flex gap-2 sm:gap-4 items-center">
              <i class="fa-solid fa-file-excel cursor-pointer" title="download"></i>
              <i class="fa-solid fa-file-csv cursor-pointer" title="download"></i>
              <i class="fa-solid fa-sliders cursor-pointer"></i>
            </div> -->
      </div>
      <div class="refrence-data">
        <textarea
          rows="5s"
          placeholder="Will only be displayed if reference data is checked, will be displayed as a downloadable and filterable grid in excel and csv"
        ></textarea>
      </div>
      }
      <div class="flex justify-end">
        <button
          [disabled]="!simulationForm.controls['simulationId']?.value || scenarioLength >= 2"
          class="btn-primary mt-3 justify-content-end sm:w-auto w-full"
          (dblclick)="$event.preventDefault()"
          (click)="toggleSections()"
        >
          Create Scenario
        </button>
      </div>
    </div>
  </div>
  <!--Reference Data Section Textarea Code End-->
</div>
