import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import {
  ContractsReferenceData,
  FormularyReferenceData,
  GetSimulationDetails,
  MarketshareReferenceData,
  ReferenceDataParameters,
  SaveSimulation,
  SimulationOptionsChannels,
  SimulationOptionsEntities,
  SimulationOptionsProducts,
} from '../shared/Interface/generic.model';

@Injectable({
  providedIn: 'root',
})
export class SimulationGraphqlService {
  constructor(private apollo: Apollo) {}

  getSimulationEntityType(): Observable<string[]> {
    const GET_ENTITY_TYPE = gql`
      query {
        entityTypes
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_ENTITY_TYPE,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((result: any) => result?.data?.entityTypes));
  }

  getGPPrices(): Observable<string[]> {
    const GET_GPPRICE_Data = gql`
      query {
        gpPrices
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_GPPRICE_Data,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((result: any) => result?.data?.gpPrices));
  }

  getSimulationDetailsById(
    simulationId: string
  ): Observable<GetSimulationDetails> {
    const GET_SIMULATION_Data = gql`
      query simulationDetail($simulationId: String!) {
        simulationDetail(simulationId: $simulationId) {
          simulationId
          simulationName
          simulationDescription
          parameters
        }
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_SIMULATION_Data,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { simulationId: simulationId },
      })
      .valueChanges.pipe(map((result: any) => result?.data?.simulationDetail));
  }

  getEntityTypeSelectionChange(
    entityType: string
  ): Observable<SimulationOptionsEntities[]> {
    const GET_ENTITY_TYPE = gql`
      query getEntityList($entityType: String) {
        getEntityList(entityType: $entityType) {
          entityId
          name
          entityType
        }
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_ENTITY_TYPE,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { entityType: entityType },
      })
      .valueChanges.pipe(map((result: any) => result?.data?.getEntityList));
  }

  getProductTypeSelectionChange(
    entityType: string
  ): Observable<SimulationOptionsProducts[]> {
    const GET_PRODUCT_TYPE = gql`
      query getProductList($entityType: String) {
        getProductList(entityType: $entityType) {
          brandId
          name
          ndc
        }
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_PRODUCT_TYPE,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { entityType: entityType },
      })
      .valueChanges.pipe(map((result: any) => result?.data?.getProductList));
  }

  getChannelTypeSelectionChange(
    entityType: string
  ): Observable<SimulationOptionsChannels[]> {
    const GET_Channel_TYPE = gql`
     query getChannelList($entityType: String) {
        getChannelList(entityType: $entityType) {
          channelId name channelGroup
        }
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_Channel_TYPE,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: { entityType: entityType },
      })
      .valueChanges.pipe(map((result: any) => result?.data?.getChannelList));
  }

  getContractsList(
    payload?: ReferenceDataParameters
  ): Observable<ContractsReferenceData[]> {
    const GET_CONTRACTS_LIST = gql`
      query getContractsReferenceData(
        $entityId: String
        $brandName: [String]
        $channelGroup: String
      ) {
        getContractsReferenceData(
          entityId: $entityId
          brandName: $brandName
          channelGroup: $channelGroup
        ) {
          contractName
          contractType
          contractId
          contractStartDate
          contractExpiryDate
          entityName
          channelGroup
          formularyPosition
          brandName
          baseRebates
          fixedPriceRebate
          adminFeeRebate
          priceProtectionRebate
          totalRebateRate
        }
      }
    `;
    if (!payload?.brandName?.length) {
      delete payload.brandName;
    }
    return this.apollo
      .watchQuery({
        query: GET_CONTRACTS_LIST,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: payload,
      })
      .valueChanges.pipe(
        map((result: any) => result?.data?.getContractsReferenceData)
      );
  }

  getFormularyList(
    payload?: ReferenceDataParameters
  ): Observable<FormularyReferenceData[]> {
    const GET_FORMULARY_LIST = gql`
      query getFormularyReferenceData(
        $entityId: String
        $brandName: [String]
        $channelGroup: String
      ) {
        getFormularyReferenceData(
          entityId: $entityId
          brandName: $brandName
          channelGroup: $channelGroup
        ) {
          pbmNm
          channel
          brandId
          formularyPeriodStartDate
          formularyPeriodEndDate
          pbmFormularyStatus
          pbmLives
        }
      }
    `;
    if (!payload?.brandName?.length) {
      delete payload.brandName;
    }
    return this.apollo
      .watchQuery({
        query: GET_FORMULARY_LIST,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: payload,
      })
      .valueChanges.pipe(
        map((result: any) => result?.data?.getFormularyReferenceData)
      );
  }

  getMarketshareReference(
    payload?: ReferenceDataParameters
  ): Observable<MarketshareReferenceData[]> {
    const GET_Marketshare_Reference_Data = gql`
      query getMarketshareReferenceData(
        $entityType: String!
        $entityId: String
        $brandName: [String]
        $channelGroup: String
      ) {
        getMarketshareReferenceData(
          entityType: $entityType
          entityId: $entityId
          brandName: $brandName
          channelGroup: $channelGroup
        ) {
          quarter
          totalApprovedClaims
          approvedClaimsByBrand
          marketShare
        }
      }
    `;
    if (!payload?.brandName?.length) {
      delete payload.brandName;
    }
    return this.apollo
      .watchQuery({
        query: GET_Marketshare_Reference_Data,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
        variables: payload,
      })
      .valueChanges.pipe(
        map((result: any) => result?.data?.getMarketshareReferenceData)
      );
  }

  saveSimulation(payload: SaveSimulation) {
    const SAVE_MUTATION = gql`
      mutation saveSimulation($data: SimulationInput!) {
        saveSimulation(data: $data) {
          simulationId
          salesforceOpportunityId
          simulationName
          simulationDescription
          parameters
        }
      }
    `;
    if (!payload?.simulationId) {
      delete payload?.simulationId;
    }
    if (!payload?.parameters?.channelId) {
      delete payload?.parameters.channelId;
    }
    return this.apollo.mutate({
      mutation: SAVE_MUTATION,
      variables: { data: payload },
    });
  }
}
