import { CommonModule, TitleCasePipe } from '@angular/common';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  RadioButtonModule,
  ButtonModule,
  CheckBoxModule,
  ChipListModule,
} from '@syncfusion/ej2-angular-buttons';
import { DateRangePickerModule } from '@syncfusion/ej2-angular-calendars';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { TextBoxModule, SliderModule } from '@syncfusion/ej2-angular-inputs';
import {
  ComparisonNumber,
  DiscountType,
  GrowthValues,
  ScenarioType,
} from '../../shared/enum/generic.enum';
import {
  Discounts,
  DropdownType,
  ForecastScenarioOptions,
  MeasureOption,
  SaveScenario,
} from '../../shared/Interface/generic.model';
import { MenuEventArgs, MenuItemModel } from '@syncfusion/ej2-navigations';
import {
  ContextMenuComponent,
  ContextMenuModule,
} from '@syncfusion/ej2-angular-navigations';
import { DialogModule, DialogUtility } from '@syncfusion/ej2-angular-popups';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { ScenarioGraphqlService } from '../../graphql/scenario-graphql.service';
import {
  ToastComponent,
  ToastModule,
  ToastUtility,
} from '@syncfusion/ej2-angular-notifications';

import { ScenarioComparisonComponent } from '../scenario-comparison/scenario-comparison.component';
import { Subscription } from 'rxjs';
import { CommonService } from '../../shared/services/common.service';
import { MenuModule } from '@syncfusion/ej2-angular-navigations';
import { GenericService } from '../../graphql/generic.service';

@Component({
  selector: 'app-manage-scenario',
  standalone: true,
  imports: [
    TextBoxModule,
    ToastModule,
    CommonModule,
    ReactiveFormsModule,
    RadioButtonModule,
    ButtonModule,
    CheckBoxModule,
    DropDownListModule,
    ChipListModule,
    SliderModule,
    DateRangePickerModule,
    ContextMenuModule,
    DialogModule,
    FormsModule,
    NgxUiLoaderModule,
    ScenarioComparisonComponent,
    MenuModule,
  ],
  templateUrl: './manage-scenario.component.html',
  styleUrl: './manage-scenario.component.scss',
})
export class ManageScenarioComponent implements OnInit, OnChanges {
  @Output() scenarioComparisonFlag: EventEmitter<boolean> = new EventEmitter(
    false
  );
  @Output() scenarioIdEmitter: EventEmitter<number> = new EventEmitter();
  @Output() scenarioLength: EventEmitter<number> = new EventEmitter();
  @ViewChild('contextMenu') public contextMenu!: ContextMenuComponent;
  @ViewChild('ejDialog') ejDialog: DialogComponent | undefined;
  @ViewChild('container', { read: ElementRef }) container:
    | ElementRef
    | undefined;
  @Input() createScenarioFlag: boolean = false;
  @Input() simulationId: string = '';
  @Input() entityType: string = 'PBM';
  scenariosForm!: FormGroup | any;
  scenarios!: FormArray;
  discounts!: FormArray;
  growthUserProvided!: FormArray;
  discountGrowthUserProvided!: FormArray;
  isDialogVisible: boolean = false;
  deleteIndex: number;
  dialogInstance: any;
  actualScenarioDropdownValue: MeasureOption[] = [];
  forecastNames: string[] = [];
  forecastDemandNames: string[] = [];
  forecastMeasures: string[] = [];
  forecastPriceTypes: string[] = [];
  forecastRevenueMetrics: string[] = [];
  subscribe?: Subscription;
  scenarioId: number;
  minRange: string = 'MinRange';
  range: string = 'Range';
  sliders = [];
  sliderTooltip: Object = { isVisible: true };
  rangeTicksData: Object = {
    placement: 'After',
    largeStep: 20,
    smallStep: 10,
    showSmallTicks: true,
  };
  rangeLimits: Object = {
    enabled: true,
    maxEnd: 100,
    minStart: 0,
  };
  userProvidedTicksData: Object = {
    placement: 'After',
    largeStep: 30,
    smallStep: 20,
    showSmallTicks: true,
    format: 'N0',
  };
  userProvidedLimits: Object = {
    enabled: true,
    minStart: 0,
    minEnd: 300,
    maxStart: 0,
    maxEnd: 300,
    format: 'N0',
  };
  minDate: Date = new Date();
  public scenarioType: DropdownType[] = ScenarioType;
  public comparisonNumbers: DropdownType[] = ComparisonNumber;
  public growthValueOptions: DropdownType[] = GrowthValues;
  public discountType: DropdownType[] = DiscountType;
  public actualMenuItems: MenuItemModel[] = [
    {
      text: '',
      iconCss: 'fa-solid fa-circle-plus',
      items: [{ text: 'Historical' }, { text: 'New' }],
    },
  ];
  public forecastMenuItems: MenuItemModel[] = [
    {
      text: '',
      iconCss: 'fa-solid fa-circle-plus',
      items: [{ text: 'Historical' }, { text: 'New' }],
    },
  ];
  public animationSettings: Object = {
    effect: 'Zoom',
    duration: 400,
    delay: 0,
  };

  constructor(
    private formBuilder: FormBuilder,
    private loader: NgxUiLoaderService,
    private scenarioGraphqlService: ScenarioGraphqlService,
    private commonService: CommonService,
    private genericService: GenericService
  ) {}

  get scenariosArray(): FormArray {
    return this.scenariosForm?.get('scenarios') as FormArray;
  }

  scenariosDiscount(i: number): FormArray {
    return this.scenariosArray.at(i)?.get('discounts') as FormArray;
  }

  growthUserProvidedArr(i: number): FormArray {
    return this.scenariosArray.at(i)?.get('growthUserProvided') as FormArray;
  }

  discountGrowthUserProvidedArr(i?: number, j?: number): FormArray {
    return this.scenariosDiscount(i)
      .at(j)
      .get('discountGrowthUserProvided') as FormArray;
  }

  addScenario() {
    this.scenariosArray.push(this.createScenariosFormInitialize());
    this.scenarioLength.emit(
      this.scenariosArray?.value?.length
        ? this.scenariosArray?.value?.length
        : 0
    );
  }

  removeScenario(index: number) {
    this.scenariosArray.removeAt(index);
    this.scenarioLength.emit(
      this.scenariosArray?.value?.length
        ? this.scenariosArray?.value?.length
        : 0
    );
  }

  scenariosFormInitialize() {
    this.scenariosForm = this.formBuilder.group({
      scenarios: this.formBuilder.array([]),
    });
  }

  createScenariosFormInitialize(): FormGroup {
    return this.formBuilder.group({
      scenarioType: [this.scenarioType[0].id],
      scenarioId: [{ value: '', disabled: true }],
      scenarioName: ['', Validators.required],
      scenarioDescription: [''],
      numberOfRevenueForecasts: [1],
      baseDateRange: ['', Validators.required],
      forecastName: [''],
      forecastDemandName: [''],
      forecastMeasure: [''],
      forecastPriceType: [''],
      forecastRevenueMetric: [''],
      showScenarioComparisonFlag: [false],
      simulationGrowthType: [this.growthValueOptions[0].id],
      quantity: [''],
      grossRevenue: [''],
      growthUserProvided: this.formBuilder.array([
        this.growthUserProvidedForm(),
      ]),
      growthMonteCarloRange: [[0, 0]],
      growthMonteCarloRangeStart: [0],
      growthMonteCarloRangeEnd: [0],
      discounts: this.formBuilder.array([]),
    });
  }

  growthUserProvidedForm(discountRateValue?): FormGroup {
    return this.formBuilder.group({
      growthUserProvidedSlider: [discountRateValue || ''],
    });
  }

  createDiscount(discount?: any): FormGroup {
    const formGroup = this.formBuilder.group({
      discountType: [discount?.type ?? '', Validators.required],
      historicalDiscount: [discount?.value ?? ''],
      discountName: [discount?.name ?? ''],
      discountValue: [discount?.value ?? ''],
      discountGrowthUserProvided: this.formBuilder.array([
        this.growthUserProvidedForm(),
      ]),
      discountGrowthMonteCarloRange: [
        discount?.discountRates?.length ? discount.discountRates : [0, 0],
      ],
      discountGrowthMonteCarloRangeStart: [
        discount?.discountRates?.length ? discount.discountRates[0] : 0,
      ],
      discountGrowthMonteCarloRangeEnd: [
        discount?.discountRates?.length ? discount.discountRates[1] : 0,
      ],
    });
    formGroup.get('discountType').valueChanges?.subscribe((type) => {
      const historicalControl = formGroup.get('historicalDiscount');
      const discountName = formGroup.get('discountName');
      const discountValue = formGroup.get('discountValue');
      if (type === this.discountType[0].id) {
        historicalControl.enable();
        discountName.disable();
        discountValue.disable();
      } else {
        historicalControl.disable();
        discountName.enable();
        discountValue.enable();
      }
    });
    return formGroup;
  }

  addDiscount(parentIndex: number) {
    this.scenariosDiscount(parentIndex).push(this.createDiscount());
  }

  removeDiscount(parentIndex: number, cardIndex: number) {
    this.scenariosDiscount(parentIndex).removeAt(cardIndex);
  }

  onSavescenariosForm(i: number, entityType: string) {
    if (entityType === ScenarioType[1].id) {
      this.forecastScenariosPrepareRequestPayload(i);
    } else {
      this.actualScenariosPrepareRequestPayload(i);
    }
  }

  actualScenariosPrepareRequestPayload(i: number) {
    let actualFinalArray = [];
    let payload: SaveScenario = {
      scenarioId: '',
      simulationId: '',
      scenarioType: '',
      scenarioName: '',
      scenarioDescription: '',
      numberOfRevenueForecasts: 0,
      baseDateRangeStart: '',
      baseDateRangeEnd: '',
      simulationGrowthType: '',
      parameters: {
        growthRates: [],
        quantity: { measure: '', value: '' },
        grossRevenue: { measure: '', value: '' },
        discounts: [],
      },
    };
    this.scenariosForm.controls.scenarios.controls.forEach(
      (scenario, index) => {
        if (index === i) {
          payload.scenarioType = scenario.value.scenarioType || '';
          payload.simulationId = this.simulationId || '';
          payload.scenarioId = scenario.controls.scenarioId.value || '';
          payload.scenarioName = scenario.value.scenarioName || '';
          payload.scenarioDescription = scenario.value.scenarioDescription || '';
          payload.numberOfRevenueForecasts =
            scenario.value.numberOfRevenueForecasts || '';
          payload.baseDateRangeStart = scenario.value.baseDateRange[0]
            ? this.formatDate(new Date(scenario.value.baseDateRange[0]))
            : '';
          payload.baseDateRangeEnd = scenario.value.baseDateRange[1]
            ? this.formatDate(new Date(scenario.value.baseDateRange[1]))
            : '';
          payload.simulationGrowthType = scenario.value.simulationGrowthType;
          payload.parameters.quantity = this.actualScenarioDropdownValue.find(
            (obj) => obj.value === scenario.value.quantity
          );
          payload.parameters.grossRevenue = this.actualScenarioDropdownValue.find(
            (obj) => obj.value === scenario.value.grossRevenue
          );
          if (
            scenario.value?.simulationGrowthType === this.growthValueOptions[1].id
          ) {
            payload.parameters.growthRates = scenario.value.growthMonteCarloRange;
          } else if (
            scenario.value?.simulationGrowthType === this.growthValueOptions[0].id
          ) {
            payload.parameters.growthRates =
              scenario.value.growthUserProvided.map(
                (obj) => obj.growthUserProvidedSlider
              );
          }
          let discountArr = [];
          let discountPayload: Discounts = {
            type: '',
            name: '',
            value: '',
            discountRates: [],
          };
          scenario.value?.discounts?.forEach((discount) => {
            discountPayload.type = discount.discountType || '';
            if (discount.discountType === this.discountType[0].id) {
              let historyDiscountSelectLookupFind =
                this.actualScenarioDropdownValue.find(
                  (obj) => obj.value === discount.historicalDiscount
                );
              discountPayload.name =
                historyDiscountSelectLookupFind.measure || '';
              discountPayload.value = historyDiscountSelectLookupFind.value || '';
            } else {
              discountPayload.name = discount.discountName || '';
              discountPayload.value = discount.discountValue || '';
            }
            if (
              scenario.value?.simulationGrowthType ===
              this.growthValueOptions[1].id
            ) {
              discountPayload.discountRates =
                discount.discountGrowthMonteCarloRange;
            } else if (
              scenario.value?.simulationGrowthType ===
              this.growthValueOptions[0].id
            ) {
              discountPayload.discountRates =
                discount.discountGrowthUserProvided.map(
                  (obj) => obj.growthUserProvidedSlider
                );
            }
            discountArr.push({ ...discountPayload });
          });
          payload.parameters.discounts = discountArr;
          if (!payload.scenarioId) {
            delete payload.scenarioId;
          }
          actualFinalArray.push({ ...payload });
        }
      }
    );
    this.saveScenarioAPICalled(i, payload, ScenarioType[0].id);
  }

  forecastScenariosPrepareRequestPayload(i: number) {
    let forecastFinalArray = [];
    let payload: SaveScenario = {
      scenarioId: '',
      simulationId: '',
      scenarioType: '',
      scenarioName: '',
      scenarioDescription: '',
      numberOfRevenueForecasts: 0,
      baseDateRangeStart: '',
      baseDateRangeEnd: '',
      simulationGrowthType: '',
      parameters: {
        growthRates: [],
        forecastName: '',
        forecastDemandName: '',
        forecastMeasure: '',
        forecastPriceType: '',
        forecastRevenueMetric: '',
        discounts: [],
      },
    };
    this.scenariosForm.controls.scenarios.controls.forEach(
      (scenario, index) => {
        if (index === i) {
          payload.scenarioType = scenario.value.scenarioType || '';
          payload.simulationId = this.simulationId || '';
          payload.scenarioId = scenario.controls.scenarioId.value || '';
          payload.scenarioName = scenario.value.scenarioName || '';
          payload.scenarioDescription = scenario.value.scenarioDescription || '';
          payload.numberOfRevenueForecasts =
            scenario.value.numberOfRevenueForecasts || '';
          payload.baseDateRangeStart = scenario.value.baseDateRange[0]
            ? this.formatDate(new Date(scenario.value.baseDateRange[0]))
            : '';
          payload.baseDateRangeEnd = scenario.value.baseDateRange[1]
            ? this.formatDate(new Date(scenario.value.baseDateRange[1]))
            : '';
          payload.simulationGrowthType = scenario.value.simulationGrowthType;
          payload.parameters.forecastName = scenario.value.forecastName || '';
          payload.parameters.forecastDemandName =
            scenario.value.forecastDemandName || '';
          payload.parameters.forecastMeasure =
            scenario.value.forecastMeasure || '';
          payload.parameters.forecastPriceType =
            scenario.value.forecastPriceType || '';
          payload.parameters.forecastRevenueMetric =
            scenario.value.forecastRevenueMetric || '';
          if (
            scenario.value?.simulationGrowthType === this.growthValueOptions[1].id
          ) {
            payload.parameters.growthRates = scenario.value.growthMonteCarloRange;
          } else if (
            scenario.value?.simulationGrowthType === this.growthValueOptions[0].id
          ) {
            payload.parameters.growthRates =
              scenario.value.growthUserProvided.map(
                (obj) => obj.growthUserProvidedSlider
              );
          }
          let discountArr = [];
          let discountPayload: Discounts = {
            type: '',
            name: '',
            value: '',
            discountRates: [],
          };
          scenario.value?.discounts?.forEach((discount) => {
            discountPayload.type = discount.discountType || '';
            discountPayload.name = discount.discountName || '';
            discountPayload.value = discount.discountValue || '';
            if (
              scenario.value?.simulationGrowthType ===
              this.growthValueOptions[1].id
            ) {
              discountPayload.discountRates =
                discount.discountGrowthMonteCarloRange;
            } else if (
              scenario.value?.simulationGrowthType ===
              this.growthValueOptions[0].id
            ) {
              discountPayload.discountRates =
                discount.discountGrowthUserProvided.map(
                  (obj) => obj.growthUserProvidedSlider
                );
            }
            discountArr.push({ ...discountPayload });
          });
          payload.parameters.discounts = discountArr;
          if (!payload.scenarioId) {
            delete payload.scenarioId;
          }
          forecastFinalArray.push({ ...payload });
        }
      }
    );
    this.saveScenarioAPICalled(i, payload, ScenarioType[1].id);
  }

  saveScenarioAPICalled(
    i: number,
    payload: SaveScenario,
    scenarioType: string
  ) {
    this.loader.start();
    this.scenariosArray.controls[i][
      'controls'
    ].showScenarioComparisonFlag.setValue(false);
    this.scenarioGraphqlService.saveScenarios(payload).subscribe(
      (response: any) => {
        if (response?.data?.saveScenario?.scenario) {
          ToastUtility.show(
            {
              content: scenarioType + ' Scenario Successfully Saved!',
              timeOut: 10000,
              cssClass: 'e-toast-success',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Information'
          ) as ToastComponent;
          this.scenariosArray.controls[i]['controls'].scenarioId.setValue(
            response.data.saveScenario.scenario.scenarioId
          );
          this.scenariosArray.controls[i][
            'controls'
          ].showScenarioComparisonFlag.setValue(
            this.scenariosArray.controls[i]['controls'].scenarioId.value
              ? true
              : false
          );
          this.loader.stop();
          // Emit Data
          this.scenarioIdEmitter.emit(
            response?.data?.saveScenario?.scenario?.scenarioId
          );
          this.scenarioComparisonFlag.emit(true);
          this.scenariosForm.markAsPristine();
        } else {
          ToastUtility.show(
            {
              content:
                scenarioType +
                ' Scenario Not Created Successfully! Please Try Again Later',
              timeOut: 10000,
              cssClass: 'e-toast-danger',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Error'
          ) as ToastComponent;
          this.loader.stop();
        }
      },
      () => {
        this.loader.stop();
      }
    );
  }

  onSliderChange(index: number, value: number) {
    const growthArray = this.scenariosForm.get('actualgrowth') as FormArray;
    growthArray.at(index).setValue(value);
  }

  itemSelect(args: MenuEventArgs, i: number): void {
    if (!args.item?.text) {
      return;
    }
    const discountRow = this.scenariosDiscount(i);
    if (discountRow?.value?.length) {
      this.addDiscount(i);
      const lastIndex = discountRow.controls.length - 1;
      if (args.item.text === this.discountType[0].id) {
        discountRow.controls[lastIndex]['controls'].discountType.setValue(
          this.discountType[0].id
        );
      } else {
        discountRow.controls[lastIndex]['controls'].discountType.setValue(
          this.discountType[1].id
        );
      }
    } else {
      this.addDiscount(i);
      discountRow.controls[0]['controls'].discountType.setValue(
        args.item.text === this.discountType[0].id
          ? this.discountType[0].id
          : this.discountType[1].id
      );
    }
  }

  onContextMenu(event: MouseEvent) {
    this.contextMenu.open(event.pageY, event.pageX);
  }

  onOpenDialog = (index: number, scenarioId: number) => {
    this.scenarioId = scenarioId;
    this.deleteIndex = index;
    this.dialogInstance = DialogUtility.confirm({
      title: 'Delete Scenario',
      content: 'Are you sure you want to delete selected scenario?',
      okButton: { text: 'OK', click: this.okClick.bind(this) },
      cancelButton: { text: 'Cancel', click: this.cancelClick.bind(this) },
      showCloseIcon: true,
      width: '30%',
      closeOnEscape: true,
      animationSettings: { effect: 'Zoom' },
    });
  };

  okClick(): void {
    if (this.scenarioId) {
      this.scenarioGraphqlService
        .deleteScenario(this.scenarioId)
        .subscribe((response) => {
          if (response?.data?.deleteScenario) {
            this.removeScenario(this.deleteIndex);
            ToastUtility.show(
              {
                content: 'Scenario Deleted Successfully!',
                timeOut: 2000,
                cssClass: 'e-toast-success',
                position: { X: 'Right', Y: 'Top' },
                showCloseButton: true,
              },
              'Information'
            ) as ToastComponent;
          }
        });
    } else {
      this.removeScenario(this.deleteIndex);
    }
    this.dialogInstance.close();
  }

  cancelClick(): void {
    this.deleteIndex = null;
    this.dialogInstance.close();
  }

  growthValueSliderCreate(numberOfRevenueForecasts: number, index: number) {
    setTimeout(() => {
      const growthUserProvided = this.growthUserProvidedArr(index);
      growthUserProvided.clear();
      if (
        this.scenariosArray?.controls[index]['controls']?.simulationGrowthType
          ?.value === this.growthValueOptions[0].id
      ) {
        for (let i = 0; i < numberOfRevenueForecasts; i++) {
          growthUserProvided.push(this.growthUserProvidedForm());
        }
      }
    });
  }

  discountGrowthValueSliderCreate(
    event: any,
    numberOfRevenueForecasts: number,
    index: number,
    childIndex: number,
    htmlSide?: boolean
  ) {
    if (!event.item?.text) {
      return;
    }
    setTimeout(() => {
      const discountRow = this.scenariosDiscount(index);
      const discountGrowthUserProvided = this.discountGrowthUserProvidedArr(
        index ? index : 0,
        htmlSide ? discountRow.value.length - 1 : childIndex ? childIndex : 0
      );
      discountGrowthUserProvided.clear();
      if (
        this.scenariosArray?.controls[index]['controls']?.simulationGrowthType
          ?.value === this.growthValueOptions[0].id
      ) {
        for (let i = 0; i < numberOfRevenueForecasts; i++) {
          discountGrowthUserProvided.push(this.growthUserProvidedForm());
        }
      }
    });
  }

  comparisonNumberChange(numberOfRevenueForecasts: number, index: number) {
    const eventPass: any = { item: { text: 'byPassText' } };
    const growthUserProvided = this.growthUserProvidedArr(index);
    growthUserProvided.clear();
    if (numberOfRevenueForecasts) {
      this.growthValueSliderCreate(numberOfRevenueForecasts, index);
    }
    const discountArray = this.scenariosDiscount(index);
    if (discountArray?.length) {
      discountArray.value?.forEach((discount, discountIndex) => {
        this.discountGrowthValueSliderCreate(
          eventPass,
          numberOfRevenueForecasts,
          index,
          discountIndex,
          false
        );
      });
    }
  }

  discountGrowthMonteCarloRangeChange(i: number, j: number) {
    const discountSliderValue =
      this.scenariosDiscount(i).controls[j]['controls']
        .discountGrowthMonteCarloRange.value;
    this.scenariosDiscount(i).controls[j][
      'controls'
    ].discountGrowthMonteCarloRangeStart.setValue(discountSliderValue[0] || 0);
    this.scenariosDiscount(i).controls[j][
      'controls'
    ].discountGrowthMonteCarloRangeEnd.setValue(discountSliderValue[1] || 0);
  }

  discountGrowthMonteCarloRangeInputBoxChange(i: number, j: number) {
    this.scenariosDiscount(i).controls[j][
      'controls'
    ].discountGrowthMonteCarloRange.setValue([
      this.scenariosDiscount(i).controls[j]['controls']
        .discountGrowthMonteCarloRangeStart.value || 0,
      this.scenariosDiscount(i).controls[j]['controls']
        .discountGrowthMonteCarloRangeEnd.value || 0,
    ]);
  }

  growthMonteCarloRangeChange(i: number) {
    const sliderValue =
      this.scenariosArray.controls[i]['controls'].growthMonteCarloRange.value;
    this.scenariosArray.controls[i][
      'controls'
    ].growthMonteCarloRangeStart.setValue(sliderValue[0] || 0);
    this.scenariosArray.controls[i][
      'controls'
    ].growthMonteCarloRangeEnd.setValue(sliderValue[1] || 0);
  }

  growthMonteCarloRangeInputBoxChange(i: number) {
    this.scenariosArray.controls[i]['controls'].growthMonteCarloRange.setValue([
      this.scenariosArray.controls[i]['controls'].growthMonteCarloRangeStart
        .value || 0,
      this.scenariosArray.controls[i]['controls'].growthMonteCarloRangeEnd
        .value || 0,
    ]);
  }

  getCreateActualScenarioDropdownValueLists() {
    this.loader.start();
    this.scenarioGraphqlService
      .getActualScenarioOptions(this.entityType)
      .subscribe(
        (measure: MeasureOption[]) => {
          this.actualScenarioDropdownValue = measure?.length
            ? measure.map((obj) => ({ measure: obj.measure, value: obj.value }))
            : [];
          this.loader.stop();
        },
        () => {
          this.loader.stop();
        }
      );
  }

  getCreateForecastScenarioDropdownValueLists() {
    this.loader.start();
    this.scenarioGraphqlService
      .getForeCastScenarioOptions(this.entityType)
      .subscribe(
        (forecastLists: ForecastScenarioOptions) => {
          if (forecastLists) {
            this.forecastNames = forecastLists.forecastNames;
            this.forecastDemandNames = forecastLists.forecastDemandNames;
            this.forecastMeasures = forecastLists.forecastMeasures;
            this.forecastPriceTypes = forecastLists.forecastPriceTypes;
            this.forecastRevenueMetrics = forecastLists.forecastRevenueMetrics;
          }
          this.loader.stop();
        },
        () => {
          this.loader.stop();
        }
      );
  }

  formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  scenarioTypeChangeEvent(scenarioType: string, i: number) {
    if (scenarioType === ScenarioType[1].id) {
      this.forecastMenuItems = [
        {
          text: '',
          iconCss: 'fa-solid fa-circle-plus',
          items: [{ text: 'New' }],
        },
      ];
      this.manageDiscountForm(i);
    } else {
      this.actualMenuItems = [
        {
          text: '',
          iconCss: 'fa-solid fa-circle-plus',
          items: [{ text: 'Historical' }, { text: 'New' }],
        },
      ];
      this.manageDiscountForm(i);
    }
  }

  manageDiscountForm(i: number) {
    const discountArray = this.scenariosDiscount(i);
    while (discountArray.length !== 0) {
      discountArray.removeAt(0);
    }
  }

  onFiltering(event: any) {
    const query = event.text.toLowerCase();
    const actualScenarioDropdownValue = this.actualScenarioDropdownValue.filter(
      (item) => item.measure.toLowerCase().includes(query)
    );
    event.updateData(actualScenarioDropdownValue);
  }

  onForecastNameFiltering(event: any) {
    const query = event.text.toLowerCase();
    const forecastNames = this.forecastNames.filter((item) =>
      item.toLowerCase().includes(query)
    );
    event.updateData(forecastNames);
  }

  ngOnInit(): void {
    this.scenariosFormInitialize();
    this.subscribe = this.commonService.createScenarioFlag.subscribe(
      (createScenario) => {
        if (createScenario) {
          this.addScenario();
        }
      }
    );
    this.subscribe = this.genericService.formDirtyFlag$.subscribe(
      (alertPopup) => {
        if (alertPopup) {
          this.genericService.scenarioFormDirtyIndetify(
            this.scenariosForm.dirty
          );
        }
      }
    );
  }

  getScenariosDetailsBySimulationId(simulationId: string) {
    this.scenarioGraphqlService
      .getScenariosDetailsBySimulationId(simulationId)
      .subscribe((scenarioDetails: SaveScenario[]) => {
        if (scenarioDetails?.length) {
          this.createScenarioFlag = true;
          setTimeout(() => {
            this.scenarioLength.emit(
              this.scenariosArray?.value?.length
                ? this.scenariosArray?.value?.length
                : 0
            );
          }, 2500);
        }
        this.scenariosForm.patchValue(scenarioDetails);
        scenarioDetails?.forEach((element, index) => {
          this.scenariosArray.push(this.createScenariosFormInitialize());
          let titleCase = new TitleCasePipe();
          this.scenariosArray.at(index).patchValue({
            scenarioType: titleCase.transform(element.scenarioType),
            scenarioId: element.scenarioId,
            showScenarioComparisonFlag: element.scenarioId ? true : false,
            scenarioName: element.scenarioName,
            scenarioDescription: element.scenarioDescription,
            numberOfRevenueForecasts: element.numberOfRevenueForecasts,
            baseDateRange: [
              element.baseDateRangeStart,
              element.baseDateRangeEnd,
            ],
            simulationGrowthType: element.simulationGrowthType,
            forecastName: element.parameters?.forecastName,
            forecastDemandName: element.parameters?.forecastDemandName,
            forecastMeasure: element.parameters?.forecastMeasure,
            forecastPriceType: element.parameters?.forecastPriceType,
            forecastRevenueMetric: element.parameters?.forecastRevenueMetric,
            quantity: element.parameters?.quantity?.value,
            grossRevenue: element.parameters?.grossRevenue?.value,
            growthMonteCarloRangeStart:
              element.simulationGrowthType === this.growthValueOptions[1].id &&
              element.parameters?.growthRates?.length
                ? [element.parameters?.growthRates[0]]
                : 0,
            growthMonteCarloRangeEnd:
              element.simulationGrowthType === this.growthValueOptions[1].id &&
              element.parameters?.growthRates?.length
                ? [element.parameters?.growthRates[1]]
                : 0,
            growthMonteCarloRange:
              element.simulationGrowthType === this.growthValueOptions[1].id &&
              element.parameters?.growthRates?.length
                ? element.parameters?.growthRates
                : [0, 0],
          });
          this.comparisonNumberChange(element.numberOfRevenueForecasts, index);
          setTimeout(() => {
            if (
              element.parameters?.growthRates?.length &&
              element.simulationGrowthType === this.growthValueOptions[0].id
            ) {
              const growthUserProvided = this.scenariosArray
                .at(index)
                .get('growthUserProvided') as FormArray;
              element.parameters?.growthRates?.forEach((growthRates, index) => {
                growthUserProvided.controls[index][
                  'controls'
                ]?.growthUserProvidedSlider.setValue(growthRates);
              });
            }
            if (element.parameters?.discounts?.length) {
              const discountsFormArray = this.scenariosArray
                .at(index)
                .get('discounts') as FormArray;
              element.parameters.discounts.forEach(
                (discount, discountIndex) => {
                  const discountFormGroup = this.createDiscount(discount);
                  discountsFormArray.push(discountFormGroup);
                  const discountGrowthUserProvided =
                    this.discountGrowthUserProvidedArr(index, discountIndex);
                  discountGrowthUserProvided.clear();
                  if (
                    this.scenariosArray?.controls[index]['controls']
                      ?.simulationGrowthType?.value ===
                    this.growthValueOptions[0].id
                  ) {
                    for (
                      let i = 0;
                      i < element?.numberOfRevenueForecasts;
                      i++
                    ) {
                      discountGrowthUserProvided.push(
                        this.growthUserProvidedForm(discount.discountRates[i])
                      );
                    }
                  }
                }
              );
            }
          });
        });
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entityType']?.currentValue) {
      this.getCreateActualScenarioDropdownValueLists();
      this.getCreateForecastScenarioDropdownValueLists();
    }
    if (changes['simulationId']?.currentValue) {
      this.getScenariosDetailsBySimulationId(this.simulationId);
    }
  }

  ngOnDestroy(): void {
    this.subscribe?.unsubscribe();
    this.commonService.createScenarioState(false);
  }
}
