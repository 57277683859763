import { inject } from '@angular/core';
import { CanMatchFn, Router } from '@angular/router';
import { AuthGraphqlService } from 'app/user-access-management/graphql/auth-graphql.service';

export const userAuthGuard: CanMatchFn = (route, segments) => {
  const authGraphqlService = inject(AuthGraphqlService);
  const router = inject(Router);

  const userDetails = authGraphqlService.userDetail();
  const urls = ['user-management', 'role-management'];

  const isAccessible = userDetails.isSuperuser && urls.includes(route.path);

  if (!isAccessible) {
    router.navigateByUrl('/');
  }

  return isAccessible;
};
