import { Component } from '@angular/core';
import { SimulationComponent } from './simulation/simulation.component';
import { ScenarioComparisonComponent } from './scenario-comparison/scenario-comparison.component';
import { ManageScenarioComponent } from './manage-scenario/manage-scenario.component';

@Component({
  selector: 'app-deal-analytics',
  standalone: true,
  imports: [
    SimulationComponent,
    ManageScenarioComponent,
    ScenarioComparisonComponent,
  ],
  templateUrl: './deal-analytics.component.html',
  styleUrls: ['./deal-analytics.component.scss'],
})
export class DealAnalyticsComponent {
  createScenarioFlag: boolean = false;
  simulationId: string = '';
  scenarioLength: number = 0;
  entityType: string = '';
  scenarioComparisonFlag: boolean = false;
  scenarioId: number = 0;
  
  constructor() {}

  createScenario(isVisible: boolean) {
    this.createScenarioFlag = isVisible;
  }
  setScenarioComparison(isVisible: boolean) {
    this.scenarioComparisonFlag = isVisible;
  }
}
