<ngx-ui-loader [fgsPosition]="'center-center'" [bgsOpacity]="1"></ngx-ui-loader>

<app-header />

<div
  class="grid-cols-12 gap-2 relative"
  [ngClass]="{ 'lg:grid': showHeaderFooter }"
>
  @if(isSideBarOpen){
  <div class="lg:col-span-2 absolute lg:relative">
    <app-sidebar [isSideBarOpen]="isSideBarOpen" />
  </div>
  }
  <div
    [ngClass]="{
      'lg:col-span-10 fixed-height': isSideBarOpen,
      'lg:col-span-12': !isSideBarOpen
    }"
  >
    <router-outlet></router-outlet>
    <app-footer />
  </div>
</div>
