import { Property, ChildProperty, Collection, Event, getUniqueID, isNullOrUndefined, formatUnit, L10n, attributes, EventHandler, append, select, compile, removeClass, addClass, remove, Complex, NotifyPropertyChanges, Component } from '@syncfusion/ej2-base';
import { TextArea } from '@syncfusion/ej2-inputs';
import { Toolbar } from '@syncfusion/ej2-navigations';
var __extends = undefined && undefined.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
var __decorate = undefined && undefined.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
    r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
    d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var ASSISTHEADER = 'e-aiassist-header-text e-assist-view-header';
/**
 * The outputToolbarItems property maps the list of the outputToolbarItems and binds the data to the output items.
 */
var ToolbarItem = /** @__PURE__ @class */function (_super) {
  __extends(ToolbarItem, _super);
  function ToolbarItem() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate([Property('')], ToolbarItem.prototype, "iconCss", void 0);
  __decorate([Property()], ToolbarItem.prototype, "text", void 0);
  __decorate([Property('Button')], ToolbarItem.prototype, "type", void 0);
  __decorate([Property('Left')], ToolbarItem.prototype, "align", void 0);
  __decorate([Property(true)], ToolbarItem.prototype, "visible", void 0);
  __decorate([Property(false)], ToolbarItem.prototype, "disabled", void 0);
  __decorate([Property('')], ToolbarItem.prototype, "tooltip", void 0);
  __decorate([Property('')], ToolbarItem.prototype, "cssClass", void 0);
  __decorate([Property()], ToolbarItem.prototype, "template", void 0);
  __decorate([Property(-1)], ToolbarItem.prototype, "tabIndex", void 0);
  return ToolbarItem;
}(ChildProperty);
/**
 * The prompts property maps the list of the prompts and binds the data to the suggestions.
 */
var Prompt = /** @__PURE__ @class */function (_super) {
  __extends(Prompt, _super);
  function Prompt() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate([Property(null)], Prompt.prototype, "prompt", void 0);
  __decorate([Property('')], Prompt.prototype, "response", void 0);
  __decorate([Property(null)], Prompt.prototype, "isResponseHelpful", void 0);
  return Prompt;
}(ChildProperty);
/**
 * Specifies the type of assist view.
 */
var AssistViewType;
(function (AssistViewType) {
  /**
   * Represents the default assist view type.
   */
  AssistViewType["Assist"] = "Assist";
  /**
   * Represents a custom assist view type.
   */
  AssistViewType["Custom"] = "Custom";
})(AssistViewType || (AssistViewType = {}));
/**
 * The assistView property maps the customized AiAssistView.
 */
var AssistView = /** @__PURE__ @class */function (_super) {
  __extends(AssistView, _super);
  function AssistView() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate([Property('Assist')], AssistView.prototype, "type", void 0);
  __decorate([Property('')], AssistView.prototype, "name", void 0);
  __decorate([Property()], AssistView.prototype, "iconCss", void 0);
  __decorate([Property()], AssistView.prototype, "viewTemplate", void 0);
  return AssistView;
}(ChildProperty);
/**
 * The toolbarSettings property maps the list of the toolbarSettings and binds the data to the output items.
 */
var ToolbarSettings = /** @__PURE__ @class */function (_super) {
  __extends(ToolbarSettings, _super);
  function ToolbarSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate([Collection([], ToolbarItem)], ToolbarSettings.prototype, "items", void 0);
  __decorate([Event()], ToolbarSettings.prototype, "itemClicked", void 0);
  return ToolbarSettings;
}(ChildProperty);
/**
 * The promptToolbarSettings property maps the list of the promptToolbarSettings and binds the data to the prompt.
 */
var PromptToolbarSettings = /** @__PURE__ @class */function (_super) {
  __extends(PromptToolbarSettings, _super);
  function PromptToolbarSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate([Property('100%')], PromptToolbarSettings.prototype, "width", void 0);
  __decorate([Collection([], ToolbarItem)], PromptToolbarSettings.prototype, "items", void 0);
  __decorate([Event()], PromptToolbarSettings.prototype, "itemClicked", void 0);
  return PromptToolbarSettings;
}(ChildProperty);
/**
 * The responseToolbarSettings property maps the list of the responseToolbarSettings and binds the data to the output items.
 */
var ResponseToolbarSettings = /** @__PURE__ @class */function (_super) {
  __extends(ResponseToolbarSettings, _super);
  function ResponseToolbarSettings() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  __decorate([Property('100%')], ResponseToolbarSettings.prototype, "width", void 0);
  __decorate([Collection([], ToolbarItem)], ResponseToolbarSettings.prototype, "items", void 0);
  __decorate([Event()], ResponseToolbarSettings.prototype, "itemClicked", void 0);
  return ResponseToolbarSettings;
}(ChildProperty);
/**
 * The `AIAssistView` component is designed to enhance user interaction by integrating AI driven assistance features.
 * It provides a seamless interface for incorporating suggestions & AI responses.
 *
 * ```html
 *  <div id='defaultAIAssistView'></div>
 * ```
 * ```typescript
 *  let aiAssistObj: AIAssistView = new AIAssistView();
 *  aiAssistObj.appendTo('#defaultAIAssistView');
 * ```
 */
var AIAssistView = /** @__PURE__ @class */function (_super) {
  __extends(AIAssistView, _super);
  /**
   * Constructor for creating the component
   *
   * @param {AIAssistViewModel} options - Specifies the AIAssistViewModel model.
   * @param {string | HTMLElement} element - Specifies the element to render as component.
   * @private
   */
  function AIAssistView(options, element) {
    var _this = _super.call(this, options, element) || this;
    _this.toolbarItems = [];
    _this.displayContents = [];
    _this.preTagElements = [];
    return _this;
  }
  /**
   * Initialize the event handler
   *
   * @private
   * @returns {void}
   */
  AIAssistView.prototype.preRender = function () {
    if (!this.element.id) {
      this.element.id = getUniqueID('e-' + this.getModuleName());
    }
  };
  AIAssistView.prototype.getDirective = function () {
    return 'EJS-AIASSISTVIEW';
  };
  /**
   * To get component name.
   *
   * @returns {string} - It returns the current module name.
   * @private
   */
  AIAssistView.prototype.getModuleName = function () {
    return 'aiassistview';
  };
  /**
   * Get the properties to be maintained in the persisted state.
   *
   * @private
   * @returns {string} - It returns the persisted data.
   */
  AIAssistView.prototype.getPersistData = function () {
    return this.addOnPersist([]);
  };
  AIAssistView.prototype.render = function () {
    this.renderPromptView();
  };
  AIAssistView.prototype.renderPromptView = function () {
    this.setDimension();
    this.renderToolbar();
    this.wireEvents();
  };
  /* To calculate the width when change via set model */
  AIAssistView.prototype.setDimension = function () {
    this.element.style.width = !isNullOrUndefined(this.width) ? formatUnit(this.width) : this.element.style.width;
    this.element.style.height = !isNullOrUndefined(this.height) ? formatUnit(this.height) : this.element.style.height;
  };
  AIAssistView.prototype.renderToolbar = function () {
    var _this = this;
    this.assistViewTemplateIndex = -1;
    this.aiAssistViewRendered = false;
    this.isAssistView = false;
    this.isOutputRenderingStop = false;
    this.isResponseRequested = false;
    var isAssistViewAssigned = false;
    var assistView;
    var customViewTemplate;
    var customViewCount = 1;
    if (this.views.length > 0) {
      for (var index = 0; index < this.views.length; index++) {
        if (this.views[parseInt(index.toString(), 10)].type.toLocaleLowerCase() === 'assist' && !isAssistViewAssigned) {
          assistView = {
            text: this.views[parseInt(index.toString(), 10)].name || 'AI Assist',
            prefixIcon: this.views[parseInt(index.toString(), 10)].iconCss || 'e-icons e-assistview-icon',
            cssClass: ASSISTHEADER,
            htmlAttributes: {
              'data-index': this.element.id + '_view_0'
            }
          };
          this.toolbarItems.unshift(assistView);
          if (this.views[parseInt(index.toString(), 10)].viewTemplate) {
            this.assistViewTemplateIndex = index;
          }
          isAssistViewAssigned = true;
          this.isAssistView = true;
        } else if (this.views[parseInt(index.toString(), 10)].type.toLocaleLowerCase() === 'custom') {
          customViewTemplate = this.createElement('div', {
            attrs: {
              class: 'e-customview-content-section-' + customViewCount + ' e-custom-view'
            }
          });
          this.updateContent('customViewTemplate', customViewTemplate, -1, index);
          this.displayContents.push(customViewTemplate);
          this.toolbarItems.push({
            text: this.views[parseInt(index.toString(), 10)].name || '',
            prefixIcon: this.views[parseInt(index.toString(), 10)].iconCss || '',
            cssClass: 'e-aiassist-header-text e-custom-view-header',
            htmlAttributes: {
              'data-index': this.element.id + '_view_' + customViewCount.toString()
            }
          });
          customViewCount++;
        }
      }
    }
    if (this.views.length === 0 || !isAssistViewAssigned) {
      assistView = {
        text: 'AI Assist',
        prefixIcon: 'e-icons e-assistview-icon',
        cssClass: ASSISTHEADER,
        htmlAttributes: {
          'data-index': this.element.id + '_view_0'
        }
      };
      this.toolbarItems.unshift(assistView);
      isAssistViewAssigned = true;
    }
    this.renderViews();
    this.renderToolbarSettings();
    if (this.assistViewTemplateIndex < 0) {
      this.displayContents.unshift(this.contentWrapper);
    } else {
      this.displayContents.unshift(this.assistCustomSection);
    }
    this.previousElement = this.displayContents[this.activeView];
    this.toolbar = new Toolbar({
      items: this.toolbarItems,
      height: '100%',
      clicked: function (args) {
        var eventItemArgs = {
          type: args.item.type,
          text: args.item.text,
          iconCss: args.item.prefixIcon,
          cssClass: args.item.cssClass,
          tooltip: args.item.tooltipText,
          template: args.item.template,
          disabled: args.item.disabled,
          visible: args.item.visible,
          align: args.item.align,
          tabIndex: args.item.tabIndex
        };
        var eventArgs = {
          item: eventItemArgs,
          event: args.originalEvent,
          cancel: false
        };
        if (_this.toolbarSettings.itemClicked) {
          _this.toolbarSettings.itemClicked.call(_this, eventArgs);
        }
        if (!eventArgs.cancel) {
          if (args.item.htmlAttributes) {
            var currentIndex = parseInt(args.item.htmlAttributes['data-index'].split(_this.element.id + '_view_')[1], 10);
            if (currentIndex !== _this.activeView) {
              var prevOnChange = _this.isProtectedOnChange;
              _this.isProtectedOnChange = true;
              var previousIndex = _this.getIndex(_this.activeView);
              _this.activeView = parseInt(args.item.htmlAttributes['data-index'].split(_this.element.id + '_view_')[1], 10);
              _this.updateActiveView(previousIndex);
              _this.isProtectedOnChange = prevOnChange;
            }
          }
        }
      }
    });
    this.toolbarHeader = this.createElement('div', {
      attrs: {
        class: 'e-view-header'
      }
    });
    var toolbarEle = this.createElement('div');
    this.toolbar.appendTo(toolbarEle);
    this.toolbar.element.setAttribute('aria-label', 'assist-view-toolbar-header');
    this.toolbarHeader.appendChild(toolbarEle);
    this.element.appendChild(this.toolbarHeader);
    this.viewWrapper = this.createElement('div', {
      attrs: {
        class: 'e-view-content'
      }
    });
    this.updateActiveView();
    this.element.appendChild(this.viewWrapper);
    if (this.cssClass) {
      this.element.classList.add(this.cssClass);
    }
    this.updateToolbarHeader();
    this.aiAssistViewRendered = true;
    if (this.enableRtl) {
      this.element.classList.add('e-rtl');
    }
  };
  AIAssistView.prototype.renderToolbarSettings = function () {
    if (this.toolbarSettings.items.length > 0) {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      var pushToolbar = this.toolbarSettings.items.map(function (item) {
        return {
          type: item.type,
          template: item.template,
          disabled: item.disabled,
          cssClass: item.cssClass,
          visible: item.visible,
          tooltipText: item.tooltip,
          prefixIcon: item.iconCss,
          text: item.text,
          align: item.align
        };
      });
      this.toolbarItems = this.toolbarItems.concat(pushToolbar);
    }
  };
  AIAssistView.prototype.getIndex = function (currentIndex) {
    return currentIndex > this.views.length - (this.isAssistView ? 1 : 0) || currentIndex < 0 ? 0 : currentIndex;
  };
  AIAssistView.prototype.updateActiveView = function (previousIndex) {
    var activeViewIndex = this.getIndex(this.activeView);
    if (!this.aiAssistViewRendered) {
      this.appendView(activeViewIndex);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (this.toolbar.tbarEle[parseInt(activeViewIndex.toString(), 10)]) {
        this.toolbar.tbarEle[parseInt(activeViewIndex.toString(), 10)].classList.add('e-active');
      }
    } else if (previousIndex !== activeViewIndex) {
      this.removePreviousView(previousIndex, activeViewIndex);
      this.appendView(activeViewIndex);
    }
    this.previousElement = this.displayContents[parseInt(activeViewIndex.toString(), 10)];
  };
  AIAssistView.prototype.appendView = function (activeViewIndex) {
    //updating the new view section according to the activeView property
    if (activeViewIndex === 0 && this.assistViewTemplateIndex < 0) {
      this.viewWrapper.append(this.contentWrapper, this.stopResponding, this.footer);
    } else if (activeViewIndex === 0 && this.assistViewTemplateIndex >= 0) {
      this.viewWrapper.append(this.assistCustomSection);
    } else {
      this.viewWrapper.append(this.displayContents[parseInt(activeViewIndex.toString(), 10)]);
    }
  };
  AIAssistView.prototype.removePreviousView = function (previousIndex, activeViewIndex) {
    // removing the previously binded element
    this.viewWrapper.removeChild(this.previousElement);
    if (previousIndex === 0 && this.assistViewTemplateIndex < 0) {
      this.viewWrapper.removeChild(this.stopResponding);
      this.viewWrapper.removeChild(this.footer);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (this.toolbar.tbarEle[parseInt(activeViewIndex.toString(), 10)]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.toolbar.tbarEle[parseInt(activeViewIndex.toString(), 10)].classList.add('e-active');
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (previousIndex >= 0 && this.toolbar.tbarEle[parseInt(previousIndex.toString(), 10)]) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.toolbar.tbarEle[parseInt(previousIndex.toString(), 10)].classList.remove('e-active');
    }
  };
  AIAssistView.prototype.renderViews = function () {
    if (this.assistViewTemplateIndex >= 0 && this.views[this.assistViewTemplateIndex].viewTemplate) {
      this.assistCustomSection = this.createElement('div', {
        attrs: {
          class: 'e-assistview-content-section-0',
          'data-index': this.element.id + '_view_0'
        }
      });
      this.updateContent('assistViewTemplate', this.assistCustomSection, -1, this.assistViewTemplateIndex);
    } else {
      this.renderDefaultView();
    }
  };
  AIAssistView.prototype.renderDefaultView = function () {
    this.contentWrapper = this.createElement('div', {
      attrs: {
        class: 'e-views',
        'data-index': this.element.id + '_view_0'
      }
    });
    var contentContainer = this.createElement('div', {
      attrs: {
        class: 'e-view-container'
      }
    });
    this.content = this.createElement('div', {
      attrs: {
        class: 'e-content-container'
      }
    });
    this.footer = this.createElement('div', {
      attrs: {
        class: "e-footer " + (this.footerTemplate ? 'e-footer-template' : '')
      }
    });
    this.renderContent();
    this.renderFooterContent();
    if (this.bannerTemplate) {
      var introContainer = this.createElement('div', {
        attrs: {
          class: 'e-banner-view'
        }
      });
      this.updateContent('bannerTemplate', introContainer);
      contentContainer.append(introContainer);
    }
    contentContainer.append(this.content);
    this.contentWrapper.appendChild(contentContainer);
    this.stopResponding = this.createElement('div', {
      attrs: {
        class: 'e-stop-response',
        tabIndex: '0',
        'aria-label': 'Stop Responding',
        role: 'button'
      }
    });
    var stopRespondingIcon = this.createElement('span', {
      attrs: {
        class: 'e-icons e-assist-stop'
      }
    });
    this.stopRespondingContent = this.createElement('span', {
      attrs: {
        class: 'e-stop-response-text'
      }
    });
    this.l10n = new L10n('aiassistview', {
      stopResponseText: 'Stop Responding'
    }, this.locale);
    this.updateStopRespondingTitle();
    this.appendChildren(this.stopResponding, stopRespondingIcon, this.stopRespondingContent);
  };
  AIAssistView.prototype.updateStopRespondingTitle = function () {
    this.l10n.setLocale(this.locale);
    this.stopRespondingContent.textContent = this.l10n.getConstant('stopResponseText');
  };
  AIAssistView.prototype.renderFooterContent = function () {
    if (this.footerTemplate) {
      this.updateContent('footerTemplate', this.footer);
    } else {
      var textareaEle = this.createElement('textarea', {
        attrs: {
          class: 'e-assist-textarea'
        }
      });
      this.footer.appendChild(textareaEle);
      this.renderFooter(textareaEle);
      this.footer.appendChild(this.sendIcon);
    }
  };
  AIAssistView.prototype.renderContent = function () {
    this.renderOutputContent();
    this.renderSuggestions();
    if (this.outputElement) {
      this.renderSkeleton();
    }
  };
  AIAssistView.prototype.renderOutputContent = function (isMethodCall) {
    var _this = this;
    this.outputElement = this.createElement('div', {
      attrs: {
        class: 'e-content'
      }
    });
    if (this.responseToolbarSettings.items.length === 0) {
      var prevOnChange = this.isProtectedOnChange;
      this.isProtectedOnChange = true;
      this.responseToolbarSettings.items = [{
        iconCss: 'e-icons e-assist-copy',
        tooltip: 'Copy',
        cssClass: 'check'
      }, {
        iconCss: 'e-icons e-assist-like',
        tooltip: 'Like'
      }, {
        iconCss: 'e-icons e-assist-dislike',
        tooltip: 'Dislike'
      }];
      this.isProtectedOnChange = prevOnChange;
    }
    if (this.prompts) {
      this.prompts.forEach(function (prompt, i) {
        _this.renderOutputContainer(prompt.prompt, prompt.response, i);
      });
    }
    this.content.appendChild(this.outputElement);
    if (this.suggestions) {
      this.content.insertBefore(this.suggestions, this.outputElement);
    }
    if (isMethodCall) {
      this.aiAssistViewRendered = true;
    }
  };
  AIAssistView.prototype.renderSuggestions = function () {
    if (this.promptSuggestions && this.promptSuggestions.length > 0) {
      this.suggestions = this.createElement('div', {
        attrs: {
          class: "e-suggestions " + (this.promptSuggestionItemTemplate ? 'e-suggestion-item-template' : '')
        }
      });
      this.suggestionHeader = this.createElement('div', {
        attrs: {
          class: 'e-suggestion-header'
        }
      });
      var suggestionList = this.createElement('div', {
        attrs: {
          class: 'e-suggestion-list'
        }
      });
      this.renderSuggestionList(suggestionList);
      if (this.promptSuggestionsHeader) {
        this.suggestionHeader.innerHTML = this.promptSuggestionsHeader;
        this.suggestions.append(this.suggestionHeader);
      }
      this.suggestions.append(suggestionList);
      this.content.append(this.suggestions);
    }
  };
  AIAssistView.prototype.renderSuggestionList = function (suggestionWrapper) {
    var _this = this;
    var suggestionsListElement = this.createElement('ul', {
      attrs: {
        'tabindex': '-1'
      }
    });
    this.promptSuggestions.forEach(function (suggestion, i) {
      var suggestionList = _this.createElement('li');
      attributes(suggestionList, {
        'tabindex': '0'
      });
      EventHandler.add(suggestionList, 'click', _this.onSuggestionClick, _this);
      EventHandler.add(suggestionList, 'keydown', _this.suggestionItemHandler, _this);
      if (_this.promptSuggestionItemTemplate) {
        _this.updateContent('promptSuggestionItemTemplate', suggestionList, -1, i);
      } else {
        suggestionList.innerHTML = suggestion;
      }
      suggestionsListElement.append(suggestionList);
    });
    suggestionWrapper.appendChild(suggestionsListElement);
  };
  AIAssistView.prototype.updateContent = function (templateName, contentEle, index, arrayPosition) {
    var notCompile = !(this.isReact || this.isVue);
    var template;
    var context = {};
    var contextIndex = index >= 0 ? index : -1;
    var contextPrompt = index >= 0 ? this.prompts[parseInt(contextIndex.toString(), 10)].prompt : '';
    var contextOutput = index >= 0 ? this.prompts[parseInt(contextIndex.toString(), 10)].response : '';
    if (this.isReact) {
      this.clearTemplate([templateName]);
    }
    switch (templateName.toLowerCase()) {
      case 'footertemplate':
        {
          template = this.footerTemplate;
          break;
        }
      case 'promptitemtemplate':
        {
          template = this.promptItemTemplate;
          context = {
            prompt: contextPrompt,
            toolbarItems: this.promptToolbarSettings.items,
            index: contextIndex
          };
          break;
        }
      case 'responseitemtemplate':
        {
          template = this.responseItemTemplate;
          context = {
            prompt: contextPrompt,
            response: contextOutput,
            index: contextIndex,
            toolbarItems: this.responseToolbarSettings.items,
            output: contextOutput
          };
          break;
        }
      case 'promptsuggestionitemtemplate':
        {
          template = this.promptSuggestionItemTemplate;
          context = {
            index: arrayPosition,
            promptSuggestion: this.promptSuggestions[parseInt(arrayPosition.toString(), 10)]
          };
          break;
        }
      case 'customviewtemplate':
      case 'assistviewtemplate':
        {
          template = this.views[parseInt(arrayPosition.toString(), 10)].viewTemplate || '';
          break;
        }
      case 'bannertemplate':
        {
          template = this.bannerTemplate;
          break;
        }
    }
    var ctn = this.getTemplateFunction(template, notCompile);
    if (typeof ctn === 'string') {
      contentEle.innerHTML = ctn;
    } else {
      append(ctn(context, this), contentEle);
    }
    this.renderReactTemplates();
  };
  /**
   * Gets template content based on the template property value.
   *
   * @param {string | Function} template - Template property value.
   * @param {boolean} notCompile - Compile property value.
   * @returns {Function} - Return template function.
   * @hidden
   */
  AIAssistView.prototype.getTemplateFunction = function (template, notCompile) {
    if (typeof template === 'string') {
      var content = '';
      try {
        var tempEle = select(template);
        if (tempEle) {
          //Return innerHTML incase of jsrenderer script else outerHTML
          content = tempEle.tagName === 'SCRIPT' ? tempEle.innerHTML : tempEle.outerHTML;
          notCompile = false;
        } else {
          content = template;
        }
      } catch (e) {
        content = template;
      }
      return notCompile ? content : compile(content);
    } else {
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
      return compile(template);
    }
  };
  AIAssistView.prototype.renderFooter = function (textareaElement) {
    var rowCount = this.getRowCount(this.prompt);
    this.textareaObj = new TextArea({
      rows: rowCount,
      cols: 300,
      cssClass: rowCount >= 10 ? 'show-scrollbar' : 'hide-scrollbar',
      placeholder: this.promptPlaceholder,
      resizeMode: 'None',
      value: this.prompt,
      showClearButton: this.showClearButton,
      input: this.handleInput.bind(this)
    });
    this.textareaObj.appendTo(textareaElement);
    this.sendIcon = this.createElement('span', {
      attrs: {
        class: 'e-assist-send e-icons disabled',
        role: 'button',
        'aria-label': 'Submit',
        tabindex: '0'
      }
    });
    this.activateSendIcon(this.textareaObj.value.length);
  };
  AIAssistView.prototype.handleInput = function (args) {
    var prevOnChange = this.isProtectedOnChange;
    this.isProtectedOnChange = true;
    this.prompt = args.value;
    this.isProtectedOnChange = prevOnChange;
    this.activateSendIcon(args.value.length);
    this.updateTextAreaObject(args.value);
    var eventArgs = {
      value: args.value,
      previousValue: args.previousValue,
      event: args.event,
      element: this.textareaObj.element
    };
    this.trigger('promptChanged', eventArgs);
  };
  AIAssistView.prototype.updateTextAreaObject = function (textValue) {
    var rowCount = this.getRowCount(textValue);
    this.textareaObj.rows = rowCount;
    this.textareaObj.cssClass = rowCount >= 10 ? 'show-scrollbar' : 'hide-scrollbar';
  };
  AIAssistView.prototype.getRowCount = function (textValue) {
    var lines = textValue.split('\n').length;
    return lines < 10 ? lines >= 1 ? lines : 1 : 10;
  };
  AIAssistView.prototype.activateSendIcon = function (value) {
    this.sendIcon.classList.toggle('disabled', value === 0);
    this.sendIcon.classList.toggle('enabled', value > 0);
  };
  AIAssistView.prototype.suggestionItemHandler = function (e) {
    this.keyHandler(e, 'suggestionitem');
  };
  AIAssistView.prototype.footerKeyHandler = function (e) {
    this.keyHandler(e, 'footer');
  };
  AIAssistView.prototype.stopResponseKeyHandler = function (e) {
    this.keyHandler(e, 'stopresponse');
  };
  AIAssistView.prototype.wireEvents = function () {
    if (this.sendIcon) {
      EventHandler.add(this.sendIcon, 'click', this.onSendIconClick, this);
    }
    if (this.footer && !this.footerTemplate) {
      EventHandler.add(this.footer, 'keydown', this.footerKeyHandler, this);
    }
    if (this.stopResponding) {
      EventHandler.add(this.stopResponding, 'click', this.respondingStopper, this);
      EventHandler.add(this.stopResponding, 'keydown', this.stopResponseKeyHandler, this);
    }
  };
  AIAssistView.prototype.unWireEvents = function () {
    if (this.sendIcon) {
      EventHandler.remove(this.sendIcon, 'click', this.onSendIconClick);
    }
    if (this.footer && !this.footerTemplate) {
      EventHandler.remove(this.footer, 'keydown', this.footerKeyHandler);
    }
    if (this.stopResponding) {
      EventHandler.remove(this.stopResponding, 'click', this.respondingStopper);
      EventHandler.remove(this.stopResponding, 'keydown', this.stopResponseKeyHandler);
    }
    this.detachCodeCopyEventHandler();
  };
  AIAssistView.prototype.detachCodeCopyEventHandler = function () {
    this.preTagElements.forEach(function (_a) {
      var preTag = _a.preTag,
        handler = _a.handler;
      var copyIcon = preTag.querySelector('.e-code-copy');
      EventHandler.remove(copyIcon, 'click', handler);
    });
    this.preTagElements = [];
  };
  AIAssistView.prototype.keyHandler = function (event, value) {
    if (event.key === 'Enter' && !event.shiftKey) {
      switch (value) {
        case 'footer':
          event.preventDefault();
          if (!this.isResponseRequested) {
            this.textareaObj.value = '';
            this.updateTextAreaObject(this.textareaObj.value);
            this.onSendIconClick();
          }
          break;
        case 'suggestionitem':
          this.onSuggestionClick(event);
          break;
        case 'stopresponse':
          this.respondingStopper();
          break;
      }
    }
  };
  AIAssistView.prototype.respondingStopper = function () {
    this.isOutputRenderingStop = true;
    this.isResponseRequested = false;
    if (this.outputElement.hasChildNodes) {
      this.outputElement.removeChild(this.skeletonContainer);
    }
    this.stopResponding.classList.remove('e-btn-active');
  };
  AIAssistView.prototype.onSendIconClick = function () {
    if (this.isResponseRequested || !this.prompt.trim()) {
      return;
    }
    this.isResponseRequested = true;
    if (this.suggestions) {
      this.suggestions.hidden = true;
    }
    this.isOutputRenderingStop = false;
    this.stopResponding.classList.add('e-btn-active');
    this.addPrompt();
    this.createOutputElement();
    var eventArgs = {
      cancel: false,
      responseToolbarItems: this.responseToolbarSettings.items,
      prompt: this.prompt,
      promptSuggestions: this.promptSuggestions
    };
    if (!this.footerTemplate) {
      var prevOnChange = this.isProtectedOnChange;
      this.isProtectedOnChange = true;
      this.prompt = this.textareaObj.value = '';
      this.isProtectedOnChange = prevOnChange;
      this.updateTextAreaObject(this.textareaObj.value);
      this.activateSendIcon(this.textareaObj.value.length);
    }
    this.trigger('promptRequest', eventArgs);
    if (this.contentWrapper) {
      this.contentWrapper.scrollTo(0, this.contentWrapper.scrollHeight);
    }
  };
  AIAssistView.prototype.addPrompt = function () {
    var prevOnChange = this.isProtectedOnChange;
    this.isProtectedOnChange = true;
    this.prompts = this.prompts.concat([{
      prompt: this.prompt,
      response: null,
      isResponseHelpful: null
    }]);
    this.isProtectedOnChange = prevOnChange;
  };
  AIAssistView.prototype.createOutputElement = function () {
    this.outputSuggestionEle = this.createElement('div', {
      attrs: {
        id: "e-prompt-item_" + (this.prompts.length - 1),
        class: "e-prompt-container " + (this.promptItemTemplate ? 'e-prompt-item-template' : '')
      }
    });
    this.renderPrompt(this.prompt, this.prompts.length - 1);
    this.outputElement.append(this.outputSuggestionEle, this.skeletonContainer);
    this.skeletonContainer.hidden = false;
  };
  AIAssistView.prototype.renderOutputContainer = function (promptText, outputText, index, isMethodCall) {
    var outputContainer = this.createElement('div', {
      attrs: {
        id: "e-response-item_" + index,
        class: "e-output-container " + (this.responseItemTemplate ? 'e-response-item-template' : '')
      }
    });
    this.renderOutput(outputContainer, promptText, outputText, isMethodCall, index);
    if (promptText) {
      this.outputElement.append(this.outputSuggestionEle);
    }
    this.outputElement.append(outputContainer);
    if (this.stopResponding) {
      this.stopResponding.classList.remove('e-btn-active');
    }
  };
  AIAssistView.prototype.renderOutput = function (outputContainer, promptText, outputText, isMethodCall, index) {
    var promptIcon = this.createElement('span', {
      attrs: {
        class: 'e-output-icon e-icons ' + (this.responseIconCss || this.isAssistView && this.views[0].iconCss || 'e-assistview-icon')
      }
    });
    var aiOutputEle = this.createElement('div', {
      attrs: {
        class: 'e-output'
      }
    });
    if (!this.aiAssistViewRendered || isMethodCall) {
      if (!isNullOrUndefined(promptText)) {
        this.outputSuggestionEle = this.createElement('div', {
          attrs: {
            id: "e-prompt-item_" + index,
            class: "e-prompt-container " + (this.promptItemTemplate ? 'e-prompt-item-template' : '')
          }
        });
        this.renderPrompt(promptText, index);
      }
    }
    var lastPrompt = {
      prompt: promptText,
      response: outputText
    };
    if (lastPrompt.response) {
      if (this.responseItemTemplate) {
        this.updateContent('responseItemTemplate', aiOutputEle, index);
        if (this.outputElement.querySelector('.e-skeleton')) {
          this.outputElement.removeChild(this.skeletonContainer);
        }
        if (this.contentFooterEle) {
          this.contentFooterEle.classList.remove('e-assist-toolbar-active');
        }
        this.renderOutputToolbarItems(index);
        aiOutputEle.append(this.contentFooterEle);
        outputContainer.append(aiOutputEle);
      } else {
        this.renderOutputTextContainer(lastPrompt.response, aiOutputEle, index);
        outputContainer.append(promptIcon, aiOutputEle);
      }
    } else if (this.aiAssistViewRendered) {
      if (this.outputElement.querySelector('.e-skeleton')) {
        this.outputElement.removeChild(this.skeletonContainer);
      }
      if (this.suggestions) {
        this.suggestions.hidden = false;
      }
    }
  };
  AIAssistView.prototype.renderOutputTextContainer = function (response, aiOutputEle, index, isMethodCall) {
    var _this = this;
    if (this.contentFooterEle) {
      this.contentFooterEle.classList.remove('e-assist-toolbar-active');
    }
    this.outputContentBodyEle = this.createElement('div', {
      attrs: {
        class: 'e-content-body',
        tabindex: '0'
      }
    });
    if (!isMethodCall) {
      this.outputContentBodyEle.innerHTML = response;
      var preTags = Array.from(this.outputContentBodyEle.querySelectorAll('pre'));
      preTags.forEach(function (preTag) {
        var copyIcon = document.createElement('span');
        copyIcon.className = 'e-icons e-code-copy e-assist-copy';
        preTag.insertBefore(copyIcon, preTag.firstChild);
        _this.preTagElements.push({
          preTag: preTag,
          handler: _this.getCopyHandler(preTag)
        });
        EventHandler.add(copyIcon, 'click', _this.preTagElements[_this.preTagElements.length - 1].handler);
      });
    }
    this.renderOutputToolbarItems(index);
    this.appendChildren(aiOutputEle, this.outputContentBodyEle, this.contentFooterEle);
  };
  AIAssistView.prototype.getCopyHandler = function (preTag) {
    return function () {
      var preText = preTag.innerText;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.navigator.clipboard.writeText(preText);
      var copyIcon = preTag.querySelector('.e-code-copy');
      copyIcon.className = 'e-icons e-code-copy e-assist-check';
      setTimeout(function () {
        copyIcon.className = 'e-icons e-code-copy e-assist-copy';
      }, 1000);
    };
  };
  AIAssistView.prototype.renderOutputToolbarItems = function (index) {
    this.contentFooterEle = this.createElement('div', {
      attrs: {
        class: 'e-content-footer e-assist-toolbar-active'
      }
    });
    var footerContent = this.createElement('div');
    this.renderResponseToolbar(index);
    if (this.aiAssistViewRendered) {
      if (this.outputElement.querySelector('.e-skeleton')) {
        this.outputElement.removeChild(this.skeletonContainer);
      }
      if (this.suggestions) {
        this.suggestions.hidden = false;
      }
    }
    this.responseToolbarEle.appendTo(footerContent);
    this.responseToolbarEle.element.setAttribute('aria-label', "response-toolbar-" + index);
    this.contentFooterEle.appendChild(footerContent);
  };
  AIAssistView.prototype.renderResponseToolbar = function (index) {
    var _this = this;
    var pushToolbar = this.responseToolbarSettings.items.map(function (item) {
      var toolbarItem = {
        type: item.type,
        visible: item.visible,
        disabled: item.disabled,
        tooltipText: item.tooltip,
        template: item.template,
        prefixIcon: item.iconCss,
        text: item.text,
        cssClass: item.cssClass,
        align: item.align,
        width: _this.responseToolbarSettings.width
      };
      if (toolbarItem.prefixIcon === 'e-icons e-assist-like' && _this.prompts[parseInt(index.toString(), 10)].isResponseHelpful) {
        toolbarItem.prefixIcon = 'e-icons e-assist-like-filled';
      } else if (toolbarItem.prefixIcon === 'e-icons e-assist-dislike' && _this.prompts[parseInt(index.toString(), 10)].isResponseHelpful === false) {
        toolbarItem.prefixIcon = 'e-icons e-assist-dislike-filled';
      }
      return toolbarItem;
    });
    this.responseToolbarEle = new Toolbar({
      items: pushToolbar,
      clicked: function (args) {
        var eventItemArgs = {
          type: args.item.type,
          text: args.item.text,
          iconCss: args.item.prefixIcon,
          cssClass: args.item.cssClass,
          tooltip: args.item.tooltipText,
          template: args.item.template,
          disabled: args.item.disabled,
          visible: args.item.visible,
          align: args.item.align,
          tabIndex: args.item.tabIndex
        };
        var eventArgs = {
          item: eventItemArgs,
          event: args.originalEvent,
          cancel: false,
          dataIndex: index
        };
        if (_this.responseToolbarSettings.itemClicked) {
          _this.responseToolbarSettings.itemClicked.call(_this, eventArgs);
        }
        if (!eventArgs.cancel) {
          _this.handleItemClick(args, index);
        }
      }
    });
  };
  AIAssistView.prototype.getClipBoardContent = function (value) {
    var tempElement = document.createElement('div');
    tempElement.innerHTML = value;
    tempElement.style.top = '0';
    tempElement.style.left = '0';
    tempElement.style.position = 'fixed';
    tempElement.style.opacity = '0';
    document.body.appendChild(tempElement);
    var copyText = tempElement.innerText;
    document.body.removeChild(tempElement);
    window.navigator.clipboard.writeText(copyText);
  };
  AIAssistView.prototype.handleItemClick = function (args, index) {
    var _this = this;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    args.item.controlParent.element.querySelector('.e-assist-dislike');
    if (args.item.prefixIcon === 'e-icons e-assist-copy') {
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      this.getClipBoardContent(this.prompts[parseInt(index.toString(), 10)].response);
      args.item.prefixIcon = 'e-icons e-assist-check';
      this.responseToolbarEle.dataBind();
      setTimeout(function () {
        args.item.prefixIcon = 'e-icons e-assist-copy';
        _this.responseToolbarEle.dataBind();
      }, 1000);
    }
    var icon = args.item.prefixIcon;
    var isLikeInteracted = icon === 'e-icons e-assist-like-filled' || icon === 'e-icons e-assist-like';
    var isDislikeInteracted = icon === 'e-icons e-assist-dislike-filled' || icon === 'e-icons e-assist-dislike';
    if (isLikeInteracted || isDislikeInteracted) {
      var isHelpful = null;
      if (isLikeInteracted) {
        isHelpful = this.prompts[parseInt(index.toString(), 10)].isResponseHelpful === true ? null : true;
      } else if (isDislikeInteracted) {
        isHelpful = this.prompts[parseInt(index.toString(), 10)].isResponseHelpful === false ? null : false;
      }
      var prevOnChange = this.isProtectedOnChange;
      this.isProtectedOnChange = true;
      this.prompts[parseInt(index.toString(), 10)].isResponseHelpful = isHelpful;
      var promptItem = this.prompts[parseInt(index.toString(), 10)];
      // eslint-disable-next-line  @typescript-eslint/no-explicit-any
      var controlParentItems = args.item.controlParent.items;
      if (isLikeInteracted) {
        if (promptItem.isResponseHelpful === true) {
          args.item.prefixIcon = 'e-icons e-assist-like-filled';
          if (controlParentItems && controlParentItems.length > 2) {
            controlParentItems[2].prefixIcon = 'e-icons e-assist-dislike';
          }
        } else {
          args.item.prefixIcon = 'e-icons e-assist-like';
        }
      } else if (isDislikeInteracted) {
        if (promptItem.isResponseHelpful === false) {
          args.item.prefixIcon = 'e-icons e-assist-dislike-filled';
          if (controlParentItems && controlParentItems.length > 1) {
            controlParentItems[1].prefixIcon = 'e-icons e-assist-like';
          }
        } else {
          args.item.prefixIcon = 'e-icons e-assist-dislike';
        }
      }
      this.responseToolbarEle.dataBind();
      this.isProtectedOnChange = prevOnChange;
    }
  };
  AIAssistView.prototype.renderPrompt = function (promptText, promptIndex) {
    var outputPrompt = this.createElement('div', {
      attrs: {
        class: 'e-prompt-text',
        tabindex: '0'
      }
    });
    var promptContent = this.createElement('div', {
      attrs: {
        class: 'e-prompt-content'
      }
    });
    var promptToolbarContainer = this.createElement('div', {
      attrs: {
        class: 'e-prompt-toolbar'
      }
    });
    var promptToolbar = this.createElement('div');
    var userIcon = this.createElement('span', {
      attrs: {
        class: this.promptIconCss ? 'e-prompt-icon e-icons ' + this.promptIconCss : ''
      }
    });
    if (this.promptItemTemplate) {
      this.updateContent('promptItemTemplate', this.outputSuggestionEle, promptIndex);
    } else {
      outputPrompt.innerHTML = promptText;
      this.appendChildren(promptContent, outputPrompt);
      if (this.promptIconCss) {
        promptContent.appendChild(userIcon);
      }
      this.outputSuggestionEle.append(promptContent);
    }
    this.renderPromptToolbar(promptToolbar, promptIndex);
    promptToolbarContainer.append(promptToolbar);
    this.appendChildren(this.outputSuggestionEle, promptToolbarContainer);
  };
  AIAssistView.prototype.renderPromptToolbar = function (element, promptIndex) {
    var _this = this;
    var pushToolbar = [];
    if (this.promptToolbarSettings.items.length === 0) {
      pushToolbar = [{
        prefixIcon: 'e-icons e-assist-edit',
        tooltipText: 'Edit'
      }, {
        prefixIcon: 'e-icons e-assist-copy',
        tooltipText: 'Copy'
      }];
      var prevOnChange = this.isProtectedOnChange;
      this.isProtectedOnChange = true;
      this.promptToolbarSettings.items = [{
        iconCss: 'e-icons e-assist-edit',
        tooltip: 'Edit'
      }, {
        iconCss: 'e-icons e-assist-copy',
        tooltip: 'Copy'
      }];
      this.isProtectedOnChange = prevOnChange;
    } else {
      pushToolbar = this.promptToolbarSettings.items.map(function (item) {
        return {
          type: item.type,
          template: item.template,
          disabled: item.disabled,
          cssClass: item.cssClass,
          visible: item.visible,
          tooltipText: item.tooltip,
          prefixIcon: item.iconCss,
          text: item.text,
          align: item.align,
          width: _this.promptToolbarSettings.width
        };
      });
    }
    this.promptToolbarEle = new Toolbar({
      items: pushToolbar,
      clicked: function (args) {
        var eventItemArgs = {
          type: args.item.type,
          text: args.item.text,
          iconCss: args.item.prefixIcon,
          cssClass: args.item.cssClass,
          tooltip: args.item.tooltipText,
          template: args.item.template,
          disabled: args.item.disabled,
          visible: args.item.visible,
          align: args.item.align,
          tabIndex: args.item.tabIndex
        };
        var eventArgs = {
          item: eventItemArgs,
          event: args.originalEvent,
          cancel: false,
          dataIndex: promptIndex
        };
        if (_this.promptToolbarSettings.itemClicked) {
          _this.promptToolbarSettings.itemClicked.call(_this, eventArgs);
        }
        if (!eventArgs.cancel) {
          if (args.item.prefixIcon === 'e-icons e-assist-edit') {
            _this.onEditIconClick(promptIndex);
          }
          if (args.item.prefixIcon === 'e-icons e-assist-copy') {
            // eslint-disable-next-line  @typescript-eslint/no-explicit-any
            _this.getClipBoardContent(_this.prompts[parseInt(promptIndex.toString(), 10)].prompt);
            args.item.prefixIcon = 'e-icons e-assist-check';
            _this.promptToolbarEle.dataBind();
            setTimeout(function () {
              args.item.prefixIcon = 'e-icons e-assist-copy';
              _this.promptToolbarEle.dataBind();
            }, 1000);
          }
        }
      }
    });
    this.promptToolbarEle.appendTo(element);
    this.promptToolbarEle.element.setAttribute('aria-label', "prompt-toolbar-" + promptIndex);
  };
  AIAssistView.prototype.renderSkeleton = function () {
    this.skeletonContainer = this.createElement('div', {
      attrs: {
        class: 'e-output-container'
      }
    });
    var outputViewWrapper = this.createElement('div', {
      attrs: {
        class: 'e-output',
        style: 'width: 70%;'
      }
    });
    var skeletonIconEle = this.createElement('span', {
      attrs: {
        class: 'e-output-icon e-skeleton e-skeleton-text e-shimmer-wave'
      }
    });
    var skeletonBodyEle = this.createElement('div', {
      attrs: {
        class: 'e-loading-body'
      }
    });
    var skeletonFooterEle = this.createElement('div', {
      attrs: {
        class: 'e-loading-footer'
      }
    });
    var _a = [this.createElement('div', {
        attrs: {
          class: 'e-skeleton e-skeleton-text e-shimmer-wave',
          style: 'width: 100%; height: 15px;'
        }
      }), this.createElement('div', {
        attrs: {
          class: 'e-skeleton e-skeleton-text e-shimmer-wave',
          style: 'width: 75%; height: 15px;'
        }
      }), this.createElement('div', {
        attrs: {
          class: 'e-skeleton e-skeleton-text e-shimmer-wave',
          style: 'width: 50%; height: 15px;'
        }
      })],
      skeletonLine1 = _a[0],
      skeletonLine2 = _a[1],
      skeletonLine3 = _a[2];
    var footerSkeleton = [this.createElement('div', {
      attrs: {
        class: 'e-skeleton e-skeleton-text e-shimmer-wave',
        style: 'width: 100%; height: 30px;'
      }
    })][0];
    this.appendChildren(skeletonBodyEle, skeletonLine1, skeletonLine2, skeletonLine3);
    skeletonFooterEle.append(footerSkeleton);
    this.appendChildren(outputViewWrapper, skeletonBodyEle, skeletonFooterEle);
    this.appendChildren(this.skeletonContainer, skeletonIconEle, outputViewWrapper);
  };
  AIAssistView.prototype.appendChildren = function (target) {
    var children = [];
    for (var _i = 1; _i < arguments.length; _i++) {
      children[_i - 1] = arguments[_i];
    }
    target.append.apply(target, children);
  };
  AIAssistView.prototype.onSuggestionClick = function (e) {
    this.suggestions.hidden = true;
    var prevOnChange = this.isProtectedOnChange;
    this.isProtectedOnChange = true;
    this.prompt = e.target.innerText;
    this.isProtectedOnChange = prevOnChange;
    this.onSendIconClick();
  };
  AIAssistView.prototype.onEditIconClick = function (promptIndex) {
    if (this.textareaObj) {
      if (this.suggestions) {
        this.suggestions.hidden = true;
      }
      var prevOnChange = this.isProtectedOnChange;
      this.isProtectedOnChange = true;
      this.textareaObj.value = this.prompt = this.prompts[parseInt(promptIndex.toString(), 10)].prompt;
      this.updateTextAreaObject(this.textareaObj.value);
      this.textareaObj.focusIn();
      this.isProtectedOnChange = prevOnChange;
      this.activateSendIcon(this.prompt.length);
    }
  };
  AIAssistView.prototype.updateIcons = function (newCss, isPromptIconCss) {
    if (isPromptIconCss === void 0) {
      isPromptIconCss = false;
    }
    var elements;
    if (this.outputElement) {
      if (isPromptIconCss) {
        newCss = 'e-prompt-icon e-icons ' + newCss;
        elements = this.outputElement.querySelectorAll('.e-prompt-icon');
      } else {
        newCss = ' e-output-icon e-icons ' + newCss;
        elements = this.outputElement.querySelectorAll('.e-output-icon');
      }
    }
    for (var index = 0; index < (elements && elements.length); index++) {
      removeClass([elements[parseInt(index.toString(), 10)]], elements[parseInt(index.toString(), 10)].classList.toString().trim().split(' '));
      addClass([elements[parseInt(index.toString(), 10)]], newCss.trim().split(' '));
    }
  };
  AIAssistView.prototype.updateToolbarHeader = function () {
    if (!this.showHeader) {
      this.toolbarHeader.hidden = true;
    } else {
      this.toolbarHeader.hidden = false;
    }
  };
  AIAssistView.prototype.updateToolbarSettings = function (previousToolbar) {
    var previousToolbarIndex = 0;
    for (var index = this.views.length; index < this.toolbarItems.length; index++) {
      if (previousToolbar.items[parseInt(previousToolbarIndex.toString(), 10)] === this.toolbarItems[parseInt(index.toString(), 10)]) {
        this.toolbarItems.splice(index, 1);
      }
    }
    this.renderToolbarSettings();
    this.toolbar.items = this.toolbarItems;
  };
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  AIAssistView.prototype.destroyAndNullify = function (obj) {
    if (obj) {
      obj.destroy();
      obj = null;
    }
  };
  AIAssistView.prototype.destroy = function () {
    _super.prototype.destroy.call(this);
    this.unWireEvents();
    this.destroyAndNullify(this.textareaObj);
    this.destroyAndNullify(this.responseToolbarEle);
    this.destroyAndNullify(this.promptToolbarEle);
    this.destroyAndNullify(this.toolbar);
    this.destroyAssistView();
    //private html elements nullify
    remove(this.viewWrapper);
    this.viewWrapper = null;
    this.aiAssistViewRendered = null;
    this.assistViewTemplateIndex = null;
    this.toolbarItems = [];
    this.displayContents = [];
    this.isOutputRenderingStop = null;
    this.isResponseRequested = null;
    this.suggestionHeader = null;
    this.previousElement = null;
    this.assistCustomSection = null;
    this.preTagElements = [];
    // properties nullify
    this.toolbarSettings = this.promptToolbarSettings = this.responseToolbarSettings = {};
    if (this.cssClass) {
      removeClass([this.element], this.cssClass.split(' '));
    }
    this.element.classList.remove('e-rtl');
  };
  AIAssistView.prototype.removeAndNullify = function (element) {
    if (element) {
      if (!isNullOrUndefined(element.parentNode)) {
        remove(element);
      } else {
        element.innerHTML = '';
      }
    }
  };
  AIAssistView.prototype.destroyAssistView = function () {
    var properties = ['toolbarHeader', 'sendIcon', 'suggestions', 'skeletonContainer', 'outputElement', 'outputSuggestionEle', 'contentFooterEle', 'footer', 'assistCustomSection', 'content', 'stopRespondingContent', 'stopResponding', 'contentWrapper'];
    for (var _i = 0, properties_1 = properties; _i < properties_1.length; _i++) {
      var prop = properties_1[_i];
      var element = prop;
      this.removeAndNullify(this[element]);
      this[element] = null;
    }
  };
  /**
   * Executes the specified prompt in the AIAssistView component. The method accepts a string representing the prompt.
   *
   * @param {string} prompt - The prompt text to be executed. It must be a non-empty string.
   *
   * @returns {void}
   */
  AIAssistView.prototype.executePrompt = function (prompt) {
    if (!isNullOrUndefined(prompt) && prompt.trim().length > 0) {
      var prevOnChange = this.isProtectedOnChange;
      this.isProtectedOnChange = true;
      this.prompt = prompt;
      this.isProtectedOnChange = prevOnChange;
      this.onSendIconClick();
    }
  };
  /**
   * Adds a response to the last prompt or appends a new Prompt data in the AIAssistView component.
   *
   * @param {string | Object} promptData - The response to be added. Can be a string representing the response or an object containing both the prompt and the response.
   * - If `outputResponse` is a string, it updates the response for the last prompt in the prompts collection.
   * - If `outputResponse` is an Object, it can either update the response of an existing prompt if the prompt matches or append a new Prompt data.
   * @returns {void}
   */
  AIAssistView.prototype.addPromptResponse = function (promptData) {
    var prevOnChange = this.isProtectedOnChange;
    this.isProtectedOnChange = true;
    if (!this.isOutputRenderingStop) {
      if (typeof promptData === 'string') {
        if (!this.isResponseRequested) {
          this.prompts = this.prompts.concat([{
            prompt: null,
            response: null,
            isResponseHelpful: null
          }]);
        }
        this.prompts[this.prompts.length - 1].response = promptData;
        this.renderOutputContainer(undefined, promptData, this.prompts.length - 1, false);
      }
      if (typeof promptData === 'object') {
        var tPrompt = {
          prompt: promptData.prompt,
          response: promptData.response,
          isResponseHelpful: isNullOrUndefined(promptData.isResponseHelpful) ? null : promptData.isResponseHelpful
        };
        if (this.prompt === tPrompt.prompt) {
          this.prompts[this.prompts.length - 1].response = tPrompt.response;
          this.prompts[this.prompts.length - 1].isResponseHelpful = tPrompt.isResponseHelpful;
          this.renderOutputContainer(undefined, tPrompt.response, this.prompts.length - 1, false);
        } else {
          this.prompts = this.prompts.concat([tPrompt]);
          this.renderOutputContainer(tPrompt.prompt, tPrompt.response, this.prompts.length - 1, true);
        }
      }
      this.isResponseRequested = false;
    }
    this.isProtectedOnChange = prevOnChange;
  };
  /**
   * Called if any of the property value is changed.
   *
   * @param  {AIAssistViewModel} newProp - Specifies new properties
   * @param  {AIAssistViewModel} oldProp - Specifies old properties
   * @returns {void}
   * @private
   */
  AIAssistView.prototype.onPropertyChanged = function (newProp, oldProp) {
    for (var _i = 0, _a = Object.keys(newProp); _i < _a.length; _i++) {
      var prop = _a[_i];
      switch (prop) {
        case 'width':
        case 'height':
          this.setDimension();
          break;
        case 'cssClass':
          if (oldProp.cssClass) {
            removeClass([this.element], oldProp.cssClass.trim().split(' '));
          }
          if (newProp.cssClass) {
            addClass([this.element], newProp.cssClass.trim().split(' '));
          }
          break;
        case 'promptIconCss':
          this.updateIcons(newProp.promptIconCss, true);
          break;
        case 'responseIconCss':
          this.updateIcons(newProp.responseIconCss);
          break;
        case 'showHeader':
          this.updateToolbarHeader();
          break;
        case 'promptSuggestions':
          if (this.suggestions) {
            this.suggestions.remove();
          }
          if (!this.isOutputRenderingStop) {
            this.renderSuggestions();
          }
          break;
        case 'showClearButton':
          this.textareaObj.showClearButton = this.showClearButton;
          break;
        case 'promptPlaceholder':
          this.textareaObj.placeholder = this.promptPlaceholder;
          break;
        case 'promptSuggestionsHeader':
          {
            this.suggestionHeader.innerHTML = this.promptSuggestionsHeader;
            var suggestionHeaderElem = this.element.querySelector('.e-suggestions .e-suggestion-header');
            if (!suggestionHeaderElem) {
              this.suggestions.append(this.suggestionHeader);
            }
            break;
          }
        case 'activeView':
          {
            var previousViewIndex = this.getIndex(oldProp.activeView);
            this.updateActiveView(previousViewIndex);
            break;
          }
        case 'enableRtl':
          this.element.classList[this.enableRtl ? 'add' : 'remove']('e-rtl');
          break;
        case 'toolbarSettings':
          this.updateToolbarSettings(oldProp.toolbarSettings);
          break;
        case 'promptToolbarSettings':
        case 'responseToolbarSettings':
        case 'prompts':
          if (this.outputElement) {
            remove(this.outputElement);
          }
          if (this.stopResponding) {
            this.stopResponding.classList.remove('e-btn-active');
          }
          this.aiAssistViewRendered = false;
          this.renderOutputContent(true);
          this.detachCodeCopyEventHandler();
          break;
        case 'prompt':
          if (!this.footerTemplate) {
            this.textareaObj.value = this.prompt;
          }
          break;
        case 'locale':
          this.updateStopRespondingTitle();
          break;
      }
    }
  };
  __decorate([Property('')], AIAssistView.prototype, "prompt", void 0);
  __decorate([Property('Type prompt for assistance...')], AIAssistView.prototype, "promptPlaceholder", void 0);
  __decorate([Collection([], Prompt)], AIAssistView.prototype, "prompts", void 0);
  __decorate([Property([])], AIAssistView.prototype, "promptSuggestions", void 0);
  __decorate([Property('')], AIAssistView.prototype, "promptSuggestionsHeader", void 0);
  __decorate([Property(true)], AIAssistView.prototype, "showHeader", void 0);
  __decorate([Complex({
    items: []
  }, ToolbarSettings)], AIAssistView.prototype, "toolbarSettings", void 0);
  __decorate([Property(0)], AIAssistView.prototype, "activeView", void 0);
  __decorate([Property(null)], AIAssistView.prototype, "promptIconCss", void 0);
  __decorate([Property(null)], AIAssistView.prototype, "responseIconCss", void 0);
  __decorate([Property('100%')], AIAssistView.prototype, "width", void 0);
  __decorate([Property('100%')], AIAssistView.prototype, "height", void 0);
  __decorate([Property('')], AIAssistView.prototype, "cssClass", void 0);
  __decorate([Collection([], AssistView)], AIAssistView.prototype, "views", void 0);
  __decorate([Complex({
    width: null,
    items: []
  }, PromptToolbarSettings)], AIAssistView.prototype, "promptToolbarSettings", void 0);
  __decorate([Complex({
    width: null,
    items: []
  }, ResponseToolbarSettings)], AIAssistView.prototype, "responseToolbarSettings", void 0);
  __decorate([Property(false)], AIAssistView.prototype, "showClearButton", void 0);
  __decorate([Property('')], AIAssistView.prototype, "footerTemplate", void 0);
  __decorate([Property('')], AIAssistView.prototype, "promptItemTemplate", void 0);
  __decorate([Property('')], AIAssistView.prototype, "responseItemTemplate", void 0);
  __decorate([Property('')], AIAssistView.prototype, "promptSuggestionItemTemplate", void 0);
  __decorate([Property('')], AIAssistView.prototype, "bannerTemplate", void 0);
  __decorate([Event()], AIAssistView.prototype, "promptRequest", void 0);
  __decorate([Event()], AIAssistView.prototype, "created", void 0);
  __decorate([Event()], AIAssistView.prototype, "promptChanged", void 0);
  AIAssistView = __decorate([NotifyPropertyChanges], AIAssistView);
  return AIAssistView;
}(Component);
export { AIAssistView, AssistView, AssistViewType, Prompt, PromptToolbarSettings, ResponseToolbarSettings, ToolbarItem, ToolbarSettings };
