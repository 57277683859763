import { Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from '@environments/environment';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const msalConfig: Configuration = {
    auth: {
        clientId: `${environment.clientId}`,
        authority: 'https://login.microsoftonline.com/' + environment.tenantId,
        redirectUri: '/simulation-list',
        postLogoutRedirectUri: '/login'
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage, // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: isIE, // Set this to "true" if you are having issues on IE11 or Edge
    },
};