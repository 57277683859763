<div class="m-3 lg:m-0 lg:my-3 lg:ml-3 sidebar-section" [ngClass]="{'hidden': !isSideBarOpen && isMobileView}">
    <ejs-sidebar id="default-sidebar" #sidebar (created)="onCreated($event)"
        class="relative rounded-md side-bar-section p-3">
        <i class="fa-solid fa-xmark text-end w-full mb-3 lg:hidden" (click)="closeSideBar()"></i>
        <div class="e-section-control space-y-2 fixed-sidebar-height">
            @for(item of sideBarData; track item.label){
            <button
                class="side-bar-btn w-full border-0 block capitalize px-2 py-3 shadow-none rounded-md hover:shadow-none"
                [ngClass]="{'active': pathUrl.includes(item.path)}" (click)="setActiveButton(item.path)"
                ejs-button>
                <i [ngClass]="item.icon + ' mr-2'"></i> {{ item.label }}
            </button>
            }
        </div>
        <div class="user flex items-center gap-2">
            <div class="user-icon"></div>
            <div class="user-name">
                <p>Welcome</p>
                <h6>&lt;user Id&gt;</h6>
            </div>
        </div>
    </ejs-sidebar>
</div>
