import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { SimulationListGrid } from '../shared/Interface/generic.model';

@Injectable({
  providedIn: 'root',
})
export class SimulationListGraphqlService {
  constructor(private apollo: Apollo) {}

  getSimulationGridListsData(): Observable<SimulationListGrid[]> {
    const GET_ENTITY_TYPE = gql`
      query {
        simulations {
          simulationId
          simulationName
          simulationDescription
          createdBy
          createdAt
          updatedAt
        }
      }
    `;
    return this.apollo
      .watchQuery({
        query: GET_ENTITY_TYPE,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(map((result: any) => result?.data?.simulations));
  }

  deleteSimulation(simulationId: string): Observable<any> {
    const DELETE_SIMULATION = gql`
      mutation deleteSimulation($simulationId: String!) {
        deleteSimulation(simulationId: $simulationId)
      }
    `;
    return this.apollo.mutate({
      mutation: DELETE_SIMULATION,
      variables: { simulationId: simulationId },
    });
  }
}
