import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import { AuthState } from '@okta/okta-auth-js';
import { filter, map, Subject, take } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { ButtonModule, CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import {
  ToastComponent,
  ToastUtility,
} from '@syncfusion/ej2-angular-notifications';
import { AuthGraphqlService } from 'app/graphql/auth-graphql.service';
import {
  MsalBroadcastService,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus
} from '@azure/msal-browser';

@Component({
  selector: 'app-sign-in',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    ButtonModule,
    TextBoxModule,
    CommonModule,
    CheckBoxModule,
    MsalModule,
    RouterModule
  ],
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
  loginForm: FormGroup;
  showPassword: boolean = false;
  isRemember: boolean = false;
  isOktaProcessing: boolean = false;
  loggedIn: boolean;
  private oktaStateService = inject(OktaAuthStateService);
  private oktaAuth = inject(OKTA_AUTH);
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authGraphqlService: AuthGraphqlService,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      rememberMe: [false],
    });
  }

  verifyAD() {
    this.authService
    .loginPopup()
    .subscribe((response: AuthenticationResult) => {
      this.authGraphqlService
        .verifyAD(response.idToken)
        .subscribe((response: any) => {
          if (response.data && response.data?.verifyAD?.token) {
            localStorage.setItem(
              'predealtoken',
              response.data.verifyAD.token
            );
            this.router.navigateByUrl('simulation-list');
          } else if (response.errors && response.errors.length) {
            ToastUtility.show(
              {
                content: response.errors[0].message,
                timeOut: 5000,
                cssClass: 'e-toast-danger',
                position: { X: 'Right', Y: 'Top' },
                showCloseButton: true,
              },
              'Error'
            ) as ToastComponent;
            //this.authService.logout();
          }
        });
    });
  }

  onClickLogin() {
    if (this.loginForm.valid) {
      const formData = this.loginForm.value;
      this.authGraphqlService.login(formData).subscribe((response: any) => {
        if (response?.data?.login) {
          localStorage.setItem('predealtoken', response.data.login.token);
          this.router.navigateByUrl('simulation-list');
        } else if (response.errors && response.errors.length) {
          ToastUtility.show(
            {
              content: response.errors[0].message,
              timeOut: 5000,
              cssClass: 'e-toast-danger',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Error'
          ) as ToastComponent;
        }
      });
    }
  }

  togglePassword() {
    this.showPassword = !this.showPassword;
  }

  public isOktaAuthenticated = this.oktaStateService.authState$.pipe(
    filter((s: AuthState) => !!s),
    map((s: AuthState) => s.isAuthenticated ?? false)
  );

  public oktaAccessToken = this.oktaStateService.authState$.pipe(
    filter((s: AuthState) => !!s),
    map((s: AuthState) => JSON.stringify(s.accessToken?.accessToken) ?? '')
  );

  verifyOkta() {
    const self = this;
    self.isOktaProcessing = true;
    self.oktaAccessToken.pipe(take(1)).subscribe((accessToken: any) => {
      const tokenValue = JSON.parse(accessToken);
      self.authGraphqlService
        .verifyOkta(tokenValue)
        .subscribe((response: any) => {
          if (response.data && response.data.verifyOkta) {
            localStorage.setItem(
              'predealtoken',
              response.data.verifyOkta.token
            );
            self.router.navigateByUrl('simulation-list');
          } else if (response.errors && response.errors.length) {
            ToastUtility.show(
              {
                content: response.errors[0].message,
                timeOut: 5000,
                cssClass: 'e-toast-danger',
                position: { X: 'Right', Y: 'Top' },
                showCloseButton: true,
              },
              'Error'
            ) as ToastComponent;
            self.oktaAuth.signOut();
          }
        });
    });
  }

  public async signInOkta(): Promise<void> {
    await this.oktaAuth.signInWithRedirect();
  }

  /**
   * @description Page reload this function is used
   */
  private pageReloadFun() {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAndSetActiveAccount();
      });
  }

  /**
   * @description After successfully login this function is use
   */
  private loginSuccessFun() {
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: any) => {
        this.getCurrentUserLoginData(
          result?.payload?.idTokenClaims,
          result?.payload?.accessToken
        );
      });
  }

  /**
   * @description
   * If no active account set but there are accounts signed in, sets first account to active account
   * To use active account set here, subscribe to inProgress$ first in your component
   * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
   */
  private checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.authService.instance.getAllAccounts().length > 0
    ) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  /**
   * @description This function is used to login api call and get current user login details
   */
  private getCurrentUserLoginData(loginUser: any, token: string) {
    if (loginUser?.preferred_username) {
      this.getCurrentUserDetailsOnEmailId(loginUser?.preferred_username, token);
    }
  }

  /**
   * @description this function is used to calling user details base on email id and token that receive microsoft api response
   */
  private getCurrentUserDetailsOnEmailId(emailId: string, token: string) {
    console.log('emailId', emailId);
    console.log('token', token);
  }

  ngOnInit(): void {
    this.authService.instance.initialize();
    this.pageReloadFun();
    this.loginSuccessFun();
  }
}
