import {
  CommonModule,
  isPlatformBrowser,
  formatCurrency,
  formatNumber,
} from '@angular/common';
import {
  Component,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import {
  ScenarioComparisonTableData,
  WaterfallData,
} from '../../shared/enum/generic.enum';
import { GridComponent, GridModule } from '@syncfusion/ej2-angular-grids';
import {
  CategoryService,
  ChartComponent,
  ChartModule,
  DataLabelService,
  WaterfallSeriesService,
  ExportService,
} from '@syncfusion/ej2-angular-charts';
import {
  DropDownButtonModule,
  ItemModel,
} from '@syncfusion/ej2-angular-splitbuttons';
import { ButtonModule } from '@syncfusion/ej2-angular-buttons';
import { ScenarioGraphqlService } from '../../graphql/scenario-graphql.service';
import {
  GrossChartData,
  ComparisonGPPrices,
  FormularyReferenceData,
} from '../../shared/Interface/generic.model';
import { formatWaterfallChart } from '../../shared/utils';
import { ToastUtility } from '@syncfusion/ej2-notifications';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import * as domtoimage from 'dom-to-image';

@Component({
  selector: 'app-scenario-comparison',
  standalone: true,
  providers: [
    DataLabelService,
    CategoryService,
    WaterfallSeriesService,
    ExportService,
  ],
  imports: [
    TextBoxModule,
    CommonModule,
    ReactiveFormsModule,
    GridModule,
    ChartModule,
    ButtonModule,
    DropDownButtonModule,
  ],
  templateUrl: './scenario-comparison.component.html',
  styleUrl: './scenario-comparison.component.scss',
})
export class ScenarioComparisonComponent implements OnInit {
  @Input() scenarioComparisonFlag: boolean = false;
  @Input() simulationId: string = '';
  @Input() scenarioId: number;
  showPlaceholder: boolean = false;
  actualScenarioComparisonForm!: FormGroup;
  forecastScenarioComparisonForm!: FormGroup;
  scenarioComparisonTableData = ScenarioComparisonTableData;
  waterfallData?: Object[];
  title?: string;
  marker?: Object;
  primaryXAxis?: Object;
  primaryYAxis?: Object;
  connector?: Object;
  sum: number[] = [8];
  intermediate: number[] = [4, 7];
  columnWidth: number = 0.6;
  items: ItemModel[] = [];
  comparisonTableData?: Object[];
  comparisonTableDownloadData?: Object[];
  tableColList?: string[] = [];
  baseChartData?: GrossChartData;
  forecastChartData?: GrossChartData[];
  comparisonGPPrices?: ComparisonGPPrices[] = [];
  isProcessing: boolean = true;
  randomId = Math.floor(Math.random() * 1000);
  formularyTableData: FormularyReferenceData[] = [];
  @ViewChild('comparisonGrid') public comparisonGrid?: GridComponent;
  @ViewChild('comparisonGridDownload')
  public comparisonGridDownload?: GridComponent;
  @ViewChild('baseChart') baseChart: ChartComponent;
  @ViewChild('foreCastChart') foreCastChart: ChartComponent;

  constructor(
    private formBuilder: FormBuilder,
    private scenarioGraphqlService: ScenarioGraphqlService
  ) {}

  actualScenarioComparisonFormInitialize() {
    this.actualScenarioComparisonForm = this.formBuilder.group({
      scenarioID: [''],
      scenarioName: [''],
      scenarioDecription: [''],
      runDate: [''],
    });
  }

  forecastScenarioComparisonFormInitialize() {
    this.forecastScenarioComparisonForm = this.formBuilder.group({
      scenarioID: [''],
      scenarioName: [''],
      scenarioDecription: [''],
      runDate: [''],
    });
  }

  getComparisonData() {
    this.scenarioGraphqlService
      .processComparisonData({
        simulationId: this.simulationId,
        scenarioId: this.scenarioId,
      })
      .subscribe(
        (response: any) => {
          if (
            response &&
            response.data &&
            response.data.processComparisonData
          ) {
            // console.log(response.data.processComparisonData, 'DATA')
            // STEP 1: Table
            const list = response.data.processComparisonData.comparisonTable
              ?.length
              ? response.data.processComparisonData.comparisonTable
              : [];
            this.comparisonTableData = [];
            this.comparisonTableDownloadData = JSON.parse(
              JSON.stringify(
                response.data.processComparisonData.comparisonTable
              )
            );
            const colList = list?.length ? Object.keys(list[0]) : [];
            for (const i of list) {
              for (const col of Object.keys(i)) {
                if (col != 'Metrics') {
                  if (i['Metrics'] == 'Quantity') {
                    if (i[col]) i[col] = formatNumber(i[col], 'en-IN', '1.2-2');
                    // } else if (i["Metrics"] == "Growth in NP") {
                    //   if (i[col]) i[col] = formatNumber(i[col], "en-IN", "1.2-2")
                  } else if (i['Metrics'] == 'Growth in NP %') {
                    if (i[col]) i[col] = parseFloat(i[col]).toFixed(2) + '%';
                  } else {
                    if (col !== 'Base Percent' &&
                      col !== 'Rev Forecast 1 Percent' &&
                      col !== 'Rev Forecast 2 Percent' &&
                      col !== 'Rev Forecast 3 Percent'
                    ) {
                      i[col] =
                      formatCurrency(
                        i[col],
                        'en-US',
                        '$',
                        'USD',
                        '1.2-2'
                      );
                    }
                    if (col === 'Base Percent' ||
                        col === 'Rev Forecast 1 Percent' ||
                        col === 'Rev Forecast 2 Percent' ||
                        col === 'Rev Forecast 3 Percent'
                      ){
                        if (i[col]) i[col] = parseFloat(i[col]).toFixed(2);
                      }
                  }
                }
              }
              this.comparisonTableData.push(i);
            }
            this.tableColList = colList;
            //
            // STEP 2: Base Chart
            this.baseChartData = response.data.processComparisonData
              ?.baseChartData
              ? response.data.processComparisonData.baseChartData
              : {};
            if (this.baseChartData) {
              this.baseChartData.formatChartData = formatWaterfallChart(
                this.baseChartData.chartData
              );
            }
            // this.baseChartData = {
            //   title: "Base Gross To Net Chart",
            //   revenueData: [
            //     { label: "GROSS REVENUE", value: 21187874 },
            //     { label: "BASE REBATE", value: 21187, percent: 60.13 },
            //     { label: "NET PROFIT", value: 21187, percent: 60.13 }
            //   ],
            //   chartData: []
            // }
            //
            // STEP 3: Forecast Chart
            this.forecastChartData = response.data.processComparisonData
              ?.forecastChartData
              ? response.data.processComparisonData.forecastChartData
              : [];
            for (const item of this.forecastChartData) {
              item.formatChartData = formatWaterfallChart(item.chartData);
              // console.log(item.formatChartData)
            }

            // STEP 4: GP Prices
            this.comparisonGPPrices = response.data.processComparisonData
              .gpPriceComparison?.length
              ? response.data.processComparisonData.gpPriceComparison
              : [];
          } else if (response && response.errors && response.errors.length) {
            this.comparisonTableData = [];
            this.baseChartData = {};
            this.forecastChartData = [];
            this.comparisonGPPrices = [];
            ToastUtility.show(
              {
                content: response.errors[0].message,
                timeOut: 2000,
                cssClass: 'e-toast-danger',
                position: { X: 'Right', Y: 'Top' },
                showCloseButton: true,
              },
              'Error'
            ) as ToastComponent;
          }
          this.isProcessing = false;
        },
        () => {
          this.isProcessing = false;
        }
      );
  }

  exportComparisonTableData(exportType: string): void {
    if (!this.comparisonTableDownloadData.length) {
      return;
    }
    if (exportType === 'csv') {
      (this.comparisonGridDownload as GridComponent).csvExport();
    } else {
      (this.comparisonGridDownload as GridComponent).excelExport();
    }
  }

  exportForecastChart(exportType: string, elementId: string, title: string) {
    const element = document.getElementById(elementId);
    const options = {
      bgcolor: 'white'
    }
    if (element && exportType === 'PNG') {
      domtoimage
        .toPng(element, options)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = title + '.png';
          link.click();
        });
    } else {
      domtoimage
        .toJpeg(element, options)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = title +'.jpeg';
          link.click();
        });
    }
  }

  exportAsImage(exportType: string, elementId: string, name: string) {
    const element = document.getElementById(elementId);
    const options = {
      bgcolor: 'white'
    }
    if (element && exportType === 'PNG') {
      domtoimage
        .toPng(element, options)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = name + '.png';
          link.click();
        });
    } else {
      domtoimage
        .toJpeg(element, options)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = name +'.jpeg';
          link.click();
        });
    }
  }

  exportChart(exportType: string, scenarioId: number) {
    const element = document.getElementById('baseChart' + scenarioId);
    const options = {
      bgcolor: 'white'
    }
    if (element && exportType === 'PNG') {
      domtoimage
        .toPng(element, options)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'BaseChart.png';
          link.click();
        });
    } else {
      domtoimage
        .toJpeg(element, options)
        .then((dataUrl) => {
          const link = document.createElement('a');
          link.href = dataUrl;
          link.download = 'BaseChart.jpeg';
          link.click();
        });
    }
  }

  ngOnInit(): void {
    this.actualScenarioComparisonFormInitialize();
    this.forecastScenarioComparisonFormInitialize();
    this.marker = {
      dataLabel: { visible: true, position: 'Outer', format: 'c2' },
    };
    this.primaryXAxis = {
      valueType: 'Category',
      majorGridLines: { width: 0 },
      minorGridLines: { width: 0 },
      labelStyle: {
        fontWeight: 'bold',
        color: '#00000',
      },
    };
    this.primaryYAxis = {
      labelFormat: '${value}',
      enableTrim: true,
      majorGridLines: { width: 0 },
      minorGridLines: { width: 0 },
      labelStyle: {
        color: '#00000',
      },
    };
    this.title = 'Gross to Net chart';
    this.connector = { color: '#0070C0', width: 1 };
    this.waterfallData = WaterfallData;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['scenarioComparisonFlag']?.currentValue) {
      this.getComparisonData();
    }
  }
}
