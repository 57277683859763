import { Component } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import {
  ButtonModule,
  RadioButtonModule,
} from '@syncfusion/ej2-angular-buttons';
import {
  ToastComponent,
  ToastUtility,
} from '@syncfusion/ej2-angular-notifications';
import { AuthGraphqlService } from 'app/graphql/auth-graphql.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [FormsModule, RadioButtonModule, ReactiveFormsModule, ButtonModule],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent {
  forgotPasswordForm: FormGroup;
  resetPasswordQuestionForm: FormGroup;
  showSecurityQeustion: Boolean = false;
  securityQuestion: Object = {};

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loader: NgxUiLoaderService,
    private authGraphqlService: AuthGraphqlService
  ) {
    this.initForm();
  }

  initForm() {
    this.forgotPasswordForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      resetMethod: ['LinkToEmail', Validators.required],
    });
    //
    this.resetPasswordQuestionForm = this.fb.group({
      answer: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    })
  }

  submit() {
    const method = this.forgotPasswordForm.controls['resetMethod'].value
    console.log(method)
    if (method == "LinkToEmail") {
      this.loader.start();
      this.authGraphqlService
        .requestPasswordReset(
          this.forgotPasswordForm.controls['emailAddress'].value
        )
        .subscribe((response: any) => {
          if (response?.data?.requestPasswordReset?.success) {
            ToastUtility.show(
              {
                content: response?.data?.requestPasswordReset?.success,
                timeOut: 10000,
                cssClass: 'e-toast-success',
                position: { X: 'Right', Y: 'Top' },
                showCloseButton: true,
              },
              'Information'
            ) as ToastComponent;
            setTimeout(()=>{
              this.router.navigate([`/login`]);
            }, 1000);
          } else {
            ToastUtility.show(
              {
                content: response?.errors[0]?.message,
                timeOut: 10000,
                cssClass: 'e-toast-success',
                position: { X: 'Right', Y: 'Top' },
                showCloseButton: true,
              },
              'Information'
            ) as ToastComponent;
          }
          this.loader.stop();
        },
        () => {
          this.loader.stop();
        }
      );
    } else if (method == "2FAAuthentication") {
      
    } else if (method == "SecurityQuestion") {
      this.loader.start();
      this.authGraphqlService
        .getRandomSecurityQuestion(
          this.forgotPasswordForm.controls['emailAddress'].value
        )
        .subscribe((response: any) => {
          this.loader.stop();
          if (response?.data?.getRandomSecurityQuestion) {
            this.showSecurityQeustion = true
            this.securityQuestion = response?.data?.getRandomSecurityQuestion
            // setTimeout(()=>{
            //   this.router.navigate([`/reset-password-with-question`]);
            // }, 1000);
          } else {
            ToastUtility.show(
              {
                content: response?.errors[0]?.message,
                timeOut: 10000,
                cssClass: 'e-toast-danger',
                position: { X: 'Right', Y: 'Top' },
                showCloseButton: true,
              },
              'Information'
            ) as ToastComponent;
          }
        },
        () => {
          this.loader.stop();
        }
      );
    } else {
      console.log("Please select one method to process")
    }
  }

  submitForQuestion() {
    const email = this.forgotPasswordForm.controls['emailAddress'].value
    const formData = this.resetPasswordQuestionForm.value
    const q = this.securityQuestion
    const payload = {...formData, email}
    if (q) payload.question = q['id']
    this.loader.start();
    this.authGraphqlService
      .resetPasswordWithSecurityQuestion(payload)
      .subscribe((response: any) => {
        this.loader.stop();
        if (response?.data?.resetPasswordWithSecurityQuestion) {
          ToastUtility.show(
            {
              content: response?.data?.resetPasswordWithSecurityQuestion?.message,
              timeOut: 10000,
              cssClass: 'e-toast-success',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Information'
          ) as ToastComponent;
          setTimeout(()=>{
            this.router.navigate([`/login`]);
          }, 1000);
        } else {
          ToastUtility.show(
            {
              content: response?.errors[0]?.message,
              timeOut: 10000,
              cssClass: 'e-toast-danger',
              position: { X: 'Right', Y: 'Top' },
              showCloseButton: true,
            },
            'Error'
          ) as ToastComponent;
        }
      },
      () => {
        this.loader.stop();
      }
    );
  }
}
